export default [
  {
    routeName: "home",
    category: "首頁",
    title: "首頁",
    sTitle1: "",
  },
  {
    routeName: "EditPassword",
    category: "修改密碼",
    title: "修改密碼",
    sTitle1: "",
  },
  //guild
  {
    routeName: "GuildsManagement",
    category: "人員系統",
    title: "公會管理",
    sTitle1: "",
    sTitle2: "",
  },
  {
    routeName: "GuildsManagementDetail",
    category: "人員系統",
    title: "公會管理",
    sTitle1: "公會資料維護",
    sTitle2: "",
  },
  {
    routeName: "GuildsManagementCreate",
    category: "人員系統",
    title: "公會管理",
    sTitle1: "新增公會",
    sTitle2: "",
  },
  {
    routeName: "GuildsManagementDelete",
    category: "人員系統",
    title: "公會管理",
    sTitle1: "停用公會",
    sTitle2: "",
  },
  {
    routeName: "GuildsManagementDeleteEdit",
    category: "人員系統",
    title: "公會管理",
    sTitle1: "停用公會資料維護",
    sTitle2: "",
  },
  //group
  {
    routeName: "GroupManagement",
    category: "人員系統",
    title: "集團管理",
    sTitle1: "",
    sTitle2: "",
  },
  {
    routeName: "GroupManagementDetail",
    category: "人員系統",
    title: "集團管理",
    sTitle1: "集團資料維護",
    sTitle2: "",
  },
  {
    routeName: "GroupManagementCreate",
    category: "人員系統",
    title: "集團管理",
    sTitle1: "新增集團",
    sTitle2: "",
  },
  {
    routeName: "GroupManagementDelete",
    category: "人員系統",
    title: "集團管理",
    sTitle1: "停用集團",
    sTitle2: "",
  },
  {
    routeName: "GroupManagementDeleteEdit",
    category: "人員系統",
    title: "集團管理",
    sTitle1: "停用集團資料維護",
    sTitle2: "",
  },
  {
    routeName: "GroupManagementCompany",
    category: "人員系統",
    title: "集團管理",
    sTitle1: "集團資料維護",
    sTitle2: "公司管理",
  },
  //department
  {
    routeName: "CompanyManagement",
    category: "公司管理",
    title: "集團資料",
    sTitle1: "",
    sTitle2: "",
  },
  {
    routeName: "CompanyManagementDetail",
    category: "公司管理",
    title: "集團資料",
    sTitle1: "公司資料",
    sTitle2: "",
  },
  {
    routeName: "CompanyManagementDepartmentCreate",
    category: "公司管理",
    title: "集團資料",
    sTitle1: "公司資料",
    sTitle2: "新增組別",
  },
  {
    routeName: "CompanyManagementDepartmentDelete",
    category: "公司管理",
    title: "集團資料",
    sTitle1: "公司資料",
    sTitle2: "停用組別",
  },
  {
    routeName: "CompanyManagementDepartmentEdit",
    category: "公司管理",
    title: "集團資料",
    sTitle1: "公司資料",
    sTitle2: "組別資料維護",
  },
  {
    routeName: "CompanyManagementDepartmentRestart",
    category: "公司管理",
    title: "集團資料",
    sTitle1: "公司資料",
    sTitle2: "重啟組別",
  },
  {
    routeName: "CompanyManagementUserCreate",
    category: "公司管理",
    title: "集團資料",
    sTitle1: "公司資料",
    sTitle2: "新增人員",
  },
  {
    routeName: "CompanyManagementUserDetail",
    category: "公司管理",
    title: "集團資料",
    sTitle1: "公司資料",
    sTitle2: "人員組別資料維護",
  },
  //user
  {
    routeName: "UsersManagement",
    category: "人員系統",
    title: "使用者管理",
    sTitle1: "",
    sTitle2: "",
  },
  {
    routeName: "UsersManagementDetail",
    category: "人員系統",
    title: "使用者管理",
    sTitle1: "使用者資料維護",
    sTitle2: "",
  },
  {
    routeName: "UsersManagementCreate",
    category: "人員系統",
    title: "使用者管理",
    sTitle1: "新增使用者",
    sTitle2: "",
  },

  //permission
  {
    routeName: "PermissionManagement",
    category: "權限管理系統",
    title: "群組權限",
    sTitle1: "",
    sTitle2: "",
  },
  {
    routeName: "PermissionManagementDetail",
    category: "權限管理系統",
    title: "群組權限",
    sTitle1: "群組維護",
    sTitle2: "",
  },
  {
    routeName: "PermissionManagementCreate",
    category: "權限管理系統",
    title: "群組權限",
    sTitle1: "新增群組",
    sTitle2: "",
  },

  //object
  {
    routeName: "ObjectManagement",
    category: "物件管理系統",
    title: "物件資料維護",
    sTitle1: "",
    sTitle2: "",
  },
  {
    routeName: "ObjectManagementCreate",
    category: "物件管理系統",
    title: "物件資料維護",
    sTitle1: "新增物件",
    sTitle2: "",
  },
  {
    routeName: "ObjectManagementDetail",
    category: "物件管理系統",
    title: "物件資料維護",
    sTitle1: "物件明細",
    sTitle2: "",
  },
  {
    routeName: "ObjectManagementDescription",
    category: "物件管理系統",
    title: "物件資料維護",
    sTitle1: "物件說明",
    sTitle2: "",
  },
  {
    routeName: "ObjectManagementAttachment",
    category: "物件管理系統",
    title: "物件資料維護",
    sTitle1: "附件",
    sTitle2: "",
  },

  //sample
  {
    routeName: "perModels",
    category: "sample",
    title: "sample",
    sTitle1: "",
    sTitle2: "",
  },
  //404
  {
    routeName: "not-found",
    category: "",
    title: "",
    sTitle1: "",
    sTitle2: "",
  },
];
