<template>
  <div class="ml-4">
    <div style="text-align: start">
      <div
        class="d-flex justify-content-between"
        style="width: 95%; margin: 0 auto"
      >
        <h1>{{ this.viewType.title }}</h1>
        <div>
          <b-button
            v-if="viewType.type !== 'objectDetail'"
            @click="checkStep(1)"
            class="mr-2"
            variant="primary"
            >上一步</b-button
          >
          <b-button
            v-if="viewType.type !== 'objectAttachment'"
            @click="checkStep(2)"
            class="mr-2"
            variant="primary"
            >下一步</b-button
          >
          <b-button
            @click="backAction()"
            class="mr-2"
            variant="outline-secondary"
            >取消</b-button
          >
        </div>
      </div>

      <objectDetail v-if="viewType.type == 'objectDetail'" />
      <objectDescription v-if="viewType.type == 'objectDescription'" />
      <objectAttachment v-if="viewType.type == 'objectAttachment'" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import { Toast } from "./../../utils/helper";
// import BaseTable from "./../../components/BaseTable";
// import SearchSelect from "./../../components/SearchSelect";
// import DateField from "./../../components/DateField";

import objectDetail from "./objectDetail/mainView";
import objectDescription from "./objectDescription/mainView";
import objectAttachment from "./objectAttachment/mainView";

export default {
  components: {
    // BaseTable,
    // SearchSelect,
    // DateField,
    objectDetail,
    objectDescription,
    objectAttachment,
  },
  data() {
    return {
      viewType: {
        type: "",
        id: null,
        title: "",
      },
    };
  },
  methods: {
    backAction() {
      this.$router.push({ name: "ObjectManagement" });
    },
    checkviewType() {
      this.viewType.id = this.$route.params.id;

      if (this.$route.name == "ObjectManagementDetail") {
        this.viewType.type = "objectDetail";
        this.viewType.title = "物件明細";
      } else if (this.$route.name == "ObjectManagementDescription") {
        this.viewType.type = "objectDescription";
        this.viewType.title = "物件說明";
      } else {
        this.viewType.type = "objectAttachment";
        this.viewType.title = "附件";
      }
    },
    checkStep(type) {
      if (type == 1) {
        if (this.viewType.type == "objectDescription") {
          let id = "test2024";
          this.$router.push({
            name: "ObjectManagementDetail",
            params: { id: id },
          });
        } else {
          let id = "test2024";
          this.$router.push({
            name: "ObjectManagementDescription",
            params: { id: id },
          });
        }
      } else {
        if (this.viewType.type == "objectDescription") {
          let id = "test2024";
          this.$router.push({
            name: "ObjectManagementAttachment",
            params: { id: id },
          });
        } else {
          let id = "test2024";
          this.$router.push({
            name: "ObjectManagementDescription",
            params: { id: id },
          });
        }
      }
      this.checkviewType();
    },
  },
  watch: {},
  computed: {
    ...mapState(["backgroundColor", "currentUser", "specialTitle"]),
  },

  created() {
    console.log("createeeee");
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;
    this.checkviewType();
  },
};
</script>
<style scoped>
.titleWidth {
  width: 10rem;
}
.addressInputCss {
  width: 4rem;
}
.postalCss {
  width: 6rem;
}
</style>