import Vue from "vue";
import VueRouter from "vue-router";
import store from "./../store";

import HomeView from "../views/HomeView.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import NotFound from "../views/NotFound.vue";
import EditPassword from "../views/EditPassword.vue";

//公會
import GuildsManagement from "../views/UserModule/GuildsManagement/SearchPage.vue";
import GuildsManagementDetail from "../views/UserModule/GuildsManagement/DetailPage.vue";
import GuildsManagementCreate from "../views/UserModule/GuildsManagement/DetailPage.vue";
import GuildsManagementDelete from "../views/UserModule/GuildsManagement/DetailPage.vue";
import GuildsManagementDeleteEdit from "../views/UserModule/GuildsManagement/DetailPage.vue";

//集團
import GroupManagement from "../views/UserModule/GroupManagement/SearchPage.vue";
import GroupManagementDetail from "../views/UserModule/GroupManagement/DetailPage.vue";
import GroupManagementCreate from "../views/UserModule/GroupManagement/DetailPage.vue";
import GroupManagementDelete from "../views/UserModule/GroupManagement/DetailPage.vue";
import GroupManagementDeleteEdit from "../views/UserModule/GroupManagement/DetailPage.vue";
import GroupManagementCompany from "../views/UserModule/GroupManagement/DetailPage.vue";

//公司
import CompanyManagement from "../views/UserModule/CompanyManagement/SearchPage.vue";
import CompanyManagementDetail from "../views/UserModule/CompanyManagement/DetailPage.vue";
import CompanyManagementDepartmentCreate from "../views/UserModule/CompanyManagement/departmentPage.vue";
import CompanyManagementDepartmentDelete from "../views/UserModule/CompanyManagement/departmentPage.vue";
import CompanyManagementDepartmentEdit from "../views/UserModule/CompanyManagement/departmentPage.vue";
import CompanyManagementDepartmentRestart from "../views/UserModule/CompanyManagement/departmentPage.vue";
import CompanyManagementUserCreate from "../views/UserModule/CompanyManagement/UserPage.vue";
import CompanyManagementUserDetail from "../views/UserModule/CompanyManagement/UserPage.vue";

//使用者
import UsersManagement from "../views/UserModule/UsersManagement/SearchPage.vue";
import UsersManagementDetail from "../views/UserModule/UsersManagement/DetailPage.vue";
import UsersManagementCreate from "../views/UserModule/UsersManagement/DetailPage.vue";

//物件
import ObjectManagement from "../views/ObjectManagement/searchPage.vue";
import ObjectManagementCreate from "../views/ObjectManagement/createPage.vue";
import ObjectManagementDetail from "../views/ObjectManagement/editPage.vue";
import ObjectManagementDescription from "../views/ObjectManagement/editPage.vue";
import ObjectManagementAttachment from "../views/ObjectManagement/editPage.vue";

//權限
import PermissionManagement from "../views/PermissionManagement/PermissionManagement.vue";
import PermissionManagementDetail from "../views/PermissionManagement/PermissionManagementDetail.vue";
import PermissionManagementCreate from "../views/PermissionManagement/PermissionManagementDetail.vue";

//sample
import perModels from "../sample/perModels.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    redirect: "/signin",
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/EditPassword",
    name: "EditPassword",
    component: EditPassword,
  },
  {
    path: "/signin",
    name: "sign-in",
    component: SignIn,
  },
  {
    path: "/signup",
    name: "sign-up",
    component: SignUp,
  },
  //公會
  {
    path: "/GuildsManagement",
    name: "GuildsManagement",
    component: GuildsManagement,
  },
  {
    path: "/GuildsManagement/Detail/:id",
    name: "GuildsManagementDetail",
    component: GuildsManagementDetail,
  },
  {
    path: "/GuildsManagement/Create",
    name: "GuildsManagementCreate",
    component: GuildsManagementCreate,
  },
  {
    path: "/GuildsManagement/Delete/:id",
    name: "GuildsManagementDelete",
    component: GuildsManagementDelete,
  },
  {
    path: "/GuildsManagement/DeleteEdit/:id",
    name: "GuildsManagementDeleteEdit",
    component: GuildsManagementDeleteEdit,
  },
  //集團
  {
    path: "/GroupManagement",
    name: "GroupManagement",
    component: GroupManagement,
  },
  {
    path: "/GroupManagement/Detail/:id",
    name: "GroupManagementDetail",
    component: GroupManagementDetail,
  },
  {
    path: "/GroupManagement/Create",
    name: "GroupManagementCreate",
    component: GroupManagementCreate,
  },
  {
    path: "/GroupManagement/Delete/:id",
    name: "GroupManagementDelete",
    component: GroupManagementDelete,
  },
  {
    path: "/GroupManagement/DeleteEdit/:id",
    name: "GroupManagementDeleteEdit",
    component: GroupManagementDeleteEdit,
  },
  {
    path: "/GroupManagement/Company/:id",
    name: "GroupManagementCompany",
    component: GroupManagementCompany,
  },
  //公司
  {
    path: "/CompanyManagement",
    name: "CompanyManagement",
    component: CompanyManagement,
  },
  {
    path: "/CompanyManagement/:id",
    name: "CompanyManagementDetail",
    component: CompanyManagementDetail,
  },
  {
    path: "/CompanyManagement/department/create/:id",
    name: "CompanyManagementDepartmentCreate",
    component: CompanyManagementDepartmentCreate,
  },
  {
    path: "/CompanyManagement/department/edit/:id",
    name: "CompanyManagementDepartmentEdit",
    component: CompanyManagementDepartmentEdit,
  },
  {
    path: "/CompanyManagement/department/delete/:id",
    name: "CompanyManagementDepartmentDelete",
    component: CompanyManagementDepartmentDelete,
  },
  {
    path: "/CompanyManagement/department/restart/:id",
    name: "CompanyManagementDepartmentRestart",
    component: CompanyManagementDepartmentRestart,
  },
  {
    path: "/CompanyManagement/user/create/:id",
    name: "CompanyManagementUserCreate",
    component: CompanyManagementUserCreate,
  },
  {
    path: "/CompanyManagement/user/edit/:id",
    name: "CompanyManagementUserDetail",
    component: CompanyManagementUserDetail,
  },

  //使用者
  {
    path: "/UsersManagement",
    name: "UsersManagement",
    component: UsersManagement,
  },
  {
    path: "/UsersManagement/Create",
    name: "UsersManagementCreate",
    component: UsersManagementCreate,
  },
  {
    path: "/UsersManagement/Detail/:id",
    name: "UsersManagementDetail",
    component: UsersManagementDetail,
  },

  //物件
  {
    path: "/ObjectManagement",
    name: "ObjectManagement",
    component: ObjectManagement,
  },
  {
    path: "/ObjectManagement/Create",
    name: "ObjectManagementCreate",
    component: ObjectManagementCreate,
  },
  {
    path: "/ObjectManagement/Detail/:id",
    name: "ObjectManagementDetail",
    component: ObjectManagementDetail,
  },
  {
    path: "/ObjectManagement/Description/:id",
    name: "ObjectManagementDescription",
    component: ObjectManagementDescription,
  },
  {
    path: "/ObjectManagement/Attachment/:id",
    name: "ObjectManagementAttachment",
    component: ObjectManagementAttachment,
  },

  //權限
  {
    path: "/PermissionManagement",
    name: "PermissionManagement",
    component: PermissionManagement,
  },
  {
    path: "/PermissionManagement/Create",
    name: "PermissionManagementCreate",
    component: PermissionManagementCreate,
  },
  {
    path: "/PermissionManagement/:id",
    name: "PermissionManagementDetail",
    component: PermissionManagementDetail,
  },


  //sample
  {
    path: "/perModels",
    name: "perModels",
    component: perModels,
  },
  //404
  {
    path: "*",
    name: "not-found",
    component: NotFound,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const tokenInLocalStorage = localStorage.getItem("token");
  const tokenInStore = store.state.token;

  let isAuthenticated = store.state.isAuthenticated;
  if (tokenInLocalStorage && tokenInLocalStorage !== tokenInStore) {
    isAuthenticated = await store.dispatch("fetchCurrentUser");
  }

  const pathsWithoutAuthentication = ["sign-up", "sign-in"];

  if (!isAuthenticated && !pathsWithoutAuthentication.includes(to.name)) {
    next("/signin");
    return;
  }

  if (isAuthenticated && pathsWithoutAuthentication.includes(to.name)) {
    next("/home");
    return;
  }
  let router = to.fullPath.split("/")[1];
  store.commit("setCurrentRoute", router);
  next();
});

export default router;
