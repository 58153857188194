<template>
  <div
    class="navbar fixed-top navbar-light bg-light shadowBox"
    :class="
      backgroundColor == 'dark'
        ? 'navbar-dark bg-dark shadowBoxDark'
        : 'navbar-light bg-light shadowBoxLight'
    "
  >
    <div style="width: 100%" class="d-flex justify-content-between">
      <div class="d-flex align-items-center" style="position: relative">
        <div class="d-flex">
          <button
            type="button"
            :disabled="windowWidth <= 992"
            class="btn"
            style="background-color: transparent; border-color: transparent"
            @click="setSidebarType()"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="d-flex align-items-center" v-if="sidebarType == '18'">
            <span
              :class="
                backgroundColor == 'dark' ? 'fontColorDark' : 'fontColorLight'
              "
              style="font-size: 1.5rem"
              >CTMS</span
            >
          </div>
        </div>
        <div
          class="d-flex"
          style="position: absolute; width: 100rem"
          :style="sidebarType == '18' ? 'left:17rem' : 'left:4rem'"
        >
          <!-- <div class="d-flex align-items-center mr-2">
            <span
              @click="setbackgroundColor()"
              v-show="backgroundColor == 'dark'"
              class="material-symbols-outlined fontColorDark"
              style="cursor: pointer"
            >
              light_mode
            </span>
            <span
              @click="setbackgroundColor()"
              v-show="backgroundColor == 'light'"
              class="material-symbols-outlined fontColorLight"
              style="cursor: pointer"
            >
              dark_mode
            </span>
          </div> -->
          <div class="d-flex">
            <div
              :class="
                backgroundColor == 'dark' ? 'fontColorDark' : 'fontColorLight'
              "
              style="font-size: 1.6rem"
            >
              {{ currentRouteTitle }}
            </div>
            <div class="d-flex align-items-end ml-3 mb-1">
              <i class="fontColorGroup">{{ category }}</i>
              <i
                :class="
                  backgroundColor == 'dark' ? 'fontColorDark' : 'fontColorLight'
                "
                style="margin-right: 0.5rem; margin-left: 0.5rem"
                >></i
              >
              <i :class="sTitle1 ? 'fontColorGroup' : 'fontColorLight'">{{
                title
              }}</i>
              <template v-if="sTitle1">
                <i
                  :class="
                    backgroundColor == 'dark'
                      ? 'fontColorDark'
                      : 'fontColorLight'
                  "
                  style="margin-right: 0.5rem; margin-left: 0.5rem"
                  >></i
                >
                <i :class="sTitle2 ? 'fontColorGroup' : 'fontColorLight'">{{
                  sTitle1
                }}</i>
              </template>
              <template v-if="sTitle2">
                <i
                  :class="
                    backgroundColor == 'dark'
                      ? 'fontColorDark'
                      : 'fontColorLight'
                  "
                  style="margin-right: 0.5rem; margin-left: 0.5rem"
                  >></i
                >
                <i :class="spTitle ? 'fontColorGroup' : 'fontColorLight'">{{
                  sTitle2
                }}</i>
              </template>
              <template v-if="spTitle">
                <i
                  :class="
                    backgroundColor == 'dark'
                      ? 'fontColorDark'
                      : 'fontColorLight'
                  "
                  style="margin-right: 0.5rem; margin-left: 0.5rem"
                  >></i
                >
                <i
                  :class="
                    backgroundColor == 'dark'
                      ? 'fontColorDark'
                      : 'fontColorLight'
                  "
                  >{{ spTitle }}</i
                >
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex" style="position: relative">
        <div class="ml-auto d-flex align-items-center">
          <!-- test -->
          <div class="mr-3">
            <b-form-select
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              v-model="permissionSelect.value"
              :options="permissionSelect.options"
              @change="selectPermission()"
            ></b-form-select>
          </div>
          <div
            :class="
              backgroundColor == 'dark' ? 'fontColorDark' : 'fontColorName'
            "
            style="font-size: 1.5rem; cursor: pointer"
          >
            {{ currentUser.name || "使用者" }}
          </div>
        </div>
        <div>
          <button
            type="button"
            class="btn my-2 my-sm-0"
            style="background-color: transparent; border-color: transparent"
            @click="showuseroption()"
          >
            <i
              :class="
                backgroundColor == 'dark' ? 'fontColorDark' : 'fontColorName'
              "
              class="fa-solid fa-user"
              style="font-size: 20px; cursor: pointer"
            ></i>
          </button>
        </div>
        <!-- user list -->
        <div
          v-if="useroption"
          @mouseleave="closeuseroption()"
          class="useroption"
        >
          <ul style="padding: 0; margin: 0.5rem auto">
            <li @click="editPasswordPage()">修改密碼</li>
            <li @click="logout()">登出</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import headerCategory from "../utils/headerCategory.js";
export default {
  // Vue 會在沒有資料時使用此預設值
  data() {
    return {
      permissionSelect: {
        value: "2",
        options: [
          { value: "1", text: "admin" },
          { value: "2", text: "管理者" },
          { value: "3", text: "管理者" },
          { value: "4", text: "管理者" },
        ],
      },

      useroption: false,
      headerCategory: null,
      windowWidth: window.innerWidth,

      currentRouteTitle: "",

      category: "sample",
      title: "sample",
      sTitle1: "",
      sTitle2: "",
      spTitle: "",
    };
  },
  methods: {
    showuseroption() {
      this.useroption = !this.useroption;
    },
    closeuseroption() {
      this.useroption = false;
    },
    setSidebarType() {
      if (this.windowWidth > 992) {
        this.$store.commit("setSidebarType", "100");
      }
    },
    logout() {
      this.$store.commit("revokeAuthentication");
      this.$router.push("/signin");
    },
    editPasswordPage() {
      this.$router.push("/EditPassword");
    },
    setbackgroundColor() {
      this.$store.commit("setbackgroundColor");
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    selectPermission() {
      localStorage.setItem("permissionID", this.permissionSelect.value);
      this.$store.dispatch("fetchCurrentUser");

      // if (this.$route.name == this.currentRoute) {
      //   this.$router.go(0);
      // } else {
      //   this.$router.push({
      //     name: this.currentRoute,
      //   });
      //   this.$router.push('/home');
      // }

      if (this.currentRoute == "home") {
        this.$router.go(0);
      } else {
        this.$router.push("/home");
        this.$router.go(0);
      }
    },
    getPermissionList() {
      this.permissionSelect.value = localStorage.getItem("permissionID");
      this.permissionSelect.options.splice(0);
      for (var index in this.currentUser.permissionList) {
        let data = this.currentUser.permissionList[index];
        let info = "";
        if (data.assocNM) {
          info = data.assocNM;
        }
        if (data.corpNM) {
          info = info == "" ? data.corpNM : info + " " + data.corpNM;
        }
        if (data.taxNM) {
          info = info == "" ? data.taxNM : info + " " + data.taxNM;
        }
        if (data.teamNM) {
          info = info == "" ? data.teamNM : info + " " + data.teamNM;
        }
        if (info) {
          info = "(" + info + ")";
        }

        this.permissionSelect.options.push({
          value: data.pkID,
          text: data.groupName + info,
        });
      }
    },
  },
  computed: {
    ...mapState([
      "currentRoute",
      "currentUser",
      "backgroundColor",
      "sidebarType",
      "isAuthenticated",
      "specialTitle",
    ]),
    isMobile() {
      return this.windowWidth < 992;
    },
  },
  watch: {
    isMobile(e) {
      if (e) {
        this.$store.commit("setSidebarType", "5");
      } else {
        this.$store.commit("setSidebarType", "18");
      }
    },
    specialTitle() {
      this.spTitle = this.specialTitle;
    },
    "$route.name"() {
      this.$store.commit("getSpecialTitle", "");

      let specialRoute = ["404", "home", "perModels", "EditPassword"];
      let checkItem = specialRoute.find((a) => a == this.currentRoute);
      if (!checkItem) {
        let object = this.currentUser.isAdmin.find(
          (item) => item.progPath == this.currentRoute
        );
        if (!object) {
          this.$router.push("/404");
        }
      }

      for (var index in this.headerCategory) {
        let data = this.headerCategory[index];
        if (data.routeName == this.$route.name) {
          this.category = data.category;
          this.title = data.title;
          this.sTitle1 = data.sTitle1;
          this.sTitle2 = data.sTitle2;
        }

        if (this.sTitle2) {
          this.currentRouteTitle = this.sTitle2;
        } else {
          if (this.sTitle1) {
            this.currentRouteTitle = this.sTitle1;
          } else {
            this.currentRouteTitle = this.title;
          }
        }
      }
    },
    isAuthenticated() {
      this.getPermissionList();
    },
  },
  mounted() {
    // 監聽窗口大小的改變
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // 在組件被銷毀之前移除窗口大小改變的監聽器
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    this.headerCategory = [...headerCategory];
    if (this.windowWidth < 992) {
      this.$store.commit("setSidebarType", "5");
    } else {
      this.$store.commit("setSidebarType", "18");
    }
    this.getPermissionList();
  },
};
</script>
<style scoped>
.useroption {
  position: absolute;
  top: 120%;
  left: 0;
  color: white;
  border-radius: 5px;
  width: 95%;
  background-color: #6c757d;
}
.useroption li {
  list-style: none;
  text-align: left;
  padding-left: 1rem;
  padding-top: 0.2rem;
  cursor: pointer;
}
.shadowBoxDark {
  box-shadow: 0 5px 5px 0px #212529;
}
.shadowBoxLight {
  box-shadow: 0 5px 5px 0px #cccccc;
}
li {
  margin-bottom: 0.5rem;
}
.fontColorLight {
  color: #00205b;
}
.fontColorDark {
  color: white;
}
.fontColorGroup {
  color: #868e96;
}
.fontColorName {
  color: #677863;
}
</style>