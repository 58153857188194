<template>
  <div>
    <div class="mainContent">
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row class="mb-2">
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '1'"
                  >同層戶數：
                </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-input
                  :id="$route.name + '1'"
                  :name="$route.name + '1'"
                  placeholder="請輸入同層戶數(戶)"
                  v-model="floorHouseholds"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '2'"
                  >總戶數：
                </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-input
                  :id="$route.name + '2'"
                  :name="$route.name + '2'"
                  placeholder="請輸入總戶數(戶)"
                  v-model="allHouseholds"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '3'"
                  >電梯數：
                </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-input
                  :id="$route.name + '3'"
                  :name="$route.name + '3'"
                  placeholder="請輸入電梯數量(部)"
                  v-model="elevatorNumber"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="12">
            <b-row>
              <b-col xl="2" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '4'">格局： </label>
              </b-col>
              <b-col xl="10" class="d-flex align-items-center">
                <b-form-input
                  :name="$route.name + '4'"
                  placeholder=""
                  v-model="objectLayout.input1"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
                <label class="itemDetailCSS">+</label>
                <b-form-input
                  :name="$route.name + '4'"
                  placeholder=""
                  v-model="objectLayout.input2"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
                <label class="itemDetailCSS">房</label>
                <b-form-input
                  :name="$route.name + '4'"
                  placeholder=""
                  v-model="objectLayout.input3"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
                <label class="itemDetailCSS">廳</label>
                <b-form-input
                  :name="$route.name + '4'"
                  placeholder=""
                  v-model="objectLayout.input4"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
                <label class="itemDetailCSS">衛，其他格局</label>
                <b-form-input
                  :name="$route.name + '4'"
                  placeholder=""
                  v-model="objectLayout.input5"
                  class="mr-1 col-3"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
                <b-form-checkbox
                  v-model="objectLayout.ckeckbox"
                  id="objectLayoutCheckbox"
                >
                </b-form-checkbox>
                <label class="itemDetailCSS" for="objectLayoutCheckbox">
                  開放格局
                </label>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="12">
            <b-row>
              <b-col xl="2" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">主要座向： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-select
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="objectDirection.objectValue"
                  :options="objectDirection.options"
                ></b-form-select>
                <label class="itemDetailCSS">（大樓：</label>
                <b-form-select
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="objectDirection.buildingValue"
                  :options="objectDirection.options"
                ></b-form-select>
                <label class="itemDetailCSS">/ 房屋：大門</label>
                <b-form-select
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="objectDirection.gateValue"
                  :options="objectDirection.options"
                ></b-form-select>
                <label class="itemDetailCSS">，落地窗</label>
                <b-form-select
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="objectDirection.windowValue"
                  :options="objectDirection.options"
                ></b-form-select>
                <label class="itemDetailCSS">）</label>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '5'">採光： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-select
                  :id="$route.name + '5'"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="lighting.value"
                  :options="lighting.options"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '6'">邊間： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-select
                  :id="$route.name + '6'"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="sideRoom.value"
                  :options="sideRoom.options"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '7'">暗房： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-select
                  :id="$route.name + '7'"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="darkRoom.value"
                  :options="darkRoom.options"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6"></b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '8'"
                  >主要建材：
                </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-select
                  :id="$route.name + '8'"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="buildingMaterials.value"
                  :options="buildingMaterials.options"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6 mb-1">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">隔間材料： </label>
              </b-col>
              <b-col class="d-flex flex-wrap mt-2">
                <div
                  v-for="(item, index) in spacerMaterial.options"
                  :key="index"
                  class="d-flex align-items-center mr-1"
                  style="padding: 0rem"
                >
                  <b-form-checkbox
                    v-model="spacerMaterial.value"
                    :value="item.value"
                    :id="spacerMaterial.name + item.value"
                  >
                  </b-form-checkbox>
                  <label
                    class="fontColor"
                    :for="spacerMaterial.name + item.value"
                    style="white-space: nowrap; margin: 0"
                  >
                    {{ item.text }}</label
                  >
                  <b-form-input
                    v-show="item.input"
                    :name="$route.name + '9'"
                    type="text"
                    class="ml-2"
                    :class="
                      backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                    "
                    trim
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">外牆： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-input
                  :name="$route.name + '10'"
                  v-model="exteriorWall.select.input"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  class="col-7"
                  trim
                />
                <b-form-select
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="exteriorWall.select.value"
                  :options="exteriorWall.select.options"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '11'"
                  >天花板：
                </label>
              </b-col>
              <b-col class="d-flex">
                <b-form-select
                  :id="$route.name + '11'"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="ceilingType.value"
                  :options="ceilingType.options"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '12'">地板： </label>
              </b-col>
              <b-col class="d-flex">
                <b-form-select
                  :id="$route.name + '12'"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="floorType.value"
                  :options="floorType.options"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">使用狀況： </label>
              </b-col>
              <b-col class="d-flex flex-wrap mt-2">
                <div
                  v-for="(item, index) in this.usageStatus.options"
                  :key="index"
                  class="d-flex mr-3 align-items-center"
                >
                  <b-form-radio
                    v-model="usageStatus.value"
                    :value="item.value"
                    :id="usageStatus.name + item.value"
                  >
                  </b-form-radio>
                  <label
                    style="white-space: nowrap; margin: 0"
                    class="fontColor"
                    :for="usageStatus.name + item.value"
                  >
                    {{ item.text }}</label
                  >
                  <div class="d-flex align-items-center" v-if="item.date">
                    <label style="white-space: nowrap; margin: 0" class="mr-2"
                      >（出租至</label
                    >
                    <DateField class="" :value.sync="usageStatus.input1"></DateField>
                    <label style="white-space: nowrap; margin: 0" class="ml-2"
                      >）</label
                    >
                  </div>
                  <div class="d-flex align-items-center" v-if="item.input">
                    <label style="white-space: nowrap; margin: 0" class="mr-2"
                      >（佔用人</label
                    >
                    <b-form-input
                      :name="$route.name + '13'"
                      placeholder="請輸入路寬(m)"
                      v-model="usageStatus.input2"
                      type="text"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      trim
                    />
                    <label style="white-space: nowrap; margin: 0" class="ml-2"
                      >）</label
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">管理員/警衛： </label>
              </b-col>
              <b-col class="d-flex flex-wrap mt-2">
                <div
                  v-for="(item, index) in this.cooperationObject.options"
                  :key="index"
                  class="d-flex mr-3"
                >
                  <b-form-radio
                    v-model="cooperationObject.value"
                    :value="item.value"
                    :id="cooperationObject.name + item.value"
                  >
                  </b-form-radio>
                  <label
                    class="fontColor"
                    :for="cooperationObject.name + item.value"
                  >
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '14'"
                  >土地寬深：
                </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <label class="itemDetailCSS">寬</label>
                <b-form-input
                  :name="$route.name + '14'"
                  placeholder="(米)"
                  v-model="landSize.width"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
                <label class="itemDetailCSS">深</label>
                <b-form-input
                  :name="$route.name + '14'"
                  placeholder="(米)"
                  v-model="landSize.height"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" style="margin: 0">管理費： </label>
              </b-col>
              <b-col class="d-flex flex-wrap">
                <div class="d-flex align-items-center" style="padding: 0">
                  <b-form-radio
                    v-model="managementFee.value"
                    value="1"
                    id="managementFee1"
                  >
                  </b-form-radio>
                  <label
                    style="white-space: nowrap; margin: 0"
                    class="fontColor mr-1"
                    for="managementFee1"
                  >
                    無</label
                  >

                  <b-form-radio
                    v-model="managementFee.value"
                    value="2"
                    id="managementFee2"
                  >
                  </b-form-radio>
                  <label
                    style="white-space: nowrap; margin: 0"
                    class="fontColor mr-1"
                    for="managementFee2"
                  >
                    每坪</label
                  >

                  <b-form-radio
                    v-model="managementFee.value"
                    value="3"
                    id="managementFee3"
                  >
                  </b-form-radio>
                  <label
                    style="white-space: nowrap; margin: 0"
                    class="fontColor mr-1"
                    for="managementFee3"
                  >
                    每戶</label
                  >

                  <b-form-radio
                    v-model="managementFee.value"
                    value="4"
                    id="managementFee4"
                  >
                  </b-form-radio>
                  <label
                    style="white-space: nowrap; margin: 0"
                    class="fontColor"
                    for="managementFee4"
                  >
                    其他</label
                  >

                  <b-form-input
                    :name="$route.name + '15'"
                    placeholder="請輸入金額或其他"
                    v-model="managementFee.input"
                    type="text"
                    class="ml-2"
                    :class="
                      backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                    "
                    trim
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" style="margin: 0">繳交頻率： </label>
              </b-col>
              <b-col class="d-flex flex-wrap">
                <div class="d-flex align-items-center" style="padding: 0">
                  <b-form-radio
                    v-model="managemenFrequency.value"
                    value="1"
                    id="managemenFrequency1"
                  >
                  </b-form-radio>
                  <label
                    style="white-space: nowrap; margin: 0"
                    class="fontColor mr-1"
                    for="managemenFrequency1"
                  >
                    月</label
                  >

                  <b-form-radio
                    v-model="managemenFrequency.value"
                    value="2"
                    id="managemenFrequency2"
                  >
                  </b-form-radio>
                  <label
                    style="white-space: nowrap; margin: 0"
                    class="fontColor mr-1"
                    for="managemenFrequency2"
                  >
                    季</label
                  >

                  <b-form-radio
                    v-model="managemenFrequency.value"
                    value="3"
                    id="managemenFrequency3"
                  >
                  </b-form-radio>
                  <label
                    style="white-space: nowrap; margin: 0"
                    class="fontColor mr-1"
                    for="managemenFrequency3"
                  >
                    半年</label
                  >

                  <b-form-radio
                    v-model="managemenFrequency.value"
                    value="4"
                    id="managemenFrequency4"
                  >
                  </b-form-radio>
                  <label
                    style="white-space: nowrap; margin: 0"
                    class="fontColor"
                    for="managemenFrequency4"
                  >
                    其他</label
                  >

                  <b-form-input
                    :name="$route.name + '16'"
                    placeholder="其他"
                    v-model="managemenFrequency.input"
                    type="text"
                    :class="
                      backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                    "
                    trim
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '17'"
                  >學校-國小：
                </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-input
                  :id="$route.name + '17'"
                  :name="$route.name + '17'"
                  placeholder="請輸入國小名稱"
                  v-model="elementarySchool"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '18'"
                  >學校-國中：
                </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-input
                  :id="$route.name + '18'"
                  :name="$route.name + '18'"
                  placeholder="請輸入國中名稱"
                  v-model="juniorHighSchool"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '19'">公園： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-input
                  :id="$route.name + '19'"
                  :name="$route.name + '19'"
                  placeholder="請輸入公園名稱"
                  v-model="parkName"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '20'">市場： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-input
                  :id="$route.name + '20'"
                  :name="$route.name + '20'"
                  placeholder="請輸入市場名稱"
                  v-model="marketName"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '21'"
                  >鄰近商圈：
                </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <b-form-input
                  :id="$route.name + '21'"
                  :name="$route.name + '21'"
                  placeholder="請輸入商圈名稱"
                  v-model="businessDistrict"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6"></b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">售屋動機： </label>
              </b-col>
              <b-col class="d-flex flex-wrap mt-2">
                <div
                  v-for="(item, index) in sellMotivation.options"
                  :key="index"
                  class="d-flex mr-1"
                  style="padding: 0"
                >
                  <b-form-checkbox
                    v-model="sellMotivation.value"
                    :value="item.value"
                    :id="sellMotivation.name + item.value"
                  >
                  </b-form-checkbox>
                  <label class="fontColor" :for="sellMotivation.name + item.value">
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">生活機能： </label>
              </b-col>
              <b-col class="d-flex flex-wrap mt-2">
                <div
                  v-for="(item, index) in lifeFunction.options"
                  :key="index"
                  class="d-flex mr-1"
                  style="padding: 0"
                >
                  <b-form-checkbox
                    v-model="lifeFunction.value"
                    :value="item.value"
                    :id="lifeFunction.name + item.value"
                  >
                  </b-form-checkbox>
                  <label
                    class="fontColor"
                    :for="lifeFunction.name + item.value"
                  >
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>
    <script>
import { mapState } from "vuex";
// import { Toast } from "./../../utils/helper";
// import BaseTable from "./../../components/BaseTable";
// import SearchSelect from "./../../../components/SearchSelect";
import DateField from "./../../../components/DateField";
// import AddressField from "./../../components/AddressField";
export default {
  components: {
    // BaseTable,
    // SearchSelect,
    // AddressField,
    DateField,
  },
  data() {
    return {
      floorHouseholds: "",
      allHouseholds: "",
      elevatorNumber: "",
      objectLayout: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        ckeckbox: false,
      },
      objectDirection: {
        objectValue: null,
        buildingValue: null,
        gateValue: null,
        windowValue: null,
        options: [
          { value: null, text: "請選擇" },
          { value: "1", text: "座南朝北" },
          { value: "2", text: "座北朝南" },
          { value: "3", text: "座東朝西" },
          { value: "4", text: "座西朝東" },
          { value: "5", text: "座東北朝西南" },
          { value: "6", text: "座西北朝東南" },
          { value: "7", text: "座東南朝西北" },
          { value: "8", text: "座西南朝東北" },
        ],
      },
      lighting: {
        value: null,
        options: [
          { value: null, text: "請選擇" },
          { value: "1", text: "1" },
          { value: "2", text: "2" },
          { value: "3", text: "3" },
          { value: "4", text: "4" },
        ],
      },
      sideRoom: {
        value: "0",
        options: [
          { value: "0", text: "否" },
          { value: "1", text: "是" },
        ],
      },
      darkRoom: {
        value: null,
        options: [
          { value: null, text: "請選擇" },
          { value: "1", text: "1" },
          { value: "2", text: "2" },
          { value: "3", text: "3" },
          { value: "4", text: "4" },
          { value: "5", text: "5" },
          { value: "6", text: "6" },
          { value: "7", text: "7" },
          { value: "8", text: "8" },
          { value: "9", text: "9" },
          { value: "10", text: "10" },
          { value: "11", text: "11" },
          { value: "12", text: "12" },
        ],
      },
      buildingMaterials: {
        value: null,
        options: [
          { value: null, text: "請選擇" },
          { value: "1", text: "鋼骨鋼筋混凝土" },
          { value: "2", text: "鋼骨混凝土" },
          { value: "3", text: "加強磚造" },
          { value: "4", text: "磚造" },
          { value: "5", text: "木造" },
          { value: "6", text: "鋼筋混凝土加強磚造" },
          { value: "7", text: "鋼骨鋼板混凝土" },
          { value: "8", text: "純鋼構" },
          { value: "9", text: "鋼骨構造" },
          { value: "10", text: "其他" },
        ],
      },
      spacerMaterial: {
        name: "spacerMaterial",
        value: [],
        options: [
          { text: "磚牆", value: "0", grid: "col-3" },
          { text: "木板", value: "1", grid: "col-3" },
          { text: "矽酸鈣板", value: "2", grid: "col-3" },
          { text: "輕隔間", value: "3", grid: "col-3" },
          { text: "其他", value: "4", grid: "col-3" },
          { text: "無隔間", value: "5", grid: "col-3", input: true },
        ],
        input: "",
      },
      exteriorWall: {
        input: "",
        select: {
          value: null,
          options: [
            { value: null, text: "請選擇" },
            { value: "1", text: "馬賽克" },
            { value: "2", text: "二丁掛" },
            { value: "3", text: "大理石" },
            { value: "4", text: "方塊磚" },
            { value: "5", text: "花崗石" },
            { value: "6", text: "玻璃帷幕" },
            { value: "7", text: "其他" },
          ],
        },
      },
      ceilingType: {
        value: null,
        options: [{ value: null, text: "請選擇" }],
      },
      floorType: {
        value: null,
        options: [{ value: null, text: "請選擇" }],
      },
      usageStatus: {
        name: "usageStatus",
        value: "0",
        options: [
          { text: "空屋(空地)", value: "0", grid: "col-2" },
          { text: "自用", value: "1", grid: "col-2" },
          { text: "借用", value: "4", grid: "col-2" },
          { text: "出租", value: "2", grid: "", date: true },
          { text: "占用", value: "3", grid: "", input: true },
        ],
        input1: "",
        input2: "",
      },
      cooperationObject: {
        name: "cooperationObject",
        value: "0",
        options: [
          { text: "無", value: "0" },
          { text: "有", value: "1" },
        ],
      },
      landSize: {
        width: "",
        height: "",
      },
      managementFee: {
        value: "1",
        input: "",
      },
      managemenFrequency: {
        value: "1",
        input: "",
      },
      elementarySchool:'',
      juniorHighSchool:'',
      parkName:'',
      marketName:'',
      businessDistrict:'',
      sellMotivation: {
        name: "sellMotivation",
        value: [],
        options: [
          { text: "換新屋", value: "0", grid: "col-3" },
          { text: "面積不合", value: "1", grid: "col-3" },
          { text: "投資獲利", value: "2", grid: "col-3" },
          { text: "轉換現金", value: "3", grid: "col-3" },
          { text: "通勤不便", value: "4", grid: "col-3" },
          { text: "移民", value: "5", grid: "col-3" },
          { text: "環境品質提升", value: "6", grid: "col-3" },
          { text: "資產運用", value: "7", grid: "col-3" },
        ],
        input: "",
      },
      lifeFunction: {
        name: "lifeFunction",
        value: [],
        options: [
          { text: "便利商店", value: "0", grid: "col-3" },
          { text: "醫療機構", value: "1", grid: "col-3" },
          { text: "百貨公司", value: "2", grid: "col-3" },
          { text: "夜市", value: "3", grid: "col-3" },
          { text: "警察局", value: "4", grid: "col-3" },
        ],
        input: "",
      },
    };
  },
  methods: {
    // addList() {
    //   this.MRTRoute.push({
    //     category1: {
    //       value: null,
    //       options: [
    //         { value: null, text: "請選擇" },
    //         { value: "1", text: "台北捷運" },
    //         { value: "2", text: "桃園捷運" },
    //         { value: "3", text: "台中捷運" },
    //         { value: "4", text: "高雄捷運" },
    //       ],
    //     },
    //     category2: {
    //       value: null,
    //       options: [{ value: null, text: "請選擇" }],
    //     },
    //     category3: {
    //       value: null,
    //       options: [{ value: null, text: "請選擇" }],
    //     },
    //   });
    //   console.log("addList");
    // },
    // deleteList(index) {
    //   console.log("deleteList", index);
    //   this.MRTRoute.splice(index, 1);
    // },

    // addTag() {
    //   let data = this.specialTag.specialTagSelect.allList.find(
    //     (x) => x.text == this.specialTag.specialTagSelect.keyWords
    //   );
    //   this.specialTag.checkGroup.push({
    //     id: data.value,
    //     name: data.text,
    //   });
    //   this.specialTag.specialTagSelect.keyWords = "";
    // },
    // deleteTag(index) {
    //   this.specialTag.checkGroup.splice(index, 1);
    // },

    // apartmentComplexEdit() {
    //   console.log("apartmentComplexEdit");
    // },
  },
  computed: {
    ...mapState(["backgroundColor"]),
  },
  created() {},
  watch: {
  },
};
</script>
    <style scoped>
</style>