export default [
  {
    title: "人員系統",
    icon: "fa-user",
    children: [
      {
        title: "公會管理",
        route: "GuildsManagement",
      },
      {
        title: "集團管理",
        route: "GroupManagement",
      },
      {
        title: "公司管理",
        route: "CompanyManagement",
      },
      // {
      //   title: "組別管理",
      //   route: "DepartmentManagement",
      // },
      {
        title: "使用者管理",
        route: "UsersManagement",
      },
    ],
  },

  {
    title: "權限管理模組",
    icon: "fa-user",
    children: [
      {
        title: "群組權限維護",
        route: "PermissionManagement",
      },
    ],
  },
  
  {
    title: "物件資料維護",
    route: "ObjectManagement",
    icon: "fa-layer-group",
  },
];
