<template>
  <div>
    <div class="d-flex">
      <b-form-select
        v-model="address.cityID"
        :options="cityoptions"
        :disabled="disabled"
        @change="resetTown()"
      />
      <b-form-select
        v-model="address.town.ID"
        :options="address.town.options"
        :disabled="disabled"
      />
    </div>

    <b-form-input
      id="input-lg"
      placeholder="請輸入地址"
      type="text"
      v-model="address.info"
      name="AddressFieldInfo"
      :disabled="disabled"
    />
  </div>
</template>
<script>
// import SearchSelect from "@/components/SearchSelect.vue";
import { Toast } from "@/utils/helper";
import sharedModuleAPI from "@/apis/sharedModuleAPI";
export default {
  components: {
    // SearchSelect,
  },
  props: {
    addressData: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cityoptions: [],

      // 父層資料格式
      // address: {
      //   cityID: null,
      //   town: {
      //     ID: null,
      //     options: [{ value: null, text: "請選擇區域" }],
      //   },
      //   info: "",
      // },
    };
  },
  methods: {
    resetTown() {
      this.address.town.ID = null;
    },
    async getCityList() {
      try {
        const response = await sharedModuleAPI.getCityList({
          cityID: null,
          status: null,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        let cityData = response.data.list;
        this.cityoptions = [{ value: null, text: "請選擇縣市" }];
        for (var index in cityData) {
          let item = cityData[index];
          this.cityoptions.push({
            value: item.id,
            text: item.nm,
          });
        }
      } catch (error) {
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async getTownList() {
      try {
        const response = await sharedModuleAPI.getTownList({
          cityID: this.addressData.cityID,
          townID: null,
          status: null,
        });
        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        let townData = response.data.list;
        this.address.town.options = [{ value: null, text: "請選擇區域" }];
        for (var index in townData) {
          let item = townData[index];
          this.address.town.options.push({
            value: item.id,
            text: item.nm,
          });
        }
      } catch (error) {
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
  },
  watch: {
    "addressData.cityID"(newValue, oldValue) {
      console.log(oldValue);
      if (newValue !== null) {
        this.getTownList();
      } else {
        this.address.town.options = [{ value: null, text: "請選擇區域" }];
      }
    },
  },
  computed: {
    address: {
      get() {
        return this.addressData;
      },
      set(val) {
        this.$emit("update:addressData", val);
      },
    },
  },
  created() {
    this.getCityList();
  },
};
</script>
<style scoped>
.mr5 {
  margin-right: 0.5rem;
}
.ml5 {
  margin-left: 0.5rem;
}
</style>
