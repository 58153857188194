var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mainContent"},[_c('p',{staticClass:"detailTitle"},[_vm._v(_vm._s(_vm.showTitle))]),_c('b-card',{staticClass:"mb-3",class:_vm.backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-row',[_c('b-col',{staticClass:"col-3 titleCSS",attrs:{"xl":"4","lg":"5","md":"3"}},[_c('label',{staticClass:"fontCSS",attrs:{"for":_vm.$route.name + 'dep1'}},[_vm._v("組別名稱： ")])]),_c('b-col',[_c('b-form-input',{class:_vm.backgroundColor == 'dark' ? 'text-light bg-secondary' : '',attrs:{"id":_vm.$route.name + 'dep1',"name":_vm.$route.name + 'dep1',"placeholder":"請輸入組別名稱","type":"text","disabled":_vm.viewSaveType.type == 'read' || _vm.viewSaveType.type == 'delete',"trim":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-row',[_c('b-col',{staticClass:"col-3 titleCSS",attrs:{"xl":"4","lg":"5","md":"3"}},[_c('label',{staticClass:"fontCSS",attrs:{"for":_vm.$route.name + 'dep2'}},[_vm._v("組別組長： ")])]),_c('b-col',[_c('b-form-select',{class:_vm.backgroundColor == 'dark' ? 'text-light bg-secondary' : '',attrs:{"id":_vm.$route.name + 'dep2',"options":_vm.leader.options,"disabled":_vm.viewSaveType.type == 'read' || _vm.viewSaveType.type == 'delete'},model:{value:(_vm.leader.value),callback:function ($$v) {_vm.$set(_vm.leader, "value", $$v)},expression:"leader.value"}})],1)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-row',[_c('b-col',{staticClass:"col-3 titleCSS",attrs:{"xl":"4","lg":"5","md":"3"}},[_c('label',{staticClass:"fontCSS"},[_vm._v("啟用日期： ")])]),_c('b-col',[_c('DateField',{attrs:{"isabled":_vm.viewSaveType.type == 'read' ||
                  _vm.defaultTeam == 1 ||
                  _vm.viewSaveType.type == 'delete'
                    ? false
                    : true,"value":_vm.startDate},on:{"update:value":function($event){_vm.startDate=$event}}})],1)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-row',[_c('b-col',{staticClass:"col-3 titleCSS",attrs:{"xl":"4","lg":"5","md":"3"}},[_c('label',{staticClass:"fontCSS"},[_vm._v("停用日期： ")])]),_c('b-col',[_c('DateField',{attrs:{"isabled":_vm.viewSaveType.type == 'read' ||
                  _vm.viewSaveType.type == 'edit' 
                    ? false
                    : true,"value":_vm.endDate},on:{"update:value":function($event){_vm.endDate=$event}}})],1)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-row',[_c('b-col',{staticClass:"col-3 titleCSS",attrs:{"xl":"4","lg":"5","md":"3"}},[_c('label',{staticClass:"fontCSS"},[_vm._v("狀態： ")])]),_c('b-col',{staticClass:"d-flex align-items-center"},_vm._l((this.statusRadio.options),function(item,index){return _c('div',{key:index,staticClass:"d-flex mr-1 mt-2"},[_c('b-form-radio',{attrs:{"value":item.value,"id":_vm.statusRadio.name + item.value,"disabled":_vm.viewSaveType.type !== 'delete' &&
                    _vm.viewSaveType.type !== 'restart'},on:{"change":function($event){return _vm.changeStatus()}},model:{value:(_vm.statusRadio.value),callback:function ($$v) {_vm.$set(_vm.statusRadio, "value", $$v)},expression:"statusRadio.value"}}),_c('label',{staticClass:"fontColor",attrs:{"for":_vm.statusRadio.name + item.value}},[_vm._v(" "+_vm._s(item.text))])],1)}),0)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-row',[_c('b-col',{staticClass:"col-3 titleCSS",attrs:{"xl":"4","lg":"5","md":"3"}},[_c('label',{staticClass:"fontCSS",attrs:{"for":_vm.$route.name + 'companyManagment7'}},[_vm._v("排序： ")])]),_c('b-col',[_c('b-form-input',{class:_vm.backgroundColor == 'dark' ? 'text-light bg-secondary' : '',attrs:{"id":_vm.$route.name + 'companyManagment7',"name":_vm.$route.name + 'companyManagment7',"placeholder":"請輸入排序，數字愈小愈前面","type":"number","disabled":!(_vm.viewSaveType.type == 'read' ||
                  _vm.defaultTeam == 1 ||
                  _vm.viewSaveType.type == 'delete')
                    ? false
                    : true,"trim":""},model:{value:(_vm.sort),callback:function ($$v) {_vm.sort=$$v},expression:"sort"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"col-12 mt-2 mb-2"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewSaveType.type !== 'read'),expression:"viewSaveType.type !== 'read'"}],staticClass:"mr-2",attrs:{"variant":"primary","disabled":_vm.isProcessing},on:{"click":function($event){return _vm.saveAction()}}},[_vm._v("確定")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.backAction()}}},[_vm._v("返回")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }