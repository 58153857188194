<template>
  <div>
    <div class="mainContent">
      <div class="detailTitle">公司管理</div>
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row>
          <div
            v-if="logoUrl"
            style="
              width: 150px;
              height: 150px;
              margin-left: 2rem;
              margin-top: 3rem;
            "
          >
            <img
              style="height: 100%; width: 100%"
              :src="logoUrl"
              alt="未上傳Logo"
            />
          </div>
          <div
            :style="
              logoUrl
                ? 'width: calc(100% - 200px); margin: auto 0'
                : 'width: 90%; margin: auto auto'
            "
          >
            <b-row>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS" :for="$route.name + 'com1'"
                    >公會名稱：
                  </label>
                </div>
                <b-form-input
                  :id="$route.name + 'com1'"
                  :name="$route.name + 'com1'"
                  placeholder="請輸入公會名稱"
                  v-model="guildName"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  disabled
                  trim
                />
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS" :for="$route.name + 'com2'"
                    >集團名稱：
                  </label>
                </div>
                <b-form-input
                  :id="$route.name + 'com2'"
                  :name="$route.name + 'com2'"
                  placeholder="請輸入集團名稱"
                  v-model="groupName"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  disabled
                  trim
                />
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS" :for="$route.name + 'com3'"
                    >加盟品牌：
                  </label>
                </div>
                <b-form-input
                  :id="$route.name + 'com3'"
                  :name="$route.name + 'com3'"
                  placeholder="請輸入加盟品牌"
                  v-model="branchName"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                  disabled
                />
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS" :for="$route.name + 'com4'"
                    >顯示名稱：
                  </label>
                </div>
                <b-form-input
                  :id="$route.name + 'com4'"
                  :name="$route.name + 'com4'"
                  placeholder="請輸入顯示名稱"
                  v-model="showName"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  disabled
                  trim
                />
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS" :for="$route.name + 'com5'"
                    >公司名稱：
                  </label>
                </div>
                <b-form-input
                  :id="$route.name + 'com5'"
                  :name="$route.name + 'com5'"
                  placeholder="請輸入公司名稱"
                  v-model="companyName"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  disabled
                  trim
                />
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS" :for="$route.name + 'com6'"
                    >公司統編：
                  </label>
                </div>
                <b-form-input
                  :id="$route.name + 'com6'"
                  :name="$route.name + 'com6'"
                  placeholder="請輸入公司統編"
                  v-model="companyNumber"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  disabled
                  trim
                />
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS" :for="$route.name + 'com7'"
                    >公司地址：
                  </label>
                </div>
                <b-form-input
                  :id="$route.name + 'com7'"
                  :name="$route.name + 'com7'"
                  placeholder="請輸入公司地址"
                  v-model="companyAddress"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  disabled
                  trim
                />
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS" :for="$route.name + 'com8'"
                    >公司電話：
                  </label>
                </div>
                <b-form-input
                  :id="$route.name + 'com8'"
                  :name="$route.name + 'com8'"
                  placeholder="請輸入公司電話"
                  v-model="companyTel"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  disabled
                  trim
                />
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS">啟用日期： </label>
                </div>
                <div style="width: 100%">
                  <DateField
                    :isabled="false"
                    :value.sync="startDate"
                  ></DateField>
                </div>
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS">停用日期： </label>
                </div>
                <div style="width: 100%">
                  <DateField :isabled="false" :value.sync="endDate"></DateField>
                </div>
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS">狀態： </label>
                </div>
                <div
                  v-for="(item, index) in this.statusRadio.options"
                  :key="index"
                  class="d-flex mr-1 mt-2"
                >
                  <b-form-radio
                    v-model="statusRadio.value"
                    :value="item.value"
                    :id="statusRadio.name + item.value"
                    disabled
                  >
                  </b-form-radio>
                  <label class="fontColor" :for="statusRadio.name + item.value">
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS" :for="$route.name + 'com122'"
                    >公司總人數：
                  </label>
                </div>
                <b-form-input
                  :id="$route.name + 'com122'"
                  :name="$route.name + 'com122'"
                  placeholder=""
                  v-model="taxHeadcount"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  disabled
                  trim
                />
              </b-col>
            </b-row>
          </div>
          <div class="col-12 mt-2">
            <b-button
              v-show="this.userPremission.search('新增') !== -1"
              :disabled="isProcessing"
              class="mr-2"
              variant="primary"
              @click="createDepartment()"
              >新增組別</b-button
            >
            <b-button
              v-show="this.userPremission.search('新增') !== -1"
              :disabled="isProcessing"
              class="mr-2"
              variant="primary"
              @click="createUser()"
              >新增人員</b-button
            >
            <b-button
              :disabled="isProcessing"
              class="mr-2"
              variant="primary"
              @click="searchUsers()"
              >人員查詢</b-button
            >
          </div>
        </b-row>
      </b-card>
      <b-card>
        <b-col class="d-flex align-items-center">
          <div
            v-for="(item, index) in this.tableStatusRadio.options"
            :key="index"
            class="d-flex mr-1 align-items-center"
          >
            <b-form-radio
              style="margin-bottom: 0.5rem"
              v-model="tableStatusRadio.value"
              :value="item.value"
              :id="'tableStatusRadio' + item.value"
              @change="getCompanyDepartmentList()"
            >
            </b-form-radio>
            <label class="fontColor" :for="'tableStatusRadio' + item.value">
              {{ item.text }}</label
            >
          </div>
        </b-col>
        <BaseTable
          v-show="table.items.length !== 0"
          :fields="table.fields"
          :items="table.items"
          :currentPage="table.currentPage"
          :totalItems="table.totalItems"
          :perPage="table.perPage"
          :text_btnFunction="departmentManagment"
          :clickPage="clickPage"
        />
      </b-card>

      <div class="col-12 mt-2 mb-2">
        <b-button @click="backAction()" variant="outline-secondary"
          >返回</b-button
        >
      </div>
    </div>
    <!-- modal -->
    <b-modal
      :id="$route.name"
      title="人員資料"
      hide-header-close
      :headerBgVariant="backgroundColor"
      :headerTextVariant="backgroundColor == 'dark' ? 'light' : 'dark'"
      :bodyBgVariant="backgroundColor"
      :bodyTextVariant="backgroundColor == 'dark' ? 'light' : 'dark'"
      :footerBgVariant="backgroundColor"
      :footerTextVariant="backgroundColor == 'dark' ? 'light' : 'dark'"
      centered
      size="lg"
    >
      <div
        :class="backgroundColor == 'dark' ? 'modalTypeDark' : 'modalTypeLight'"
      >
        <template v-if="modalData.viewSaveType.type == 'search'">
          <b-row class="align-items-center mb-1">
            <b-col class="col-6 d-flex">
              <label
                style="font-size: 20px; white-space: nowrap"
                :for="$route.name + 'modal1'"
              >
                組別：
              </label>

              <b-form-input
                :id="$route.name + 'modal1'"
                :name="$route.name + 'modal1'"
                placeholder="請輸入組別名稱"
                v-model="modalData.department"
                type="text"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
            </b-col>
            <b-col class="col-6 d-flex">
              <label
                style="font-size: 20px; white-space: nowrap"
                :for="$route.name + 'modal1'"
              >
                人員：
              </label>

              <b-form-input
                :id="$route.name + 'modal1'"
                :name="$route.name + 'modal1'"
                placeholder="請輸入人員姓名"
                v-model="modalData.name"
                type="text"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
            </b-col>
            <b-col class="col-6 d-flex">
              <label
                style="font-size: 20px; white-space: nowrap"
                :for="$route.name + 'modal1'"
              >
                在職狀況：
              </label>

              <b-col class="d-flex align-items-center" style="padding-left: 0">
                <div
                  v-for="(item, index) in this.modalData.statusRadio.options"
                  :key="index"
                  class="d-flex mr-1 align-items-center"
                >
                  <b-form-radio
                    v-model="modalData.statusRadio.value"
                    style="margin-bottom: 0.5rem"
                    :value="item.value"
                    :id="modalData.statusRadio.name + item.value"
                  >
                  </b-form-radio>
                  <label
                    class="fontColor"
                    :for="modalData.statusRadio.name + item.value"
                  >
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
            </b-col>
          </b-row>
          <div class="col-12 mb-1" style="text-align: center">
            <b-button
              :disabled="isProcessing"
              @click="getCompanyUserList(1)"
              variant="outline-primary"
              >查詢</b-button
            >
          </div>
        </template>

        <BaseTable
          v-show="modalData.table.items.length !== 0"
          :fields="modalData.table.fields"
          :items="modalData.table.items"
          :currentPage="modalData.table.currentPage"
          :totalItems="modalData.table.totalItems"
          :perPage="modalData.table.perPage"
          :editAction="editUser"
          :detailAction="editUser"
          :clickPage="clickPageModal"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-row>
          <b-button variant="outline-secondary" @click="cancel">
            返回
          </b-button>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "@/utils/helper";
import BaseTable from "@/components/BaseTable";
import DateField from "@/components/DateField";
import usersAPI from "@/apis/usersAPI";
import moment from "moment";

export default {
  components: {
    BaseTable,
    DateField,
  },
  data() {
    return {
      userPremission: "",
      isProcessing: false,

      modalData: {
        viewSaveType: {
          id: null,
          type: "",
        },
        department: "",
        name: "",

        statusRadio: {
          name: "modalDatastatusRadio",
          value: 1,
          options: [
            { text: "在職", value: 1 },
            { text: "離職", value: 0 },
            { text: "留停", value: 2 },
          ],
        },
        searchData: null,
        table: {
          currentPage: 1,
          perPage: 5,
          totalItems: 0,
          fields: [
            {
              key: "COL0",
              label: "",
              type: "default_btn",
            },
            { key: "COL1", label: "組別" },
            { key: "COL2", label: "姓名" },
            { key: "COL3", label: "職位" },
            { key: "COL4", label: "角色" },
            { key: "COL6", label: "狀態" },
            { key: "COL5", label: "電話" },
          ],
          items: [],
        },
      },

      logoUrl: "",

      guildName: "",
      groupName: "",
      branchName: "",
      branchSelect: {
        value: null,
        options: [
          { value: null, text: "請選擇加盟品牌" },
          { value: "1", text: "中信房屋" },
          { value: "2", text: "大家房屋" },
          { value: "3", text: "住商房屋" },
          { value: "4", text: "21世紀房屋" },
        ],
      },
      showName: "",
      companyName: "",
      companyNumber: "",
      companyID: null,
      companySID: null,
      companyAddress: "",
      companyTel: "",
      startDate: "",
      endDate: "",
      statusRadio: {
        name: "statusRadio",
        value: 1,
        options: [
          { text: "啟用", value: 1 },
          { text: "停用", value: 0 },
        ],
      },
      logoFile: null,
      tableStatusRadio: {
        name: "tableStatusRadio",
        value: 1,
        options: [
          { text: "啟用", value: 1 },
          { text: "停用", value: 0 },
        ],
      },
      taxHeadcount: null,

      searchData: null,
      table: {
        currentPage: 1,
        perPage: 5,
        totalItems: 0,
        fields: [
          {
            key: "COL0",
            label: "",
            type: "text_btn",
          },
          {
            key: "COL1",
            label: "排序",
          },
          // { key: "COL1", label: "編號" },
          { key: "COL2", label: "組別名稱" },
          { key: "COL3", label: "組長" },
          { key: "COL4", label: "人數" },
          {
            key: "COL5",
            label: "人員管理",
            type: "text_btn",
            css: "center",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    backAction() {
      this.$router.push({
        name: "CompanyManagement",
      });
    },
    async getCompanyData() {
      try {
        this.isProcessing = true;

        const response = await usersAPI.getCompanyData({
          taxSID: this.$route.params.id,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        let data = response.data.list[0];
        this.guildName = data.assocNM;
        this.groupName = data.corpNM;
        this.branchName = data.brandNM;
        this.showName = data.shortName;
        this.companyName = data.companyNM;
        this.companyNumber = data.companyID;
        this.companyID = data.taxID;
        this.companySID = data.taxSID;
        this.companyAddress = data.address;
        this.companyTel = data.tel;
        this.startDate = moment(data.startTime).format("YYYY-MM-DD");
        this.endDate = moment(data.endTime).format("YYYY-MM-DD");
        this.statusRadio.value = Number(data.status);
        this.taxHeadcount = data.taxHeadcount;

        if (data.filePathList.length > 0) {
          this.logoUrl = data.filePathList[0].linkPath;
        }
        this.getCompanyDepartmentList();
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async getCompanyDepartmentList() {
      try {
        this.isProcessing = true;

        const response = await usersAPI.getCompanyDepartmentList({
          roW_INDEX: (this.table.currentPage - 1) * this.table.perPage,
          rows: this.table.perPage,
          taxID: this.companyID,
          status: this.tableStatusRadio.value,
          theday: null,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        this.searchData = response.data;

        this.setTableData();
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    clickPage(page) {
      this.table.currentPage = page;
      this.getCompanyDepartmentList();
    },
    setTableData() {
      this.table.items.splice(0);
      this.table.totalItems = this.searchData.dataTotal;

      for (var index in this.searchData.list) {
        let data = this.searchData.list[index];
        let userbtn = [
          {
            text: "人員管理",
            variant: "outline-primary",
          },
        ];
        let combtn = [];
        if (this.tableStatusRadio.value == 1) {
          if (this.userPremission.search("修改") !== -1) {
            combtn.push({
              text: "修改組別",
              variant: "secondary",
            });
          } else {
            combtn.push({
              text: "瀏覽",
              variant: "info",
            });
          }

          if (
            data.teamHeadcount == 0 &&
            this.userPremission.search("停用") !== -1
          ) {
            if (data.defaultTeam ==1) {
              console.log("預設");
            } else {
              combtn.push({
                text: "停用組別",
                variant: "danger",
              });
            }
          }
        } else {
          if (this.userPremission.search("重新啟用") !== -1 &&
             (this.statusRadio.value != 0 )   ) {
            combtn.push({
              text: "重新啟用",
              variant: "success",
            });
          } else {
            // combtn.push({
            //   text: "瀏覽",
            //   variant: "info",
            // });
          }
        }
        this.table.items.push({
          COL0: combtn,
          COL1: data.sort,
          COL2: data.teamNM,
          COL3: data.teamEmpNM,
          COL4: data.teamHeadcount,
          COL5: data.teamHeadcount == 0 ? "" : userbtn,
        });
      }
    },

    departmentManagment(field, rowIndex, itemIndex) {
      if (this.tableStatusRadio.value == 1) {
        if (field == "COL5") {
          this.editDepartmentUsers(rowIndex);
        } else {
          if (itemIndex == 0) {
            this.editDepartment(rowIndex);
          } else {
            this.deleteDepartment(rowIndex);
          }
        }
      } else {
        this.restartDepartment(rowIndex);
      }
    },
    createDepartment() {
      let id =
        this.companyID + "." + this.companyNumber + "." + this.companySID;
      this.$router.push({
        name: "CompanyManagementDepartmentCreate",
        params: { id: id },
      });
    },
    editDepartment(index) {
      let id =
        this.companyID +
        "." +
        this.companyNumber +
        "." +
        this.companySID +
        "." +
        this.searchData.list[index].teamSID +
        "." +
        index;
      this.$router.push({
        name: "CompanyManagementDepartmentEdit",
        params: { id: id },
        query:{ defaultTeam: this.searchData.list[index].defaultTeam}
      });
    },
    deleteDepartment(index) {
      let id =
        this.companyID +
        "." +
        this.companyNumber +
        "." +
        this.companySID +
        "." +
        this.searchData.list[index].teamSID;
      this.$router.push({
        name: "CompanyManagementDepartmentDelete",
        params: { id: id },
      });
    },
    restartDepartment(index) {
      let id =
        this.companyID +
        "." +
        this.companyNumber +
        "." +
        this.companySID +
        "." +
        this.searchData.list[index].teamSID;
      this.$router.push({
        name: "CompanyManagementDepartmentRestart",
        params: { id: id },
      });
    },
    searchUsers() {
      this.modalData.viewSaveType.type = "search";
      this.modalData.viewSaveType.id = null;
      this.modalData.table.currentPage = 1;
      this.modalData.table.items.splice(0);
      this.$bvModal.show(this.$route.name);
    },
    editDepartmentUsers(idx) {
      this.modalData.viewSaveType.type = "edit";
      this.modalData.viewSaveType.id = this.searchData.list[idx].teamID;
      this.modalData.table.currentPage = 1;
      this.modalData.table.items.splice(0);
      this.$bvModal.show(this.$route.name);
      this.getCompanyDepartmentUserList();
    },
    async getCompanyDepartmentUserList() {
      try {
        this.isProcessing = true;

        const response = await usersAPI.getCompanyDepartmentUserList({
          roW_INDEX:
            (this.modalData.table.currentPage - 1) *
            this.modalData.table.perPage,
          rows: this.modalData.table.perPage,
          teamID: this.modalData.viewSaveType.id,
          status: 1,
          theday: null,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        this.modalData.searchData = response.data;
        this.setModalTable();
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async getCompanyUserList(page) {
      this.modalData.table.currentPage = page
        ? page
        : this.modalData.table.currentPage;
      try {
        this.isProcessing = true;

        const response = await usersAPI.getCompanyUserList({
          roW_INDEX:
            (this.modalData.table.currentPage - 1) *
            this.modalData.table.perPage,
          rows: this.modalData.table.perPage,
          teamNM: this.modalData.department,
          accountNM: this.modalData.name,
          taxID: this.companyID,
          teamID: null,
          accountID: null,
          status: this.modalData.statusRadio.value,
          theday: null,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        this.modalData.searchData = response.data;
        this.setModalTable();
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    setModalTable() {
      this.modalData.table.items.splice(0);
      this.modalData.table.totalItems = this.modalData.searchData.dataTotal;
      let array = [];
      for (var index in this.modalData.searchData.list) {
        let data = this.modalData.searchData.list[index];
        let btn = "";
        if (data.hireState == "離職") {
          btn = "detail";
        } else {
          // btn = "edit";
          if (this.userPremission.search("修改") !== -1) {
            btn = "edit";
          } else {
            btn = "detail";
          }
        }

        array.push({
          COL0: btn,
          COL1: data.teamNM,
          COL2: data.empNM,
          COL3: data.positionNM,
          COL4: data.empRole,
          COL5: data.mobile,
          COL6: data.hireState,
        });
      }
      this.modalData.table.items = array;
    },
    clickPageModal(page) {
      this.modalData.table.currentPage = page;
      if (this.modalData.viewSaveType.type == "search") {
        this.getCompanyUserList();
      } else {
        this.getCompanyDepartmentUserList();
      }
    },

    createUser() {
      let id =
        this.companyID + "." + this.companyNumber + "." + this.companySID;
      this.$router.push({
        name: "CompanyManagementUserCreate",
        params: { id: id },
      });
    },
    editUser(filed, index) {
      console.log(filed);
      this.$bvModal.hide(this.$route.name);
      let status = "";
      if (this.modalData.searchData.list[index].hireState == "在職") {
        status = "1";
      } else if (this.modalData.searchData.list[index].hireState == "離職") {
        status = "2";
      } else if (this.modalData.searchData.list[index].hireState == "留停") {
        status = "3";
      }
      if (this.userPremission.search("修改") == -1) {
        status = "2";
      }
      let id =
        this.companyID +
        "." +
        this.companyNumber +
        "." +
        this.companySID +
        "." +
        this.modalData.searchData.list[index].pkID +
        "." +
        this.modalData.searchData.list[index].empID +
        "." +
        status;
      this.$router.push({
        name: "CompanyManagementUserDetail",
        params: { id: id },
      });
    },
  },
  watch: {},
  computed: {
    ...mapState(["backgroundColor", "currentUser", "currentRoute"]),
  },

  created() {
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;

    this.getCompanyData();
  },
};
</script>
<style scoped>
.title {
  white-space: nowrap;
  min-width: 120px;
  display: flex;
  justify-content: end;
}
</style>