<template>
  <div>
    <div class="mainContent">
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row class="mb-2">
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '1'"
                  >關鍵字查詢：
                </label>
              </b-col>
              <b-col class="">
                <b-form-input
                  :id="$route.name + '1'"
                  :name="$route.name + '1'"
                  placeholder="請輸入關鍵字"
                  v-model="keyword"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '2'"
                  >銷售編號：
                </label>
              </b-col>
              <b-col class="">
                <b-form-input
                  :id="$route.name + '2'"
                  :name="$route.name + '2'"
                  placeholder="請輸入銷售編號"
                  v-model="sellNumbar"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '3'"
                  >委託編號：
                </label>
              </b-col>
              <b-col class="">
                <b-form-input
                  :id="$route.name + '3'"
                  :name="$route.name + '3'"
                  placeholder="請輸入委託編號"
                  v-model="entrustNumber"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '4'"
                  >案件狀態：
                </label>
              </b-col>
              <b-col class="">
                <b-form-select
                  :for="$route.name + '4'"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="statusSelect.value"
                  :options="statusSelect.options"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '5'"
                  >物件地址：
                </label>
              </b-col>
              <b-col class="">
                <b-form-input
                  :id="$route.name + '5'"
                  :name="$route.name + '5'"
                  placeholder="請輸入物件地址"
                  v-model="address"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">物件型態： </label>
              </b-col>
              <b-col class="d-flex flex-wrap mt-2">
                <div
                  v-for="(item, index) in objectStatusCheckbox.options"
                  :key="index"
                  class="d-flex mr-3"
                >
                  <b-form-checkbox
                    v-model="objectStatusCheckbox.value"
                    :value="item.value"
                    :id="objectStatusCheckbox.name + item.value"
                    style="margin-top: 0.2rem"
                  >
                  </b-form-checkbox>
                  <label
                    class="fontColor"
                    :for="objectStatusCheckbox.name + item.value"
                  >
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>

          <!-- button -->
          <b-col class="col-12" style="margin-top: 0.5rem">
            <b-button @click="searchAction()" class="mr-2" variant="primary"
              >查詢</b-button
            >
            <b-button
              @click="createAction()"
              class="mr-2"
              variant="outline-primary"
              >新增物件</b-button
            >
          </b-col>
        </b-row>
      </b-card>

      <BaseTable
        v-show="table.items.length !== 0"
        :fields="table.fields"
        :items="table.items"
        :currentPage="table.currentPage"
        :totalItems="table.totalItems"
        :perPage="table.perPage"
        :editAction="editAction"
        :clickPage="clickPage"
        :renderPerPageNumber="renderPerPageNumber"
        :renderTotalItems="true"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "./../../utils/helper";
import BaseTable from "./../../components/BaseTable";
// import SearchSelect from "./../../components/SearchSelect";
// import DateField from "./../../components/DateField";
// import AddressField from "./../../components/AddressField";
export default {
  components: {
    BaseTable,
    // SearchSelect,
    // AddressField,
    // DateField,
  },
  data() {
    return {
      keyword: "",
      sellNumbar: "",
      entrustNumber: "",
      statusSelect: {
        value: null,
        options: [
          { value: null, text: "請選擇案件狀態" },
          { value: "1", text: "輸入中" },
          { value: "2", text: "可售" },
          { value: "3", text: "成交" },
          { value: "4", text: "逾期" },
        ],
      },
      address: "",
      objectStatusCheckbox: {
        name: "objectStatusCheckbox",
        value: [],
        options: [
          { text: "成屋", value: "0" },
          { text: "預售", value: "1" },
          { text: "土地", value: "2" },
        ],
      },
      // table
      searchData: null,
      table: {
        currentPage: 1,
        perPage: 5,
        totalItems: 0,
        fields: [
          { key: "COL0", label: "店長推薦", type: "checkBox" },
          { key: "COL1", label: "開發人員" },
          { key: "COL2", label: "委託編號" },
          { key: "COL3", label: "銷售編號" },
          { key: "COL4", label: "案名" },
          { key: "COL5", label: "地址" },
          { key: "COL6", label: "建物型態" },
          { key: "COL7", label: "總價(萬)" },
          { key: "COL8", label: "附表價(萬)" },
          { key: "COL9", label: "登記坪數" },
          { key: "COL10", label: "委託起訖" },
          { key: "COL11", label: "照片/格局圖" },
          { key: "COL12", label: "刊登外網" },
        ],
        items: [],
      },
      //modal

      ///test///
      APIcourseList: [
        {
          id: "1",
          title: "content1",
          date: "2024-01-01",
          content: "announcement1announcement1",
          file: "",
        },
        {
          id: "2",
          title: "content2",
          date: "2024-01-01",
          content: "announcement2announcement2",
          file: "",
        },
        {
          id: "3",
          title: "content3",
          date: "2024-01-01",
          content: "announcement3announcement3",
          file: "",
        },
        {
          id: "4",
          title: "content4",
          date: "2024-01-01",
          content: "announcement4announcement4",
          file: "",
        },
        {
          id: "5",
          title: "content5",
          date: "2024-01-01",
          content: "announcement5announcement5",
          file: "",
        },
        {
          id: "6",
          title: "content6",
          date: "2024-01-01",
          content: "announcement6announcement6",
          file: "",
        },
        {
          id: "7",
          title: "content7",
          date: "2024-01-01",
          content: "announcement7announcement7",
          file: "",
        },
        {
          id: "8",
          title: "content8",
          date: "2024-01-01",
          content: "announcement8announcement8",
          file: "",
        },
      ],
    };
  },
  methods: {
    searchAction(page) {
      if (page) {
        this.table.currentPage = page;
      }
      if (!this.statusSelect.value) {
        Toast.fire({
          icon: "warning",
          title: "請選擇案件狀態",
        });
        return;
      }

      //call api get data

      let APIcourseList = [...this.APIcourseList];

      let filterData = [];
      filterData = APIcourseList;

      this.table.totalItems = filterData.length;
      let array = filterData.slice(
        (this.table.currentPage - 1) * this.table.perPage,
        this.table.currentPage * this.table.perPage
      );

      this.searchData = array;
      this.setSearchData();
    },
    setSearchData() {
      this.table.items.splice(0);
      this.table.fields.splice(0);
      if (this.statusSelect.value !== "1") {
        this.table.fields = [
          { key: "COL0", label: "店長推薦", type: "checkBox" },
          { key: "COL1", label: "開發人員" },
          { key: "COL2", label: "委託編號" },
          { key: "COL3", label: "銷售編號" },
          { key: "COL4", label: "案名" },
          { key: "COL5", label: "地址" },
          { key: "COL6", label: "建物型態" },
          { key: "COL7", label: "總價(萬)" },
          { key: "COL8", label: "附表價(萬)" },
          { key: "COL9", label: "登記坪數" },
          { key: "COL10", label: "委託起訖" },
          { key: "COL11", label: "照片/格局圖" },
          { key: "COL12", label: "刊登外網" },
        ];
      } else {
        this.table.fields = [
          { key: "COL0", label: "項次" },
          { key: "COL1", label: "物件編號" },
          { key: "COL2", label: "物件名稱" },
          { key: "COL3", label: "委託起日" },
          { key: "COL4", label: "委託訖日" },
          { key: "COL5", label: "開發人員" },
          { key: "COL6", label: "物件狀態" },
          { key: "COL7", label: "藤本明細輸入" },
          { key: "COL8", label: "物件說明2" },
          { key: "COL9", label: "照片/格局圖" },
          { key: "COL10", label: "開燈外網" },
        ];
      }
      let array = [];
      for (var index in this.searchData) {
        let data = this.searchData[index];
        array.push({
          COL0: "edit,delete",
          COL1: data.title,
          COL2: data.title,
          COL3: data.title,
          COL4: data.title,
          COL5: data.title,
          COL6: data.title,
          COL7: data.title,
          COL8: data.title,
          COL9: data.title,
          COL10: data.title,
          COL11: data.title,
          COL12: data.title,
          COL13: data.title,
        });
      }
      this.table.items = array;
    },
    clickPage(page) {
      this.table.currentPage = page;
      this.searchAction();
    },
    renderPerPageNumber(number) {
      this.table.perPage = number;
      this.searchAction();
    },
    createAction() {
      this.$router.push({ name: "ObjectManagementCreate" });
    },
    editAction(field, rowIndex) {
      console.log("edit", field, rowIndex);
      let userID = "test" + this.searchData[rowIndex].id;
      this.$router.push({
        name: "ObjectManagementEdit",
        params: { id: userID },
      });
    },
  },
  computed: {
    ...mapState(["backgroundColor"]),
  },
  created() {},
  watch: {},
};
</script>
<style scoped>
</style>