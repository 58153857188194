<template>
  <div>
    <div class="mainContent">
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row class="mb-3">
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '1'"
                  >群組名稱：
                </label>
              </b-col>
              <b-col>
                <b-form-input
                  :id="$route.name + '1'"
                  :name="$route.name + '1'"
                  placeholder="請輸入群組名稱"
                  v-model="groupName"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col v-show="this.pageType == 'edit'" lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '2'"
                  >使用者人數：
                </label>
              </b-col>
              <b-col>
                <b-form-input
                  :id="$route.name + '2'"
                  :name="$route.name + '2'"
                  placeholder=""
                  v-model="usersNumber"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  disabled
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '3'"
                  >管理處權限：
                </label>
              </b-col>
              <b-col
                style="text-align: start"
                class="d-flex align-items-center"
              >
                <b-form-checkbox
                  :id="$route.name + '3'"
                  v-model="auth"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <b-card :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'">
        <BaseTable
          :fields="table.fields"
          :items="table.items"
          :currentPage="table.currentPage"
          :totalItems="table.totalItems"
          :perPage="table.perPage"
          :text_btnFunction="text_btnFunction"
          :showPagination="false"
        />
      </b-card>

      <div class="col-12 mt-3">
        <b-button
          :disabled="isProcessing"
          @click="saveAction()"
          class="mr-2"
          variant="primary"
          >{{ pageType == "create" ? "確定新增" : "確定修改" }}</b-button
        >
        <b-button
          :disabled="isProcessing"
          @click="backAction()"
          variant="outline-secondary"
          >返回</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "./../../utils/helper";
import BaseTable from "./../../components/BaseTable";
import permissionAPI from "@/apis/permissionAPI";
import sidebarContent from "@/utils/sidebar.js";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      isProcessing: false,

      groupID: null,
      pageType: "",

      groupName: "",
      usersNumber: 0,
      auth: false,
      permission: [],
      basicPermissionList: null,
      permissionList: null,

      //table
      table: {
        currentPage: 1,
        perPage: 100,
        totalItems: 0,
        fields: [
          {
            key: "COL0",
            label: "程式分類",
          },
          { key: "COL1", label: "程式名稱" },
          { key: "COL2", label: "全開/全關", type: "text_btn" },
          { key: "COL3", label: "功能", type: "text_btn" },
        ],
        items: [],
      },
    };
  },
  methods: {
    backAction() {
      this.$router.push({
        name: "PermissionManagement",
      });
    },
    text_btnFunction(field, rowIndex, itemsIndex) {
      if (field == "COL2") {
        this.allPermission(rowIndex);
      } else {
        this.perPermission(rowIndex, itemsIndex);
      }
    },
    allPermission(rowIndex) {
      let filed = this.table.items[rowIndex];
      let object = this.permission.find((a) => a.id == filed.groupID);
      let Anumber = -1;
      if (object.list.split("#")[0] == "") {
        Anumber = 0;
      } else {
        Anumber = object.list.split("#").length;
      }
      let number = this.table.items[rowIndex].COL3.length;
      if (Anumber == number) {
        object.list = "";
      } else {
        object.list = this.basicPermissionList[rowIndex].progType;
      }
      this.setPerTable();
    },
    perPermission(rowIndex, itemsIndex) {
      let id = this.table.items[rowIndex].groupID;
      let item = this.permission.find((c) => c.id == id);
      let object = this.table.items[rowIndex].COL3[itemsIndex];
      item.list = "#" + item.list;
      if (object.variant == "success") {
        item.list = item.list.replace("#" + object.text, "");
      } else {
        if (item.list == "#") {
          item.list = item.list + object.text;
        } else {
          item.list = item.list + "#" + object.text;
        }
      }
      item.list = item.list.replace("#", "");
      this.setPerTable();
    },

    //get basic
    async getbasicPermissionList() {
      try {
        this.isProcessing = true;
        let inputData = {};

        const response = await permissionAPI.getProgBasicList(inputData);

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        let array = [];
        let arrayAll = [];
        for (var index in response.data.list) {
          let item = response.data.list[index].progPath;
          arrayAll.push(item);
          for (var idx in sidebarContent) {
            if (sidebarContent[idx].children) {
              for (var idx2 in sidebarContent[idx].children) {
                if (sidebarContent[idx].children[idx2].route == item) {
                  array.push(item);
                }
              }
            } else {
              if (sidebarContent[idx].route == item) {
                array.push(item);
              }
            }
          }
        }

        const difference1 = array.filter((x) => !arrayAll.includes(x));
        const difference2 = arrayAll.filter((x) => !array.includes(x));
        const differences = difference1.concat(difference2);

        for (var idx4 in differences) {
          this.basicPermissionList = response.data.list.filter(
            (itema) => itema.progPath !== differences[idx4]
          );
        }

        this.setbasicPermissionList();

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    setbasicPermissionList() {
      this.table.items.splice(0);
      let array = [];
      for (var index in this.basicPermissionList) {
        let data = this.basicPermissionList[index];

        let list = data.progType.split("#");
        let functionList = [];
        for (var idx in list) {
          functionList.push({
            text: list[idx],
            variant: "outline-success",
          });
        }

        let catgory = "";
        let name = "";
        for (var idx1 in sidebarContent) {
          if (sidebarContent[idx1].children) {
            for (var idx2 in sidebarContent[idx1].children) {
              if (sidebarContent[idx1].children[idx2].route == data.progPath) {
                catgory = sidebarContent[idx1].title;
                name = sidebarContent[idx1].children[idx2].title;
              }
            }
          } else {
            if (sidebarContent[idx1].route == data.progPath) {
              catgory = sidebarContent[idx1].title;
              name = sidebarContent[idx1].title;
            }
          }
        }

        array.push({
          groupID: data.progID,
          COL0: catgory,
          COL1: name,
          COL2: [{ text: "全開/全關", variant: "primary" }],
          COL3: [...functionList],
        });
        this.permission.push({
          id: data.progID,
          list: "",
        });
      }

      this.table.items = array;
      if (this.pageType == "edit") {
        this.getPermissionList();
      }
    },

    //edit
    async getPermissionList() {
      try {
        this.isProcessing = true;
        let inputData = {
          groupID: this.groupID,
        };

        const response = await permissionAPI.getDetailList(inputData);

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        this.permissionList = response.data.list[0];

        this.setPermissionList();
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    setPermissionList() {
      this.groupName = this.permissionList.groupNM;
      this.usersNumber = this.permissionList.groupCount;
      this.auth = this.permissionList.auth == 1 ? true : false;

      let array1 = [];
      for (var idx1 in this.basicPermissionList) {
        array1.push(this.basicPermissionList[idx1].progPath);
      }
      let array2 = [];
      for (var idx2 in this.permissionList.gpAuthMapList) {
        let item2 = this.permissionList.gpAuthMapList[idx2].progPath;
        if (array1.indexOf(item2) !== -1) {
          array2.push(this.permissionList.gpAuthMapList[idx2]);
        }
      }
      let array3 = [];
      for (var idx3 in array2) {
        array3.push({
          id: array2[idx3].progID,
          list: array2[idx3].haveAuth == null ? "" : array2[idx3].haveAuth,
        });
      }
      this.permission = array3;
      this.setPerTable();
    },
    setPerTable() {
      for (var idx1 in this.table.items) {
        let data1 = this.table.items[idx1];
        for (var idx2 in data1.COL3) {
          let data2 = data1.COL3[idx2];
          let item = this.permission.find((a) => a.id == data1.groupID);
          if (item.list.search(data2.text) !== -1) {
            data2.variant = "success";
          } else {
            data2.variant = "outline-success";
          }
        }
      }
    },

    saveAction() {
      if (!this.groupName) {
        Toast.fire({
          icon: "warning",
          title: "請輸入群組名稱",
        });
        return;
      }

      if (this.pageType == "create") {
        this.createPermissionGroup();
      } else {
        this.editPermissionGroup();
      }
    },
    async createPermissionGroup() {
      let newGPAuthMapList = [];
      for (var index in this.permission) {
        newGPAuthMapList.push({
          progID: this.permission[index].id,
          haveAuth: this.permission[index].list,
        });
      }
      try {
        this.isProcessing = true;
        const response = await permissionAPI.createPermissionGroup({
          systemID: null,
          groupID: null,
          groupNM: this.groupName,
          status: 1,
          auth: this.auth ? 1 : 0,
          newGPAuthMapList: newGPAuthMapList,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        Toast.fire({
          icon: "success",
          title: "新增成功",
        });
        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async editPermissionGroup() {
      try {
        this.isProcessing = true;
        let newGPAuthMapList = [];
        for (var index in this.permission) {
          newGPAuthMapList.push({
            progID: this.permission[index].id,
            haveAuth: this.permission[index].list,
          });
        }
        const response = await permissionAPI.editPermissionGroup({
          systemID: null,
          groupID: this.groupID,
          groupNM: this.groupName,
          status: 1,
          auth: this.auth ? 1 : 0,
          newGPAuthMapList: newGPAuthMapList,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        Toast.fire({
          icon: "success",
          title: "修改成功",
        });
        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
  },
  watch: {},
  computed: {
    ...mapState(["backgroundColor"]),
  },

  created() {
    if (this.$route.name == "PermissionManagementDetail") {
      this.pageType = "edit";
    } else {
      this.pageType = "create";
    }
    this.groupID = this.$route.params.id;
    this.getbasicPermissionList();
  },
};
</script>
<style scoped>
</style>