<template>
  <div class="" style="padding-top: 5rem">
    <div
      class="d-flex flex-column"
      v-for="(item, index) in sidebarList"
      :key="index"
      @click="showSidebar()"
    >
      <div
        :class="
          item.route == currentRoute && sidebarType == '18' ? 'choosePage' : ''
        "
        @click="renderURL(item.route, sidebarType)"
        v-b-toggle="'collapse' + index"
        style="text-align: left; padding-left: 2rem; height: 3rem"
        class="d-flex align-items-center justify-content-between"
      >
        <div>
          <i
            class="fa-solid mr-2"
            :class="checkItemFontColor(item.route, item.icon)"
            style="font-size: 1.2rem; width: 1.7rem"
          ></i>
          <i
            :class="checkItemFontColor(item.route)"
            v-show="sidebarType == '18'"
            >{{ item.title }}</i
          >
        </div>
        <div class="mr-2" v-show="item.children && sidebarType == '18'">
          <b-icon
            :class="checkItemFontColor(item.route, item.icon)"
            class="sidebarIconColse"
            icon="chevron-right"
          ></b-icon>
          <b-icon
            :class="checkItemFontColor(item.route, item.icon)"
            class="sidebarIconOpen"
            icon="chevron-down"
          ></b-icon>
        </div>
      </div>

      <b-collapse
        v-if="item.children && sidebarType == '18'"
        :id="'collapse' + index"
        style="width: 100%; text-align: start"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(data, index) in item.children"
            :key="index"
            style="padding-left: 4.5rem"
            @click="renderURL(data.route)"
            :class="checkItemCss(data.route)"
            >{{ data.title }}</b-list-group-item
          >
        </b-list-group>
      </b-collapse>
    </div>

    <!-- sidebar -->
    <div>
      <b-sidebar
        id="sidebar-variant"
        :bg-variant="backgroundColor"
        :text-variant="backgroundColor == 'dark' ? 'light' : 'dark'"
        v-model="isSidebar"
        width="20rem"
        no-header
        shadow
        backdrop
      >
        <div class="px-2 py-2">
          <h4 class="mt-3 ml-4" style="text-align: start">CTMS</h4>
          <div
            class="d-flex flex-column"
            v-for="(item, index) in sidebarList"
            :key="index"
          >
            <div
              :class="item.route == currentRoute ? 'choosePage' : ''"
              @click="renderURL(item.route)"
              v-b-toggle="'collapseC' + index"
              style="text-align: left; padding-left: 2rem; height: 3rem"
              class="d-flex align-items-center justify-content-between"
            >
              <div>
                <i
                  class="fa-solid mr-2"
                  :class="checkItemFontColor(item.route, item.icon, true)"
                  style="font-size: 1.2rem; width: 1.7rem"
                ></i>
                <i :class="checkItemFontColor(item.route, '', true)">{{
                  item.title
                }}</i>
              </div>
              <div class="mr-2" v-show="item.children">
                <b-icon
                  :class="checkItemFontColor(item.route, item.icon, true)"
                  class="sidebarIconColse"
                  icon="chevron-right"
                ></b-icon>
                <b-icon
                  :class="checkItemFontColor(item.route, item.icon, true)"
                  class="sidebarIconOpen"
                  icon="chevron-down"
                ></b-icon>
              </div>
            </div>

            <b-collapse
              :id="'collapseC' + index"
              style="width: 100%; text-align: start"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(data, index) in item.children"
                  :key="index"
                  style="padding-left: 4.5rem"
                  @click="renderURL(data.route)"
                  :class="checkItemCss(data.route)"
                  >{{ data.title }}</b-list-group-item
                >
              </b-list-group>
            </b-collapse>
          </div>
        </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import sidebarContent from "../utils/sidebar.js";
//test

export default {
  data() {
    return {
      sidebarList: null,
      isSidebar: false,
    };
  },
  methods: {
    renderURL(route, sidebarType) {
      if (!route) return;
      if (sidebarType == "5") return;

      let Nowroute = this.$router.history.current.name;
      if (Nowroute == route) return;
      this.$router.push({ name: route });
    },
    showSidebar() {
      if (this.sidebarType == "18") return;
      this.isSidebar = !this.isSidebar;
    },
    checkItemCss(route) {
      if (route == this.currentRoute) {
        return "choosePage";
      } else {
        if (this.backgroundColor == "dark") {
          return "itemDark";
        } else {
          return "itemLight";
        }
      }
    },
    checkItemFontColor(route, icon, sidebar) {
      if (route == this.currentRoute) {
        if (this.sidebarType == "18") {
          return "iDark " + icon;
        } else {
          if (this.backgroundColor == "dark") {
            return "iDark " + icon;
          } else {
            if (sidebar) {
              return "iDark " + icon;
            } else {
              return "iLight " + icon;
            }
          }
        }
      } else {
        if (this.backgroundColor == "dark") {
          return "iDark " + icon;
        } else {
          return "iLight " + icon;
        }
      }
    },

    createView() {
      this.sidebarList = JSON.parse(JSON.stringify(sidebarContent));

      let showList = [];
      for (var index in this.currentUser.isAdmin) {
        let data = this.currentUser.isAdmin[index];
        if (data.haveAuth.search("瀏覽") !== -1) {
          showList.push(data.progPath);
        }
      }
      showList = showList.toString();

      let deleteIndex = [];
      for (var idx1 in this.sidebarList) {
        let data1 = this.sidebarList[idx1];
        if (data1.children) {
          let deleteIdx = [];
          for (var idx2 in data1.children) {
            let data2 = data1.children[idx2];
            let item1 = showList.search(data2.route) !== -1;
            if (!item1) {
              deleteIdx.push(idx2);
            }
          }

          deleteIdx.sort(function (a, b) {
            return b - a;
          });
          for (var idxx in deleteIdx) {
            data1.children.splice(deleteIdx[idxx], 1);
          }
          if (data1.children.length == 0) {
            deleteIndex.push(idx1);
          }
        } else {
          let checkItem = showList.search(data1.route) !== -1;
          if (!checkItem) {
            deleteIndex.push(idx1);
          }
        }
      }
      deleteIndex.sort(function (a, b) {
        return b - a;
      });

      for (var i in deleteIndex) {
        this.sidebarList.splice(deleteIndex[i], 1);
      }
    },
  },
  computed: {
    ...mapState([
      "sidebarType",
      "currentRoute",
      "backgroundColor",
      "currentUser",
      "isAuthenticated",
    ]),
  },
  watch: {
    isAuthenticated() {
      this.createView();
    },
  },
  created() {
    this.createView();
  },
};
</script>
<style scoped>
.itemLight {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  cursor: pointer;
}
.itemDark {
  background-color: #6c757d;
  border-color: #6c757d;
  cursor: pointer;
  color: white;
}
.choosePage {
  background-color: #00205b;
  border-color: #00205b;
  box-shadow: 0 2px 10px #00205b;
  cursor: pointer;
  color: white;
}

.iLight {
  font-size: 20px;
  color: #00205b;
}
.iDark {
  font-size: 20px;
  color: white;
}

.not-collapsed .sidebarIconColse {
  display: none;
}
.collapsed .sidebarIconColse {
  display: flex;
}
.not-collapsed .sidebarIconOpen {
  display: flex;
}
.collapsed .sidebarIconOpen {
  display: none;
}
</style>