import Vue from "vue";
import Vuex from "vuex";

import usersAPI from "./../apis/usersAPI";
import { Toast } from "./../utils/helper";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: {
      name: "",
      account: "",
      corpID: "",
      taxSID: "",
      permissionList: [],
      isAdmin: null,
      permissionInfo: {
        pkID: null,
        empID: null,
        assocID: null,
        corpID: null,
        companyID: null,
        teamID: null,
        groupName: null,
      },
    },
    searchCondition: {
      status: false,
      router: "",
      data: null,
    },
    specialTitle: "",
    token: "",
    isAuthenticated: false,

    sidebarType: "18",
    backgroundColor: localStorage.getItem("backgroundColor") || "light",
    currentRoute: "",
  },
  getters: {},
  mutations: {
    setCurrentUser(state, currentUser) {
      state.currentUser = {
        ...state.currentUser,
        ...currentUser,
      };
      state.token = localStorage.getItem("token");
      state.isAuthenticated = true;
    },
    setSidebarType(state, type) {
      if (type == "100") {
        if (state.sidebarType == "18") {
          state.sidebarType = "5";
        } else {
          state.sidebarType = "18";
        }
      } else {
        state.sidebarType = type;
      }
    },
    setbackgroundColor(state) {
      if (state.backgroundColor == "dark") {
        state.backgroundColor = "light";
        localStorage.setItem("backgroundColor", "light");
      } else {
        state.backgroundColor = "dark";
        localStorage.setItem("backgroundColor", "dark");
      }
    },
    setCurrentRoute(state, currentRoute) {
      state.currentRoute = currentRoute;
    },
    revokeAuthentication(state) {
      state.currentUser = {};
      state.isAuthenticated = false;
      state.token = "";
      localStorage.removeItem("token");
      localStorage.removeItem("permissionID");
    },
    getsearchCondition(state, searchCondition) {
      state.searchCondition = searchCondition;
    },
    getSpecialTitle(state, specialTitle) {
      state.specialTitle = specialTitle;
    },
  },
  actions: {
    async fetchCurrentUser({ commit }) {
      try {
        if (!localStorage.getItem("permissionID")) {
          commit("revokeAuthentication");
          return false;
        }

        const { data } = await usersAPI.getPermission({
          pkid: localStorage.getItem("permissionID"),
        });

        let permissionList = data.list[0].userRolesDataList;
        let isAdmin = data.list[0].userRolesList[0].userFunctionList;
        let permissionInfo = {
          pkID: data.list[0].accPkID,
          empID: data.list[0].empID,
          assocID: data.list[0].userRolesList[0].assocID,
          corpID: data.list[0].userRolesList[0].corpID,
          companyID: data.list[0].userRolesList[0].taxID,
          teamID: data.list[0].userRolesList[0].teamID,
          groupName: data.list[0].userRolesList[0].groupName,
        };

        let userData = {
          name: data.list[0].name,
          account: data.list[0].nationalID,
          corpID: data.list[0].corpID,
          taxSID: data.list[0].taxSID,
          permissionList: permissionList,
          isAdmin: isAdmin,
          permissionInfo: permissionInfo,
        };
        this.commit("setCurrentUser", userData);
        if (!data.success) {
          Toast.fire({
            icon: "warning",
            title: data.errDesc,
          });
          commit("revokeAuthentication");
          return false;
        }
        return true;
      } catch (error) {
        commit("revokeAuthentication");
        return false;
      }
    },
  },
  modules: {},
});
