<template>
  <div class="d-flex justify-content-center">
    <b-card
      style="width: 30rem"
      class="mt-5"
      :style="step == 0 ? 'height: 15rem' : 'height: 18rem'"
    >
      <form v-if="step !== 2" class="w-100" @submit.prevent.stop="handleSubmit">
        <div class="text-center mb-3">
          <h1 class="h3">CTMS</h1>
        </div>

        <div class="form-label-group d-flex align-items-center mb-3">
          <label for="IDnumber">帳號：</label>
          <input
            id="IDnumber"
            name="IDnumber"
            type="text"
            class="form-control"
            placeholder="身分證"
            v-model="account"
            required
            autofocus
            :disabled="step == 1"
          />
        </div>

        <div class="form-label-group d-flex align-items-center mb-3">
          <label for="password">密碼：</label>
          <b-input-group>
            <b-form-input
              id="password"
              name="password"
              placeholder="密碼"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              trim
              :disabled="step == 1"
              autocomplete="current-password"
              required
            />
            <b-input-group-prepend is-text>
              <b-icon
                style="cursor: pointer"
                :icon="showPassword ? 'eye-fill' : 'eye-slash'"
                @click="checkPasswordShow(1)"
              ></b-icon>
            </b-input-group-prepend>
          </b-input-group>

          <!-- <input
            id="password"
            name="password"
            type="password"
            class="form-control"
            placeholder="密碼"
            autocomplete="current-password"
            v-model="password"
            required
            :disabled="step == 1"
          /> -->
        </div>

        <div
          v-if="step == 1"
          class="form-label-group d-flex align-items-center mb-3"
        >
          <label for="password">身份權限：</label>
          <select
            v-model="permission.value"
            name="permission"
            id="permission"
            class="form-control"
          >
            <option value="" selected disabled>--請選擇--</option>
            <option
              v-for="item in permission.options"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>

        <button :disabled="isProcessing" class="btn btn-primary" type="submit">
          {{ step == 0 ? "登入" : "確定" }}
        </button>
      </form>

      <div v-else>
        <div class="text-center mb-3">
          <h1 class="h3">CTMS</h1>
        </div>
        <p class="ml-3" style="color: red">密碼為預設密碼，請修改後登入</p>
        <div class="form-label-group d-flex align-items-center mb-3">
          <label for="IDnumber">新密碼：</label>
          <b-input-group>
            <b-form-input
              id="newPassword"
              name="newPassword"
              placeholder="請輸入新密碼"
              v-model="newPassword"
              :type="showNewPassword ? 'text' : 'password'"
              class="form-control"
              trim
              :disabled="step == 1"
              autofocus
              required
            />
            <b-input-group-prepend is-text>
              <b-icon
                style="cursor: pointer"
                :icon="showNewPassword ? 'eye-fill' : 'eye-slash'"
                @click="checkPasswordShow(2)"
              ></b-icon>
            </b-input-group-prepend>
          </b-input-group>

          <!-- <input
            id="newPassword"
            name="newPassword"
            type="password"
            class="form-control"
            placeholder="請輸入新密碼"
            v-model="newPassword"
            required
            autofocus
            :disabled="step == 1"
          /> -->
        </div>
        <div class="form-label-group d-flex align-items-center mb-3">
          <label for="IDnumber">確認密碼：</label>
          <b-input-group>
            <b-form-input
              id="checknewPassword"
              name="checknewPassword"
              placeholder="請確認密碼"
              v-model="checknewPassword"
              :type="showCheckNewPassword ? 'text' : 'password'"
              class="form-control"
              trim
              :disabled="step == 1"
              autofocus
              required
            />
            <b-input-group-prepend is-text>
              <b-icon
                style="cursor: pointer"
                :icon="showCheckNewPassword ? 'eye-fill' : 'eye-slash'"
                @click="checkPasswordShow(3)"
              ></b-icon>
            </b-input-group-prepend>
          </b-input-group>

          <!-- <input
            id="checknewPassword"
            name="checknewPassword"
            type="password"
            class="form-control"
            placeholder="請確認密碼"
            v-model="checknewPassword"
            required
            autofocus
            :disabled="step == 1"
          /> -->
        </div>

        <button
          @click="editPasswordAPI()"
          :disabled="isProcessing"
          class="btn btn-primary"
          type="submit"
        >
          確定
        </button>
      </div>
    </b-card>
  </div>
</template>

<script>
import authorizationAPI from "./../apis/authorization";
import { Toast } from "./../utils/helper";

export default {
  data() {
    return {
      account: "",
      password: "",
      showPassword: false,
      isProcessing: false,
      newPassword: "",
      checknewPassword: "",
      showNewPassword: false,
      showCheckNewPassword: false,

      permission: {
        value: "",
        options: [
          { id: "1", name: "222" },
          { id: "2", name: "222" },
        ],
      },

      step: 0,
    };
  },
  methods: {
    checkPasswordShow(type) {
      if (type == 1) {
        this.showPassword = !this.showPassword;
      } else if (type == 2) {
        this.showNewPassword = !this.showNewPassword;
      } else {
        this.showCheckNewPassword = !this.showCheckNewPassword;
      }
    },
    handleSubmit() {
      if (this.step == 0) {
        this.loginAPI();
      } else {
        if (!this.permission.value) {
          Toast.fire({
            icon: "warning",
            title: "請選擇身份權限",
          });
          return;
        }

        let permissionID = this.permission.value;

        localStorage.setItem("permissionID", permissionID);
        this.$router.push("/home");
      }
    },
    async loginAPI() {
      try {
        this.isProcessing = true;

        const response = await authorizationAPI.signIn({
          nationalID: this.account,
          password: this.password,
        });
        this.isProcessing = false;

        const { data } = response;

        if (!response.data.success) {
          this.password = "";
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        localStorage.setItem("token", data.jwt);

        if (data.remark == "密碼為預設密碼，需要協助更改") {
          this.step = 2;
          return;
        }
        let permissionList = data.list[0].userRolesDataList;
        if (!permissionList || permissionList.length == 0) {
          Toast.fire({
            icon: "warning",
            title: "無登入權限，請洽管理處",
          });
          return;
        }
        if (permissionList?.length == 1) {
          let permissionID = permissionList[0].pkID;
          localStorage.setItem("permissionID", permissionID);
          this.$router.push("/home");
        } else {
          this.permission.options.splice(0);
          for (var idx in permissionList) {
            let info = "";

            if (permissionList[idx].assocNM) {
              info = permissionList[idx].assocNM;
            }
            if (permissionList[idx].corpNM) {
              info =
                info == ""
                  ? permissionList[idx].corpNM
                  : info + " " + permissionList[idx].corpNM;
            }
            if (permissionList[idx].taxNM) {
              info =
                info == ""
                  ? permissionList[idx].taxNM
                  : info + " " + permissionList[idx].taxNM;
            }
            if (permissionList[idx].teamNM) {
              info =
                info == ""
                  ? permissionList[idx].teamNM
                  : info + " " + permissionList[idx].teamNM;
            }

            if (permissionList[idx].powerful !== 1 && info) {
              info = " (" + info + ")";
            }

            this.permission.options.push({
              id: permissionList[idx].pkID,
              name: permissionList[idx].groupName + info,
            });
          }
          this.step = 1;
        }
      } catch (error) {
        this.password = "";
        this.isProcessing = false;
        localStorage.removeItem("token");

        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async editPasswordAPI() {
      if (!this.newPassword) {
        Toast.fire({
          icon: "warning",
          title: "請輸入新密碼",
        });
        return;
      }
      if (!this.checknewPassword) {
        Toast.fire({
          icon: "warning",
          title: "請確認密碼",
        });
        return;
      }
      if (this.newPassword !== this.checknewPassword) {
        Toast.fire({
          icon: "warning",
          title: "密碼確認有誤，請重新輸入",
        });
        this.checknewPassword = "";
        return;
      }
      try {
        this.isProcessing = true;
        const response = await authorizationAPI.editPassword({
          empID: null,
          nationalID: null,
          oldPassword: "000000",
          password: this.newPassword,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        Toast.fire({
          icon: "success",
          title: "修改完成",
        });

        this.step = 0;
        this.password = "";

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
  },
  created() {
    this.$store.commit("setSidebarType", "0");
  },
};
</script>
<style scoped>
label {
  margin: 0;
  width: 7rem;
}
</style>