<template>
  <div>
    <div class="mainContent">
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row class="mb-3">
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '1'">公司： </label>
              </b-col>
              <b-col class="">
                <b-form-input
                  :id="$route.name + '1'"
                  :name="$route.name + '1'"
                  placeholder="請輸入公司名稱"
                  v-model="company"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '2'">組別： </label>
              </b-col>
              <b-col class="">
                <b-form-input
                  :id="$route.name + '2'"
                  :name="$route.name + '2'"
                  placeholder="請輸入組別名稱"
                  v-model="department"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '3'"
                  >使用者：
                </label>
              </b-col>
              <b-col class="">
                <b-form-input
                  :id="$route.name + '3'"
                  :name="$route.name + '3'"
                  placeholder="請輸入使用者名稱"
                  v-model="user"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">在職狀況： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <div
                  v-for="(item, index) in this.statusRadio.options"
                  :key="index"
                  class="d-flex mr-1 align-items-center"
                >
                  <b-form-radio
                    v-model="statusRadio.value"
                    style="margin-bottom: 0.5rem"
                    :value="item.value"
                    :id="statusRadio.name + item.value"
                  >
                  </b-form-radio>
                  <label class="fontColor" :for="statusRadio.name + item.value">
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
          <!-- button -->
          <b-col class="col-12">
            <b-button
              class="mr-2"
              :disabled="isProcessing"
              @click="searchAction(1)"
              variant="primary"
              >查詢</b-button
            >
            <b-button
              v-show="this.userPremission.search('新增') !== -1"
              :disabled="isProcessing"
              @click="createAction()"
              variant="outline-primary"
              >新增</b-button
            >
          </b-col>
        </b-row>
      </b-card>

      <BaseTable
        v-show="table.items.length !== 0"
        :fields="table.fields"
        :items="table.items"
        :currentPage="table.currentPage"
        :totalItems="table.totalItems"
        :perPage="table.perPage"
        :editAction="editAction"
        :deleteAction="deleteAction"
        :clickPage="clickPage"
        :detailAction="editAction"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "@/utils/helper";
import BaseTable from "@/components/BaseTable";
import usersAPI from "@/apis/usersAPI";
import moment from "moment";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      userPremission: "",
      isProcessing: false,

      company: "",
      department: "",
      user: "",
      statusRadio: {
        name: "statusRadio",
        value: 1,
        options: [
          { text: "在職", value: 1 },
          { text: "離職", value: 0 },
          { text: "留停", value: 2 },
        ],
      },

      searchData: null,
      table: {
        currentPage: 1,
        perPage: 5,
        totalItems: 0,
        fields: [
          {
            key: "COL0",
            label: "",
            type: "default_btn",
          },
          { key: "COL1", label: "使用者名稱" },
          { key: "COL2", label: "到職日" },
          { key: "COL3", label: "公司" },
          { key: "COL4", label: "組別" },
          { key: "COL5", label: "職位" },
          { key: "COL6", label: "在職狀況" },
        ],
        items: [],
      },
    };
  },
  methods: {
    setSearchCondition() {
      this.table.perPage = this.searchCondition.data.rows;
      this.table.currentPage = 1;
      this.company = this.searchCondition.data.taxNM;
      this.department = this.searchCondition.data.teamNM;
      this.user = this.searchCondition.data.accountNM;
      this.statusRadio.value = this.searchCondition.data.status;

      this.searchAction();
    },
    async searchAction(page) {
      this.table.currentPage = page ? page : this.table.currentPage;
      try {
        this.isProcessing = true;

        let inputData = {
          roW_INDEX: (this.table.currentPage - 1) * this.table.perPage,
          rows: this.table.perPage,
          taxNM: this.company,
          teamNM: this.department,
          accountNM: this.user,
          taxID: null,
          teamID: null,
          accountID: null,
          status: this.statusRadio.value,
          theday: null,
        };

        const response = await usersAPI.getUsersList(inputData);

        let data = response.data;

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        this.searchData = data;
        this.setSearchData();
        this.isProcessing = false;

        let searchCondition = {
          status: false,
          router: this.currentRoute,
          data: inputData,
        };
        this.$store.commit("getsearchCondition", searchCondition);
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    setSearchData() {
      this.table.items.splice(0);
      this.table.totalItems = this.searchData.dataTotal;

      let array = [];
      for (var index in this.searchData.list) {
        let data = this.searchData.list[index];
        let btn = "";
        if (data.hireState == "離職") {
          btn = "detail";
        } else {
          if (this.userPremission.search("修改") !== -1) {
            btn = "edit";
          } else {
            btn = "detail";
          }
        }

        array.push({
          COL0: btn,
          COL1: data.empNM,
          COL2: data.hireDate ? moment(data.hireDate).format("YYYY-MM-DD") : "",
          COL3: data.taxNM,
          COL4: data.teamNM,
          COL5: data.positionNM,
          COL6: data.hireState,
        });
      }
      this.table.items = array;
    },
    clickPage(page) {
      this.table.currentPage = page;
      this.searchAction();
    },

    createAction() {
      this.$router.push({ name: "UsersManagementCreate" });
    },
    editAction(field, rowIndex) {
      console.log(field, rowIndex);
      let userData = this.searchData.list[rowIndex];
      let status = "";
      if (userData.hireState == "在職") {
        status = "1";
      } else if (userData.hireState == "離職") {
        status = "2";
      } else if (userData.hireState == "留停") {
        status = "3";
      }
      if (this.userPremission.search("修改") == -1) {
        status = "2";
      }
      let id = userData.pkID + "." + userData.empID + "." + status;

      this.$router.push({
        name: "UsersManagementDetail",
        params: { id: id },
      });
    },
    deleteAction(field, rowIndex) {
      console.log(field, rowIndex);
    },
  },
  watch: {},
  computed: {
    ...mapState([
      "backgroundColor",
      "currentUser",
      "currentRoute",
      "searchCondition",
    ]),
  },
  created() {
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;
    if (this.userPremission.search("總管理處") == -1) {
      let id =
        this.currentUser.permissionInfo.pkID +
        "." +
        this.currentUser.permissionInfo.empID +
        ".0";
      this.$router.push({
        name: "UsersManagementDetail",
        params: { id: id },
      });
    }
    if (
      this.searchCondition.status &&
      this.searchCondition.router == this.currentRoute
    ) {
      this.setSearchCondition();
    }
  },
};
</script>
<style scoped>
</style>