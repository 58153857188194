import { apiHelper } from "./../utils/helper";

export default {
  getProgBasicList(data) {
    return apiHelper.post("/api/Permission/GetProgBasicList", data);
  },
  getMasterList(data) {
    return apiHelper.post("/api/Permission/GetMasterList", data);
  },
  getDetailList(data) {
    return apiHelper.post("/api/Permission/GetDetailList", data);
  },
  createPermissionGroup(data) {
    return apiHelper.post("/api/Permission/PermissionDataInsert", data);
  },
  editPermissionGroup(data) {
    return apiHelper.post("/api/Permission/PermissionDataUpdate", data);
  },
  deletePermissionGroup(data) {
    return apiHelper.post("/api/Permission/PermissionDataDisable", data);
  },
  getPermUserDetailList(data) {
    return apiHelper.post("/api/Permission/PermUserDetailList", data);
  },
};

// https://ctms-api.januarytc.com/api/Permission/PermUserDetailList
