<template>
  <div>
    <div class="mainContent">
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row class="mb-3">
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '1'">公會： </label>
              </b-col>
              <b-col class="">
                <b-form-input
                  :id="$route.name + '1'"
                  :name="$route.name + '1'"
                  placeholder="請輸入公會名稱"
                  v-model="guild"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">集團： </label>
              </b-col>
              <b-col class="">
                <b-form-input
                  :id="$route.name + '2'"
                  :name="$route.name + '2'"
                  placeholder="請輸入集團名稱"
                  v-model="group"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">狀態： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <div
                  v-for="(item, index) in this.statusRadio.options"
                  :key="index"
                  class="d-flex mr-1 align-items-center"
                >
                  <b-form-radio
                    v-model="statusRadio.value"
                    style="margin-bottom: 0.5rem"
                    :value="item.value"
                    :id="statusRadio.name + item.value"
                  >
                  </b-form-radio>
                  <label class="fontColor" :for="statusRadio.name + item.value">
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="col-12">
            <b-button
              :disabled="isProcessing"
              @click="searchAction(1)"
              variant="primary"
              >查詢</b-button
            >
            <b-button
              v-show="this.userPremission.search('新增') !== -1"
              class="ml-2"
              @click="createAction()"
              variant="outline-primary"
              >新增</b-button
            >
          </b-col>
        </b-row>
      </b-card>

      <BaseTable
        v-show="table.items.length !== 0"
        :fields="table.fields"
        :items="table.items"
        :currentPage="table.currentPage"
        :totalItems="table.totalItems"
        :perPage="table.perPage"
        :detailAction="editAction"
        :editAction="editAction"
        :deleteAction="deleteAction"
        :clickPage="clickPage"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "@/utils/helper";
import BaseTable from "@/components/BaseTable";
import usersAPI from "@/apis/usersAPI";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      userPremission: "",
      isProcessing: false,

      guild: "",
      group: "",
      statusRadio: {
        name: "statusRadio",
        value: 1,
        options: [
          { text: "啟用", value: 1 },
          { text: "停用", value: 0 },
        ],
      },

      searchData: null,
      table: {
        currentPage: 1,
        perPage: 5,
        totalItems: 0,
        fields: [
          {
            key: "COL0",
            label: "",
            type: "default_btn",
          },
          { key: "COL6", label: "排序" },
          { key: "COL3", label: "公會名稱" },
          { key: "COL5", label: "集團" },
        ],
        items: [],
      },
    };
  },
  methods: {
    setSearchCondition() {
      this.table.perPage = this.searchCondition.data.rows;
      this.table.currentPage = 1;
      this.guild = this.searchCondition.data.assocNM;
      this.group = this.searchCondition.data.corpNM;
      this.statusRadio.value = this.searchCondition.data.status;

      this.searchAction();
    },
    async searchAction(page) {
      this.table.currentPage = page ? page : this.table.currentPage;
      try {
        this.isProcessing = true;

        let inputData = {
          roW_INDEX: (this.table.currentPage - 1) * this.table.perPage,
          rows: this.table.perPage,
          assocNM: this.guild,
          corpNM: this.group,
          assocID: null,
          corpID: null,
          status: this.statusRadio.value,
          theday: null,
        };

        const response = await usersAPI.getGuildList(inputData);

        let data = response.data;

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        this.searchData = data;
        this.setSearchData();
        this.isProcessing = false;

        let searchCondition = {
          status: false,
          router: this.currentRoute,
          data: inputData,
        };
        this.$store.commit("getsearchCondition", searchCondition);
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    setSearchData() {
      this.table.items.splice(0);
      this.table.totalItems = this.searchData.dataTotal;

      let array = [];
      for (var index in this.searchData.list) {
        let data = this.searchData.list[index];
        let btn = "";
        if (this.userPremission.search("修改") !== -1) {
          if (this.userPremission.search("停用") !== -1) {
            if (this.statusRadio.value == 1) {
              btn = "edit,delete";
            } else {
              btn = "edit";
            }
          } else {
            if (this.statusRadio.value == 1) {
              btn = "edit";
            } else {
              btn = "edit";
            }
          }
        } else {
          if (this.userPremission.search("停用") !== -1) {
            if (this.statusRadio.value == 1) {
              btn = "delete";
            } else {
              btn = "detail";
            }
          } else {
            btn = "detail";
          }
        }

        let number = (this.table.currentPage - 1) * this.table.perPage;

        array.push({
          COL0: btn,
          COL1: number + (Number(index) + 1),
          COL2: data.assocID,
          COL3: data.assocNM,
          COL4: data.empNM,
          COL5: data.corpNM,
          COL6: data.sort,
        });
      }
      this.table.items = array;
    },
    clickPage(page) {
      this.table.currentPage = page;
      this.searchAction();
    },

    createAction() {
      this.$router.push({ name: "GuildsManagementCreate" });
    },
    editAction(field, rowIndex) {
      console.log(field, rowIndex);
      let id = this.searchData.list[rowIndex].assocID;
      if (this.statusRadio.value == 1) {
        this.$router.push({
          name: "GuildsManagementDetail",
          params: { id: id },
        });
      } else {
        this.$router.push({
          name: "GuildsManagementDeleteEdit",
          params: { id: id },
        });
      }
    },
    deleteAction(field, rowIndex) {
      console.log(field, rowIndex);
      let id = this.searchData.list[rowIndex].assocID;
      this.$router.push({ name: "GuildsManagementDelete", params: { id: id } });
    },
  },
  watch: {},
  computed: {
    ...mapState([
      "backgroundColor",
      "currentUser",
      "currentRoute",
      "searchCondition",
    ]),
  },
  created() {
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;

    if (
      this.searchCondition.status &&
      this.searchCondition.router == this.currentRoute
    ) {
      this.setSearchCondition();
    }
  },
};
</script>
<style scoped>
</style>

