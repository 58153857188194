import { apiHelper } from "./../utils/helper";

export default {
  signIn(data) {
    return apiHelper.post("/api/Account/Login", data);
  },
  editPassword(data) {
    return apiHelper.post("/api/Account/PasswordChange", data);
  },
};

// https://ctms-api.januarytc.com/api/Account/PasswordChange
