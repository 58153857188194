<template>
  <div>
    <div class="mainContent">
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row class="mb-3">
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '1'">公會： </label>
              </b-col>
              <b-col class="">
                <b-form-input
                  :id="$route.name + '1'"
                  :name="$route.name + '1'"
                  placeholder="請輸入公會名稱"
                  v-model="guild"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '2'">集團： </label>
              </b-col>
              <b-col class="">
                <b-form-input
                  :id="$route.name + '2'"
                  :name="$route.name + '2'"
                  placeholder="請輸入集團名稱"
                  v-model="group"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + '3'">公司： </label>
              </b-col>
              <b-col class="">
                <b-form-input
                  :id="$route.name + '3'"
                  :name="$route.name + '3'"
                  placeholder="請輸入公司名稱"
                  v-model="company"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">狀態： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <div
                  v-for="(item, index) in this.statusRadio.options"
                  :key="index"
                  class="d-flex mr-1 align-items-center"
                >
                  <b-form-radio
                    v-model="statusRadio.value"
                    style="margin-bottom: 0.5rem"
                    :value="item.value"
                    :id="statusRadio.name + item.value"
                  >
                  </b-form-radio>
                  <label class="fontColor" :for="statusRadio.name + item.value">
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="col-12">
            <b-button
              :disabled="isProcessing"
              @click="searchAction(1)"
              variant="primary"
              >查詢</b-button
            >
            <b-button
              v-show="this.userPremission.search('新增') !== -1"
              :disabled="isProcessing"
              class="ml-2"
              @click="createAction()"
              variant="outline-primary"
              >新增</b-button
            >
          </b-col>
        </b-row>
      </b-card>

      <BaseTable
        v-show="table.items.length !== 0"
        :fields="table.fields"
        :items="table.items"
        :currentPage="table.currentPage"
        :totalItems="table.totalItems"
        :perPage="table.perPage"
        :editAction="editAction"
        :detailAction="editAction"
        :deleteAction="deleteAction"
        :text_btnFunction="companyManagement"
        :clickPage="clickPage"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "@/utils/helper";
import BaseTable from "@/components/BaseTable";
import usersAPI from "@/apis/usersAPI";
import moment from "moment";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      userPremission: "",
      isProcessing: false,
      guild: "",
      group: "",
      company: "",
      statusRadio: {
        name: "statusRadio",
        value: 1,
        options: [
          { text: "啟用", value: 1 },
          { text: "停用", value: 0 },
        ],
      },

      searchData: null,
      table: {
        currentPage: 1,
        perPage: 5,
        totalItems: 0,
        fields: [
          {
            key: "COL0",
            label: "",
            type: "default_btn",
          },
          { key: "COL7", label: "排序" },
          { key: "COL4", label: "公會" },
          { key: "COL3", label: "集團名稱" },
          { key: "COL6", label: "公司", type: "style_Cstm" },
          { key: "COL8", label: "啟用日期" },
          { key: "COL9", label: "停用日期" },
          {
            key: "COL1",
            label: "公司管理",
            type: "text_btn",
            css: "center",
          },
        ],
        items: [],
      },

      GroupAPI: null,
    };
  },
  methods: {
    setSearchCondition() {
      this.table.perPage = this.searchCondition.data.rows;
      this.table.currentPage = 1;
      this.guild = this.searchCondition.data.assocNM;
      this.group = this.searchCondition.data.corpNM;
      this.company = this.searchCondition.data.companyNM;
      this.statusRadio.value = this.searchCondition.data.status;
      this.searchAction();
    },
    async searchAction(page) {
      this.table.currentPage = page ? page : this.table.currentPage;
      try {
        this.isProcessing = true;

        let inputData = {
          roW_INDEX: (this.table.currentPage - 1) * this.table.perPage,
          rows: this.table.perPage,
          assocNM: this.guild,
          corpNM: this.group,
          companyNM: this.company,
          assocID: null,
          corpID: null,
          companyID: null,
          status: this.statusRadio.value,
          theday: null,
        };

        const response = await usersAPI.getGroupList(inputData);

        let data = response.data;

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        this.searchData = data;
        this.setSearchData();
        this.isProcessing = false;

        let searchCondition = {
          status: false,
          router: this.currentRoute,
          data: inputData,
        };
        this.$store.commit("getsearchCondition", searchCondition);
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    setSearchData() {
      this.table.items.splice(0);
      this.table.totalItems = this.searchData.dataTotal;

      let array = [];
      for (var index in this.searchData.list) {
        let data = this.searchData.list[index];
        let btn = "";
        if (this.userPremission.search("修改") !== -1) {
          if (this.userPremission.search("停用") !== -1) {
            if (this.statusRadio.value == 1) {
              btn = "edit,delete";
            } else {
              btn = "edit";
            }
          } else {
            if (this.statusRadio.value == 1) {
              btn = "edit";
            } else {
              btn = "edit";
            }
          }
        } else {
          if (this.userPremission.search("停用") !== -1) {
            if (this.statusRadio.value == 1) {
              btn = "delete";
            } else {
              btn = "detail";
            }
          } else {
            btn = "detail";
          }
        }

        let combtn = [{ text: "公司管理", variant: "outline-primary" }];
        let number = (this.table.currentPage - 1) * this.table.perPage;

        let companyNM = {
          text: data.companyNM,
          style: "min-width: 20rem",
        };

        array.push({
          COL0: btn,
          COL1: combtn,
          COL2: number + (Number(index) + 1),
          COL3: data.corpNM,
          COL4: data.assocNM,
          COL6: companyNM,
          COL7: data.sort,
          COL8: moment(data.startTime).format("YYYY-MM-DD"),
          COL9: moment(data.endTime).format("YYYY-MM-DD"),
        });

        if (this.statusRadio.value == 1) {
          this.table.fields = [
            {
              key: "COL0",
              label: "",
              type: "default_btn",
            },
            { key: "COL7", label: "排序" },
            { key: "COL4", label: "公會" },
            { key: "COL3", label: "集團名稱" },
            { key: "COL6", label: "公司", type: "style_Cstm" },
            { key: "COL8", label: "啟用日期" },
            { key: "COL9", label: "停用日期" },
            {
              key: "COL1",
              label: "公司管理",
              type: "text_btn",
              css: "center",
            },
          ];
        } else {
          this.table.fields = [
            {
              key: "COL0",
              label: "",
              type: "default_btn",
            },
            { key: "COL7", label: "排序" },
            { key: "COL4", label: "公會" },
            { key: "COL3", label: "集團名稱" },
            { key: "COL6", label: "公司", type: "style_Cstm" },
            { key: "COL8", label: "啟用日期" },
            { key: "COL9", label: "停用日期" },
          ];
        }
      }
      this.table.items = array;
    },
    clickPage(page) {
      this.table.currentPage = page;
      this.searchAction();
    },

    createAction() {
      this.$router.push({ name: "GroupManagementCreate" });
    },
    editAction(field, rowIndex) {
      console.log(field, rowIndex);
      let id = this.searchData.list[rowIndex].corpID;
      if (this.statusRadio.value == 1) {
        this.$router.push({
          name: "GroupManagementDetail",
          params: { id: id },
        });
      } else {
        this.$router.push({
          name: "GroupManagementDeleteEdit",
          params: { id: id },
        });
      }
    },
    deleteAction(field, rowIndex) {
      console.log(field, rowIndex);
      let id = this.searchData.list[rowIndex].corpID;
      this.$router.push({ name: "GroupManagementDelete", params: { id: id } });
    },
    companyManagement(field, rowIndex, itemIndex) {
      console.log(field, rowIndex, itemIndex);
      let id = this.searchData.list[rowIndex].corpID;
      this.$router.push({ name: "GroupManagementCompany", params: { id: id } });
    },
  },
  watch: {},
  computed: {
    ...mapState([
      "backgroundColor",
      "currentUser",
      "currentRoute",
      "searchCondition",
    ]),
  },
  created() {
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;

    if (
      this.searchCondition.status &&
      this.searchCondition.router == this.currentRoute
    ) {
      this.setSearchCondition();
    }
  },
};
</script>
<style scoped>
</style>