<template>
  <div>
    <div class="d-flex">
      <p class="detailTitle">{{ showTitle }}</p>
    </div>
    <b-card
      class="mb-3"
      :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
    >
      <b-row class="mb-3">
        <b-col lg="6">
          <b-row>
            <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
              <label class="fontCSS" :for="$route.name + 'companyManagment1'"
                >加盟品牌：
              </label>
            </b-col>

            <b-col class="">
              <b-form-select
                :id="$route.name + 'companyManagment1'"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                v-model="branchSelect.value"
                :options="branchSelect.options"
                :disabled="
                  viewSaveType.type == 'read' ||
                  viewSaveType.type == 'deleteEdit' ||
                  viewSaveType.type == 'delete'
                "
              ></b-form-select>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row>
            <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
              <label class="fontCSS">狀態： </label>
            </b-col>
            <b-col class="d-flex align-items-center mt-2">
              <div
                v-for="(item, index) in this.companyStatusRadio.options"
                :key="index"
                class="d-flex mr-1"
              >
                <b-form-radio
                  v-model="companyStatusRadio.value"
                  :value="item.value"
                  :id="companyStatusRadio.name + item.value"
                  :disabled="viewSaveType.type !== 'delete'"
                  @change="changeStatus()"
                >
                </b-form-radio>
                <label
                  class="fontColor"
                  :for="companyStatusRadio.name + item.value"
                >
                  {{ item.text }}</label
                >
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row>
            <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
              <label class="fontCSS" :for="$route.name + 'companyManagment2'"
                >顯示名稱：
              </label>
            </b-col>
            <b-col>
              <b-form-input
                :id="$route.name + 'companyManagment2'"
                :name="$route.name + 'companyManagment2'"
                placeholder="請輸入顯示名稱"
                v-model="showName"
                type="text"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                :disabled="
                  viewSaveType.type == 'read' || viewSaveType.type == 'delete'
                "
                trim
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col lg="6">
          <b-row>
            <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
              <label class="fontCSS" :for="$route.name + 'companyManagment3'"
                >公司名稱：
              </label>
            </b-col>
            <b-col>
              <b-form-input
                :id="$route.name + 'companyManagment3'"
                :name="$route.name + 'companyManagment3'"
                placeholder="請輸入公司名稱"
                v-model="companyName"
                type="text"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                :disabled="
                  viewSaveType.type == 'read' || viewSaveType.type == 'delete'
                "
                trim
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row>
            <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
              <label class="fontCSS" :for="$route.name + 'companyManagment4'"
                >公司統編：
              </label>
            </b-col>
            <b-col>
              <b-form-input
                :id="$route.name + 'companyManagment4'"
                :name="$route.name + 'companyManagment4'"
                placeholder="請輸入公司統編"
                v-model="companyNumber"
                type="text"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                :disabled="viewSaveType.type !== 'create'"
                @change="checkVerify(2)"
                trim
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row>
            <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
              <label class="fontCSS" :for="$route.name + 'companyManagment5'"
                >公司地址：
              </label>
            </b-col>
            <b-col>
              <b-form-input
                :id="$route.name + 'companyManagment5'"
                :name="$route.name + 'companyManagment5'"
                placeholder="請輸入公司地址"
                v-model="companyAddress"
                type="text"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                :disabled="
                  viewSaveType.type == 'read' ||
                  viewSaveType.type == 'deleteEdit' ||
                  viewSaveType.type == 'delete'
                "
                trim
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row>
            <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
              <label class="fontCSS" :for="$route.name + 'companyManagment6'"
                >公司電話：
              </label>
            </b-col>
            <b-col>
              <b-form-input
                :id="$route.name + 'companyManagment6'"
                :name="$route.name + 'companyManagment6'"
                placeholder="請輸入公司電話"
                v-model="companyTel"
                type="text"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                :disabled="
                  viewSaveType.type == 'read' ||
                  viewSaveType.type == 'deleteEdit' ||
                  viewSaveType.type == 'delete'
                "
                trim
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row>
            <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
              <label class="fontCSS">啟用日期： </label>
            </b-col>
            <b-col>
              <DateField
                :isabled="
                  viewSaveType.type == 'read' ||
                  viewSaveType.type == 'deleteEdit' ||
                  viewSaveType.type == 'delete'
                    ? false
                    : true
                "
                :value.sync="startDate"
              ></DateField>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row>
            <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
              <label class="fontCSS">停用日期： </label>
            </b-col>
            <b-col>
              <DateField
                :isabled="
                  viewSaveType.type == 'read' ||
                  viewSaveType.type == 'deleteEdit' ||
                  viewSaveType.type == 'edit'
                    ? false
                    : true
                "
                :value.sync="endDate"
              ></DateField>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row>
            <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
              <label class="fontCSS" :for="$route.name + 'companyManagment7'"
                >排序：
              </label>
            </b-col>
            <b-col>
              <b-form-input
                :id="$route.name + 'companyManagment7'"
                :name="$route.name + 'companyManagment7'"
                placeholder="請輸入排序，數字愈小愈前面"
                v-model="sort"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                :disabled="
                  viewSaveType.type == 'read' ||
                  viewSaveType.type == 'deleteEdit' ||
                  viewSaveType.type == 'delete'
                "
                trim
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row>
            <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
              <label class="fontCSS">公司Logo： </label>
            </b-col>
            <b-col xl="8" lg="7" md="9" class="col-9 d-flex align-items-center">
              <div style="padding: 0" class="col-12">
                <b-form-file
                  ref="file-input"
                  style="text-align: start"
                  browse-text="上傳"
                  placeholder="請選擇檔案"
                  accept=".jpg, .png, .bmp"
                  id="groupLogo"
                  v-model="logoFile"
                  :disabled="
                    viewSaveType.type == 'read' ||
                    viewSaveType.type == 'deleteEdit' ||
                    viewSaveType.type == 'delete'
                  "
                  @change="onFileChange"
                ></b-form-file>
              </div>
            </b-col>
            <b-col
              v-if="showFile"
              xl="4"
              lg="5"
              md="3"
              class="col-3 titleCSS"
            ></b-col>
            <b-col
              style="max-width: 15rem; max-height: 15rem"
              v-if="showFile"
              class="d-flex align-items-center"
            >
              <img style="height: 100%; width: 100%" :src="showFile" alt="" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <div class="col-12 mt-2 mb-2">
      <b-button
        v-show="viewSaveType.type !== 'read'"
        :disabled="isProcessing"
        @click="saveAction()"
        class="mr-2"
        variant="primary"
        >確定</b-button
      >
      <b-button
        :disabled="isProcessing"
        @click="backAction()"
        variant="outline-secondary"
        >返回</b-button
      >
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "@/utils/helper";
import DateField from "@/components/DateField";
import usersAPI from "@/apis/usersAPI";
import sharedModuleAPI from "@/apis/sharedModuleAPI";

import moment from "moment";

export default {
  components: {
    DateField,
  },
  props: {
    returnPage: {
      type: Function,
      default: null,
    },
    viewSaveType: {
      type: Object,
    },
  },
  data() {
    return {
      userPremission: "",
      showTitle: "",
      isProcessing: false,

      branchSelect: {
        value: null,
        options: [{ value: null, text: "請選擇加盟品牌" }],
      },
      pkID: null,
      sid: null,

      logoFile: null,
      showFile: null,
      saveFile: null,

      showName: "",
      companyName: "",
      companyID: "",
      companyNumber: "",
      companyAddress: "",
      companyTel: "",
      startDate: "",
      endDate: "",
      originEndDate: "",
      companyStatusRadio: {
        name: "companyStatusRadio",
        value: 1,
        options: [
          { text: "啟用", value: 1 },
          { text: "停用", value: 0 },
        ],
      },
      sort: null,
    };
  },
  methods: {
    async checkVerify(type) {
      let ID = "";
      if (type == 2) {
        ID = this.companyNumber;
      }
      if (!ID) return;
      try {
        this.isProcessing = true;

        const response = await sharedModuleAPI.checkVerify({
          code: type,
          id: ID,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          if (type == 2) {
            this.companyNumber = "";
          }

          return;
        }

        if (type == 1) {
          this.getUserDataByAccount();
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    backAction() {
      this.$store.commit("getSpecialTitle", "");
      this.returnPage();
    },
    changeStatus() {
      if (
        this.viewSaveType.type == "delete" &&
        this.companyStatusRadio.value == 0
      ) {
        this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      }
    },
    saveAction() {
      if (
        this.viewSaveType.type == "create" &&
        this.endDate <= moment().format("YYYY-MM-DD")
      ) {
        Toast.fire({
          icon: "warning",
          title: "停用日期不得小於當日。",
        });
        return;
      }
      if (!this.companyName) {
        Toast.fire({
          icon: "warning",
          title: "請輸入公司名稱",
        });
        return;
      }
      if (!this.showName) {
        Toast.fire({
          icon: "warning",
          title: "請輸入顯示公司名稱",
        });
        return;
      }
      if (!this.companyNumber) {
        Toast.fire({
          icon: "warning",
          title: "請輸入公司統編",
        });
        return;
      }
      if (this.logoFile) {
        this.getPathList();
      } else {
        if (this.viewSaveType.type == "create") {
          this.createGroupCompanyData();
        } else if (this.viewSaveType.type == "delete") {
          this.deleteAction();
        } else {
          this.editGroupCompanyData();
        }
      }
    },
    deleteAction() {
      if (this.currentUser.taxSID == this.sid) {
        Toast.fire({
          icon: "warning",
          title: "預停用公司為所屬公司，無法停用",
        });
        return;
      }

      let msg = "";
      let today = moment().format("YYYY-MM-DD");
      let type = 0;
      if (this.endDate < today) {
        msg = "公司將立即停用，請再次確定。";
        type = 1;
      } else {
        if (this.originEndDate !== this.endDate) {
          msg = "公司將於" + this.endDate + " 23:59 停用，請再次確定。";
        } else {
          this.backAction();
          return;
        }
      }

      this.$bvModal
        .msgBoxConfirm(msg, {
          bodyClass: "checkModalCss",
          headerClass: " border-bottom-0",
          footerClass: "p-2 border-top-0",
          okVariant: "primary",
          okTitle: "確定",
          cancelTitle: "取消",
          cancelVariant: "outline-secondary",
          size: "sm",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteGroupCompanyData(type);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getGroupCompanyData() {
      try {
        this.isProcessing = true;
        const response = await usersAPI.getGroupCompanyData({
          taxSID: this.viewSaveType.id,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        let data = response.data.list[0];

        this.pkID = data.pkID;
        this.sid = data.taxSID;
        this.branchSelect.value = data.brandID;
        this.showName = data.shortName;
        this.companyName = data.companyNM;
        this.companyNumber = data.companyID;
        this.companyID = data.companyID;
        this.companyAddress = data.address;
        this.companyTel = data.tel;
        this.companyStatusRadio.value = Number(data.status);
        this.startDate = moment(data.startTime).format("YYYY-MM-DD");
        this.endDate = moment(data.endTime).format("YYYY-MM-DD");
        this.originEndDate = moment(data.endTime).format("YYYY-MM-DD");
        this.sort = data.sort;
        this.showFile =
          data.filePathList.length > 0 ? data.filePathList[0].linkPath : null;

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.showFile = URL.createObjectURL(file);
      } else {
        this.showFile = null;
      }
    },
    async getPathList() {
      this.saveFile = null;
      try {
        this.isProcessing = true;

        let form = new FormData();
        form.append("ProgNM", "groupManagment");
        form.append("Files", this.logoFile);

        const response = await sharedModuleAPI.getPathList(form);

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;
          return;
        }
        this.saveFile = response.data.list[0].filePathList;

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        if (this.viewSaveType.type == "create") {
          this.createGroupCompanyData();
        } else {
          this.editGroupCompanyData();
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async createGroupCompanyData() {
      try {
        this.isProcessing = true;
        const response = await usersAPI.createGroupCompanyData({
          corpID: this.viewSaveType.groupID,
          empID: null,
          brandID: this.branchSelect.value,
          typeID: null,
          shortName: this.showName,
          taxNM: this.companyName,
          taxID: this.companyNumber,
          address: this.companyAddress,
          tel: this.companyTel,
          Sort: this.sort ? Number(this.sort) : null,
          StartTime: this.startDate == "" ? null : this.startDate,
          EndTime: this.endDate == "" ? null : this.endDate,
          Status: this.companyStatusRadio.value,
          filePathList: this.saveFile,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        Toast.fire({
          icon: "success",
          title: "新增成功",
        });
        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async editGroupCompanyData() {
      try {
        this.isProcessing = true;
        const response = await usersAPI.editGroupCompanyData({
          corpID: this.viewSaveType.groupID,
          empID: null,
          brandID: this.branchSelect.value,
          typeID: null,
          shortName: this.showName,
          taxNM: this.companyName,
          taxID: this.companyNumber,
          address: this.companyAddress,
          tel: this.companyTel,
          Sort: this.sort ? Number(this.sort) : null,
          StartTime: this.startDate == "" ? null : this.startDate,
          EndTime: this.endDate == "" ? null : this.endDate,
          Status: this.companyStatusRadio.value,
          filePathList: this.saveFile,
          pkID: this.pkID,
          taxSID: this.sid,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        Toast.fire({
          icon: "success",
          title: "修改成功",
        });
        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async deleteGroupCompanyData(type) {
      try {
        this.isProcessing = true;
        const response = await usersAPI.deleteGroupCompanyData({
          pkID: this.pkID,
          taxSID: this.sid,
          corpID: this.viewSaveType.groupID,
          taxID: this.companyNumber,
          EndTime: this.endDate == "" ? null : this.endDate,
          Status: this.companyStatusRadio.value,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        if (type == 1) {
          Toast.fire({
            icon: "success",
            title: this.showName + "已停用",
          });
        } else {
          Toast.fire({
            icon: "success",
            title: this.showName + "已預作停用",
          });
        }

        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async getBrandList() {
      try {
        const response = await sharedModuleAPI.getBrandList({
          brandID: null,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        let list = response.data.list;
        this.branchSelect.options = [{ value: null, text: "請選擇加盟品牌" }];
        for (var index in list) {
          let item = list[index];
          this.branchSelect.options.push({
            value: item.id,
            text: item.nm,
          });
        }
      } catch (error) {
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
  },
  watch: {},
  computed: {
    ...mapState(["backgroundColor", "currentUser", "currentRoute"]),
  },

  created() {
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;
    this.getBrandList();
    if (this.viewSaveType.type == "create") {
      this.showTitle = "新增公司";
      this.startDate = moment().format("YYYY-MM-DD");
      this.endDate = "9999-12-31";
      this.sort = 1;
      this.$store.commit("getSpecialTitle", "新增公司");
    } else if (
      this.viewSaveType.type == "edit" ||
      this.viewSaveType.type == "read"
    ) {
      this.showTitle = "公司維護";
      this.getGroupCompanyData();
      this.$store.commit("getSpecialTitle", "公司維護");
    } else if (this.viewSaveType.type == "deleteEdit") {
      this.showTitle = "停用公司維護";
      this.getGroupCompanyData();
      this.$store.commit("getSpecialTitle", "停用公司維護");
    } else {
      this.showTitle = "停用公司";
      this.getGroupCompanyData();
      this.$store.commit("getSpecialTitle", "停用公司");
    }
  },
};
</script>
<style scoped>
</style>