<template>
  <div class="ml-4">
    <b-card
      class="mb-3"
      :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
    >
      <div style="width: 60%; margin: 0 auto">
        <div class="d-flex">
          <div class="d-flex justify-content-end titleWidth">
            <label
              style="white-space: nowrap"
              class="fontCSS"
              :for="$route.name + '1'"
              >委託編號：
            </label>
          </div>
          <div style="width: 100%" class="d-flex">
            <b-form-input
              :id="$route.name + '1'"
              :name="$route.name + '1'"
              placeholder="請輸入委託編號"
              v-model="referenceNumber"
              type="text"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
            <div
              v-for="(item, index) in referenceNumberType.options"
              :key="index"
              class="d-flex mr-1 ml-3 mt-1"
            >
              <b-form-checkbox
                v-model="referenceNumberType.value"
                :value="item.value"
                :id="referenceNumberType.name + item.value"
              >
              </b-form-checkbox>
              <label
                style="white-space: nowrap"
                class="fontColor"
                :for="referenceNumberType.name + item.value"
              >
                {{ item.text }}</label
              >
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="d-flex justify-content-end titleWidth">
            <label style="white-space: nowrap" class="fontCSS"
              >委託日期：
            </label>
          </div>

          <div style="width: 100%" class="d-flex align-items-center">
            <DateField :value.sync="referenceDate.stratDate"></DateField>
            <label class="dashCSS">-</label>
            <DateField :value.sync="referenceDate.endDate"></DateField>
          </div>
        </div>
        <div class="d-flex">
          <div class="d-flex justify-content-end titleWidth">
            <label style="white-space: nowrap" class="fontCSS"
              >物件型態：
            </label>
          </div>
          <div style="width: 100%" class="d-flex mb-2">
            <div
              v-for="(item, index) in objectType.options"
              :key="index"
              class="d-flex mt-1 mr-3 align-items-center"
            >
              <b-form-checkbox
                v-model="objectType.value"
                :value="item.value"
                :id="objectType.name + item.value"
              >
              </b-form-checkbox>
              <label
                style="white-space: nowrap; margin-bottom: 0"
                class="fontColor"
                :for="objectType.name + item.value"
              >
                {{ item.text }}</label
              >
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="d-flex justify-content-end titleWidth">
            <label style="white-space: nowrap" class="fontCSS"
              >資料類別：
            </label>
          </div>
          <div style="width: 100%" class="d-flex mb-2">
            <div
              v-for="(item, index) in formalType.options"
              :key="index"
              class="d-flex mt-1 mr-3 align-items-center"
            >
              <b-form-checkbox
                v-model="formalType.value"
                :value="item.value"
                :id="formalType.name + item.value"
              >
              </b-form-checkbox>
              <label
                style="white-space: nowrap; margin-bottom: 0"
                class="fontColor"
                :for="formalType.name + item.value"
              >
                {{ item.text }}</label
              >
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="d-flex justify-content-end titleWidth">
            <label style="white-space: nowrap" class="fontCSS">門牌： </label>
          </div>
          <div style="width: 100%" class="d-flex">
            <b-form-input
              :name="$route.name + '6'"
              placeholder="郵遞區號"
              v-model="address.postal"
              type="text"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              class="postalCss"
              trim
            />
            <b-form-select
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              v-model="address.cityID"
              :options="address.cityOptions"
            ></b-form-select>
            <b-form-select
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              v-model="address.townID"
              :options="address.townOptions"
            ></b-form-select>
            <b-form-input
              :name="$route.name + '6'"
              placeholder=""
              v-model="address.road"
              type="text"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
            <label style="white-space: nowrap" class="fontCSS">(路、街) </label>
            <b-form-input
              :name="$route.name + '6'"
              placeholder=""
              v-model="address.input1"
              type="text"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              class="addressInputCss"
              trim
            />
            <label style="white-space: nowrap" class="fontCSS">(段) </label>
          </div>
        </div>
        <div class="d-flex">
          <div class="d-flex justify-content-end titleWidth">
            <label style="white-space: nowrap" class="fontCSS"> </label>
          </div>
          <div style="width: 100%" class="d-flex">
            <b-form-input
              :name="$route.name + '6'"
              placeholder=""
              v-model="address.input2"
              type="text"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              class="addressInputCss"
              trim
            />
            <label style="white-space: nowrap" class="fontCSS">(鄰) </label>
            <b-form-input
              :name="$route.name + '6'"
              placeholder=""
              v-model="address.input3"
              type="text"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              class="addressInputCss"
              trim
            />
            <label style="white-space: nowrap" class="fontCSS">(里) </label>
            <b-form-input
              :name="$route.name + '6'"
              placeholder=""
              v-model="address.input4"
              type="text"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              class="addressInputCss"
              trim
            />
            <label style="white-space: nowrap" class="fontCSS">(巷) </label>
            <b-form-input
              :name="$route.name + '6'"
              placeholder=""
              v-model="address.input5"
              type="text"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              class="addressInputCss"
              trim
            />
            <label style="white-space: nowrap" class="fontCSS">(弄) </label>
            <b-form-input
              :name="$route.name + '6'"
              placeholder=""
              v-model="address.input6"
              type="text"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              class="addressInputCss"
              trim
            />
            <label style="white-space: nowrap" class="fontCSS">(號) </label>
            <b-form-input
              :name="$route.name + '6'"
              placeholder=""
              v-model="address.input7"
              type="text"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              class="addressInputCss"
              trim
            />
            <label style="white-space: nowrap" class="fontCSS">(樓) </label>
          </div>
        </div>

        <b-col class="col-12" style="margin-top: 0.5rem">
          <b-button @click="createAction()" class="mr-2" variant="primary"
            >新增委託</b-button
          >
          <b-button
            @click="backAction()"
            class="mr-2"
            variant="outline-secondary"
            >取消</b-button
          >
        </b-col>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import { Toast } from "./../../utils/helper";
// import BaseTable from "./../../components/BaseTable";
// import SearchSelect from "./../../components/SearchSelect";
import DateField from "./../../components/DateField";

export default {
  components: {
    // BaseTable,
    // SearchSelect,
    DateField,
  },
  data() {
    return {
      referenceNumber: "",
      referenceNumberType: {
        name: "referenceNumberType",
        value: [],
        options: [
          { text: "專任", value: "0" },
          { text: "一般", value: "1" },
        ],
      },
      referenceDate: {
        stratDate: "",
        endDate: "",
      },
      objectType: {
        name: "objectType",
        value: [],
        options: [
          { text: "成屋", value: "0" },
          { text: "預售", value: "1" },
          { text: "土地", value: "2" },
        ],
      },
      formalType: {
        name: "formalType",
        value: [],
        options: [
          { text: "售", value: "0" },
          { text: "租", value: "1" },
          { text: "售租", value: "2" },
        ],
      },
      address: {
        postal: "",
        cityID: null,
        cityOptions: [{ value: null, text: "請選擇縣市" }],
        townID: null,
        townOptions: [{ value: null, text: "請選擇區域" }],
        road: "",
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
      },
    };
  },
  methods: {
    backAction() {
      this.$router.push({ name: "ObjectManagement" });
    },
    createAction() {
      //  call api
      let id = "test2024";
      this.$router.push({
        name: "ObjectManagementDetail",
        params: { id: id },
      });
    },
  },
  watch: {},
  computed: {
    ...mapState(["backgroundColor", "currentUser"]),
  },

  created() {
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;
  },
};
</script>
<style scoped>
.titleWidth {
  width: 10rem;
}
.addressInputCss {
  width: 4rem;
}
.postalCss {
  width: 6rem;
}
</style>