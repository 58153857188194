import { apiHelper } from "./../utils/helper";

export default {
  getPathList(data) {
    return apiHelper.post("/api/Infra/GetPathList", data);
    //集團 groupManagment
    //公司 companyManagment
    //人員 userManagment
  },
  getBrandList(data) {
    return apiHelper.post("/api/Infra/GetBrandDropdownlist", data);
  },
  getCityList(data) {
    return apiHelper.post("/api/Infra/GetCityDropdownlist", data);
  },
  getTownList(data) {
    return apiHelper.post("/api/Infra/GetTownDropdownlist", data);
  },
  getGroupList(data) {
    return apiHelper.post("/api/Infra/GetCorporateDropdownlist", data);
  },
  getCompanyList(data) {
    return apiHelper.post("/api/Infra/GetCompanyDropdownlist", data);
  },
  getDepartmentList(data) {
    return apiHelper.post("/api/Infra/GetTeamDropdownlist", data);
  },
  getPermissionList(data) {
    return apiHelper.post("/api/Infra/GetGroupDropdownlist", data);
  },
  checkVerify(data) {
    return apiHelper.post("/api/Infra/GetVerify", data);
  },
};

// https://ctms-api.januarytc.com/api/Infra/GetVerify