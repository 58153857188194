<template>
  <div>
    <div class="mainContent">
      <div class="d-flex">
        <p v-show="viewSaveType.type == 'create'" class="detailTitle">
          新增集團
        </p>
        <p
          v-show="viewSaveType.type == 'edit' || viewSaveType.type == 'read'"
          class="detailTitle"
        >
          集團資料維護
        </p>
        <p v-show="viewSaveType.type == 'delete'" class="detailTitle">
          停用集團
        </p>
        <p v-show="viewSaveType.type == 'deleteEdit'" class="detailTitle">
          停用集團資料維護
        </p>
        <p v-show="viewSaveType.type == 'company'" class="detailTitle">
          集團資料 - 公司管理
        </p>
      </div>
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row class="mb-3">
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'detail1'"
                  >集團名稱：
                </label>
              </b-col>
              <b-col>
                <b-form-input
                  :id="$route.name + 'detail1'"
                  :name="$route.name + 'detail1'"
                  placeholder="請輸入集團名稱"
                  v-model="groupName"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  :disabled="
                    viewSaveType.type == 'read' ||
                    viewSaveType.type == 'company' ||
                    viewSaveType.type == 'delete'
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">狀態： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <div
                  v-for="(item, index) in this.statusRadio.options"
                  :key="index"
                  class="d-flex mr-1 align-items-center"
                >
                  <b-form-radio
                    style="margin-bottom: 0.5rem"
                    v-model="statusRadio.value"
                    :value="item.value"
                    :id="statusRadio.name + item.value"
                    :disabled="viewSaveType.type !== 'delete'"
                    @change="changeStatus()"
                  >
                  </b-form-radio>
                  <label class="fontColor" :for="statusRadio.name + item.value">
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">啟用日期： </label>
              </b-col>
              <b-col>
                <DateField
                  :isabled="
                    viewSaveType.type == 'read' ||
                    viewSaveType.type == 'deleteEdit' ||
                    viewSaveType.type == 'company' ||
                    viewSaveType.type == 'delete'
                      ? false
                      : true
                  "
                  :value.sync="startDate"
                ></DateField>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">停用日期： </label>
              </b-col>
              <b-col>
                <DateField
                  :isabled="
                    viewSaveType.type == 'read' ||
                    viewSaveType.type == 'deleteEdit' ||
                    viewSaveType.type == 'company' ||
                    viewSaveType.type == 'edit'
                      ? false
                      : true
                  "
                  :value.sync="endDate"
                ></DateField>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">集團Logo： </label>
              </b-col>
              <b-col
                xl="8"
                lg="7"
                md="9"
                class="col-9 d-flex align-items-center"
              >
                <div style="padding: 0" class="col-12">
                  <b-form-file
                    ref="file-input"
                    style="text-align: start"
                    browse-text="上傳"
                    placeholder="請選擇檔案"
                    id="file-default"
                    v-model="logoFile"
                    accept=".jpg, .png, .bmp"
                    :disabled="
                      viewSaveType.type == 'read' ||
                      viewSaveType.type == 'deleteEdit' ||
                      viewSaveType.type == 'company' ||
                      viewSaveType.type == 'delete'
                    "
                    @change="onFileChange"
                  ></b-form-file>
                </div>
              </b-col>
              <b-col
                v-if="showFile"
                xl="4"
                lg="5"
                md="3"
                class="col-3 titleCSS"
              ></b-col>
              <b-col
                style="max-width: 15rem; max-height: 15rem"
                v-if="showFile"
                class="d-flex align-items-center"
              >
                <img style="height: 100%; width: 100%" :src="showFile" alt="" />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'detail2'"
                  >排序：
                </label>
              </b-col>
              <b-col>
                <b-form-input
                  :id="$route.name + 'detail2'"
                  :name="$route.name + 'detail2'"
                  placeholder="請輸入排序，數字愈小愈前面"
                  v-model="sort"
                  type="number"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  :disabled="
                    viewSaveType.type == 'read' ||
                    viewSaveType.type == 'deleteEdit' ||
                    viewSaveType.type == 'company' ||
                    viewSaveType.type == 'delete'
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="12" v-if="this.userPremission.search('新增') !== -1">
            <b-button
              v-show="this.viewSaveType.type == 'company' && showViews == ''"
              :disabled="isProcessing"
              @click="createCompanyAction()"
              class="mt-1"
              variant="primary"
              >新增公司</b-button
            >
          </b-col>
        </b-row>
      </b-card>

      <template v-if="showViews == ''">
        <b-card v-show="this.viewSaveType.type == 'company'">
          <b-col class="d-flex align-items-center">
            <div
              v-for="(item, index) in this.companyStatus.options"
              :key="index"
              class="d-flex mr-1 align-items-center"
            >
              <b-form-radio
                style="margin-bottom: 0.5rem"
                v-model="companyStatus.value"
                :value="item.value"
                :id="companyStatus.name + item.value"
                @change="getGrupCompanyList()"
              >
              </b-form-radio>
              <label class="fontColor" :for="companyStatus.name + item.value">
                {{ item.text }}</label
              >
            </div>
          </b-col>
          <BaseTable
            v-show="table.items.length !== 0"
            :fields="table.fields"
            :items="table.items"
            :currentPage="table.currentPage"
            :totalItems="table.totalItems"
            :perPage="table.perPage"
            :editAction="editCompanyAction"
            :detailAction="editCompanyAction"
            :deleteAction="deleteCompanyAction"
            :clickPage="clickPage"
          />
        </b-card>
      </template>
      <template v-else>
        <CompanyDetailPage
          :viewSaveType="companyViewSaveType"
          :returnPage="getGroupData"
        />
      </template>

      <div v-show="showViews == ''" class="col-12 mt-2 mb-2">
        <b-button
          v-if="
            this.viewSaveType.type !== 'company' && viewSaveType.type !== 'read'
          "
          :disabled="isProcessing"
          @click="saveAction()"
          class="mr-2"
          variant="primary"
          >確定</b-button
        >
        <b-button @click="backAction()" variant="outline-secondary"
          >返回</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "@/utils/helper";
import moment from "moment";
import BaseTable from "@/components/BaseTable";
import DateField from "@/components/DateField";
import usersAPI from "@/apis/usersAPI";
import sharedModuleAPI from "@/apis/sharedModuleAPI";
import CompanyDetailPage from "@/views/UserModule/GroupManagement/CompanyDetailPage.vue";

export default {
  components: {
    BaseTable,
    DateField,
    CompanyDetailPage,
  },
  data() {
    return {
      userPremission: "",
      viewSaveType: {
        id: null,
        type: "",
      },
      isProcessing: false,

      showViews: "",
      companyViewSaveType: {
        id: null,
        type: "",
        groupID: null,
      },
      companyStatus: {
        name: "companyStatus",
        value: 1,
        options: [
          { text: "啟用", value: 1 },
          { text: "停用", value: 0 },
        ],
      },

      groupID: "",
      groupName: "",
      startDate: "",
      endDate: "",
      originEndDate: "",
      statusRadio: {
        name: "statusRadio",
        value: 1,
        options: [
          { text: "啟用", value: 1 },
          { text: "停用", value: 0 },
        ],
      },
      logoFile: null,
      showFile: null,
      saveFile: null,
      sort: null,

      searchData: null,
      table: {
        currentPage: 1,
        perPage: 5,
        totalItems: 0,
        fields: [
          {
            key: "COL0",
            label: "",
            type: "default_btn",
          },
          { key: "COL9", label: "排序" },
          { key: "COL2", label: "公司名稱" },
          { key: "COL3", label: "顯示名稱" },
          { key: "COL4", label: "加盟品牌" },
          { key: "COL5", label: "公司電話" },
          { key: "COL6", label: "組別", type: "style_Cstm" },
          { key: "COL7", label: "啟用時間" },
          { key: "COL8", label: "停用時間" },
        ],
        items: [],
      },
    };
  },
  methods: {
    checkViewSaveType() {
      this.viewSaveType.id = this.$route.params.id;
      this.companyViewSaveType.groupID = this.$route.params.id;
      if (this.$route.name == "GroupManagementDetail") {
        if (this.userPremission.search("修改") !== -1) {
          this.viewSaveType.type = "edit";
        } else {
          this.viewSaveType.type = "read";
        }
        this.getGroupData();
      } else if (this.$route.name == "GroupManagementCreate") {
        this.viewSaveType.type = "create";
        this.startDate = moment().format("YYYY-MM-DD");
        this.endDate = "9999-12-31";
        this.sort = 1;
      } else if (this.$route.name == "GroupManagementDeleteEdit") {
        if (this.userPremission.search("修改") !== -1) {
          this.viewSaveType.type = "deleteEdit";
        } else {
          this.viewSaveType.type = "read";
        }
        this.getGroupData();
      } else if (this.$route.name == "GroupManagementDelete") {
        this.viewSaveType.type = "delete";
        this.getGroupData();
      } else {
        this.viewSaveType.type = "company";
        this.getGroupData();
      }
    },

    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.showFile = URL.createObjectURL(file);
      } else {
        this.showFile = null;
      }
    },

    createCompanyAction() {
      this.companyViewSaveType.id = "0";
      this.companyViewSaveType.type = "create";
      this.showViews = "CompanyDetailPage";
    },
    editCompanyAction(field, rowIndex) {
      console.log(field, rowIndex);
      this.companyViewSaveType.id =
        this.searchData.list[rowIndex].taxSID.toString();

      if (this.userPremission.search("修改") !== -1) {
        if (this.companyStatus.value == 1) {
          this.companyViewSaveType.type = "edit";
        } else {
          this.companyViewSaveType.type = "deleteEdit";
        }
      } else {
        this.companyViewSaveType.type = "read";
      }

      this.showViews = "CompanyDetailPage";
    },
    deleteCompanyAction(field, rowIndex) {
      console.log(field, rowIndex);
      this.companyViewSaveType.id =
        this.searchData.list[rowIndex].taxSID.toString();
      this.companyViewSaveType.type = "delete";
      this.showViews = "CompanyDetailPage";
    },

    changeStatus() {
      if (this.viewSaveType.type == "delete" && this.statusRadio.value == 0) {
        this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      }
    },
    saveAction() {
      if (
        this.viewSaveType.type == "create" &&
        this.endDate <= moment().format("YYYY-MM-DD")
      ) {
        Toast.fire({
          icon: "warning",
          title: "停用日期不得小於當日。",
        });
        return;
      }
      if (!this.groupName) {
        Toast.fire({
          icon: "warning",
          title: "請輸入集團名稱",
        });
        return;
      }
      if (this.logoFile) {
        this.getPathList();
      } else {
        if (this.viewSaveType.type == "create") {
          this.createGroupData();
        } else if (this.viewSaveType.type == "delete") {
          this.deleteAction();
        } else {
          this.editGroupData();
        }
      }
    },
    deleteAction() {
      if (this.currentUser.corpID == this.groupID) {
        Toast.fire({
          icon: "warning",
          title: "預停用集團為所屬集團，無法停用",
        });
        return;
      }

      let msg = "";
      let today = moment().format("YYYY-MM-DD");
      let type = 0;

      if (this.endDate < today) {
        msg = "集團將立即停用，請再次確定。";
        type = 1;
      } else {
        if (this.originEndDate !== this.endDate) {
          msg = "集團將於" + this.endDate + " 23:59 停用，請再次確定。";
        } else {
          this.backAction();
          return;
        }
      }

      this.$bvModal
        .msgBoxConfirm(msg, {
          bodyClass: "checkModalCss",
          headerClass: " border-bottom-0",
          footerClass: "p-2 border-top-0",
          okVariant: "primary",
          okTitle: "確定",
          cancelTitle: "取消",
          cancelVariant: "outline-secondary",
          size: "sm",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteGroupData(type);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    backAction() {
      let searchCondition = this.searchCondition;
      searchCondition.status = true;
      this.$store.commit("getsearchCondition", searchCondition);

      this.$router.push({
        name: "GroupManagement",
      });
    },

    clickPage(page) {
      this.table.currentPage = page;
      this.getGrupCompanyList();
    },
    async getGroupData() {
      this.showViews = "";
      try {
        this.isProcessing = true;
        const response = await usersAPI.getGroupData({
          corpID: this.viewSaveType.id,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        let data = response.data.list[0];

        this.groupID = data.corpID;
        this.groupName = data.corpNM;
        this.statusRadio.value = Number(data.status);
        this.startDate = moment(data.startTime).format("YYYY-MM-DD");
        this.endDate = moment(data.endTime).format("YYYY-MM-DD");
        this.originEndDate = moment(data.endTime).format("YYYY-MM-DD");

        this.showFile =
          data.filePathList.length > 0 ? data.filePathList[0].linkPath : null;
        this.sort = data.sort;

        if (this.viewSaveType.type == "company") {
          this.getGrupCompanyList();
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async getGrupCompanyList() {
      try {
        this.isProcessing = true;

        const response = await usersAPI.getGrupCompanyList({
          roW_INDEX: (this.table.currentPage - 1) * this.table.perPage,
          rows: this.table.perPage,
          corpID: this.groupID,
          status: this.companyStatus.value,
          theday: null,
        });

        let data = response.data;

        if (!data.success) {
          Toast.fire({
            icon: "warning",
            title: data.errDesc,
          });
          this.isProcessing = false;

          if (data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        this.searchData = data;
        this.setTableData();
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    setTableData() {
      this.table.items.splice(0);
      this.table.totalItems = this.searchData.dataTotal;
      let array = [];
      for (var index in this.searchData.list) {
        let data = this.searchData.list[index];

        let btn = "";
        if (this.userPremission.search("修改") !== -1) {
          if (this.userPremission.search("停用") !== -1) {
            if (this.companyStatus.value !== 0) {
              btn = "edit,delete";
            } else {
              btn = "edit";
            }
          } else {
            if (this.companyStatus.value !== 0) {
              btn = "edit";
            } else {
              btn = "edit";
            }
          }
        } else {
          if (this.userPremission.search("停用") !== -1) {
            if (this.companyStatus.value !== 0) {
              btn = "delete";
            } else {
              btn = "detail";
            }
          } else {
            btn = "detail";
          }
        }

        let number = (this.table.currentPage - 1) * this.table.perPage;
        let departmentNM = {
          text: data.teamNM,
          style: "min-width: 16rem",
        };
        array.push({
          COL0: btn,
          COL1: number + (Number(index) + 1),
          COL2: data.taxNM,
          COL3: data.shortName,
          COL4: data.brandNM,
          COL5: data.tel,
          COL6: departmentNM,
          COL7: moment(data.startTime).format("YYYY-MM-DD"),
          COL8: moment(data.endTime).format("YYYY-MM-DD"),
          COL9: data.sort,
        });
      }
      this.table.items = array;
    },
    async getPathList() {
      this.saveFile = null;
      try {
        this.isProcessing = true;

        let form = new FormData();
        form.append("ProgNM", "groupManagment");
        form.append("Files", this.logoFile);

        const response = await sharedModuleAPI.getPathList(form);

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        this.saveFile = response.data.list[0].filePathList;

        if (this.saveFile.length == 0) {
          Toast.fire({
            icon: "warning",
            title: "資訊錯誤，請通知管理處",
          });
          this.isProcessing = false;
          return;
        }

        if (this.viewSaveType.type == "create") {
          this.createGroupData();
        } else {
          this.editGroupData();
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async createGroupData() {
      try {
        this.isProcessing = true;
        const response = await usersAPI.createGroupData({
          corpID: null,
          corpNM: this.groupName,
          Sort: this.sort ? Number(this.sort) : null,
          StartTime: this.startDate == "" ? null : this.startDate,
          EndTime: this.endDate == "" ? null : this.endDate,
          Status: this.statusRadio.value,
          filePathList: this.saveFile,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        Toast.fire({
          icon: "success",
          title: "新增成功",
        });
        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async editGroupData() {
      try {
        this.isProcessing = true;
        const response = await usersAPI.editGroupData({
          corpID: this.groupID,
          corpNM: this.groupName,
          Sort: Number(this.sort),
          startTime: this.startDate == "" ? null : this.startDate,
          endTime: this.endDate == "" ? null : this.endDate,
          Status: this.statusRadio.value,
          filePathList: this.saveFile,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        Toast.fire({
          icon: "success",
          title: "修改成功",
        });
        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async deleteGroupData(type) {
      try {
        this.isProcessing = true;
        let disableList = [];
        disableList.push({
          corpID: this.groupID,
          endTime: this.endDate == "" ? null : this.endDate,
          status: this.statusRadio.value,
        });

        const response = await usersAPI.deleteGroupData({
          disableList: disableList,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        if (type == 1) {
          Toast.fire({
            icon: "success",
            title: this.groupName + "已停用",
          });
        } else {
          Toast.fire({
            icon: "success",
            title: this.groupName + "已預作停用",
          });
        }

        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
  },
  watch: {},
  computed: {
    ...mapState([
      "backgroundColor",
      "currentUser",
      "currentRoute",
      "searchCondition",
    ]),
  },

  created() {
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;
    this.checkViewSaveType();
  },
};
</script>
<style scoped>
</style>