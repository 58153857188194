var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mainContent"},[_c('b-card',{staticClass:"mb-3",class:_vm.backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'},[_c('form',{staticClass:"w-100",on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.checkAction.apply(null, arguments)}}},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{staticClass:"col-3 titleCSS",attrs:{"xl":"2","lg":"3","md":"3"}},[_c('label',{staticClass:"fontCSS",attrs:{"for":_vm.$route.name + '1'}},[_vm._v("帳號： ")])]),_c('b-col',{staticClass:"col-8",attrs:{"xl":"9","lg":"8","md":"8"}},[_c('b-form-input',{class:_vm.backgroundColor == 'dark' ? 'text-light bg-secondary' : '',attrs:{"id":_vm.$route.name + '1',"name":_vm.$route.name + '1',"placeholder":"請輸入帳號","type":"text","disabled":"","required":"","trim":""},model:{value:(_vm.account),callback:function ($$v) {_vm.account=$$v},expression:"account"}})],1)],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{staticClass:"col-3 titleCSS",attrs:{"xl":"2","lg":"3","md":"3"}},[_c('label',{staticClass:"fontCSS",attrs:{"for":_vm.$route.name + '2'}},[_vm._v("舊密碼： ")])]),_c('b-col',{staticClass:"col-8",attrs:{"xl":"9","lg":"8","md":"8"}},[_c('b-input-group',[_c('b-form-input',{class:_vm.backgroundColor == 'dark'
                        ? 'text-light bg-secondary'
                        : '',attrs:{"id":_vm.$route.name + '2',"name":_vm.$route.name + '2',"placeholder":"請輸入舊密碼","type":_vm.showOldPassword ? 'text' : 'password',"trim":"","required":""},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":_vm.showOldPassword ? 'eye-fill' : 'eye-slash'},on:{"click":function($event){return _vm.checkPasswordShow(1)}}})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{staticClass:"col-3 titleCSS",attrs:{"xl":"2","lg":"3","md":"3"}},[_c('label',{staticClass:"fontCSS",attrs:{"for":_vm.$route.name + '3'}},[_vm._v("新密碼： ")])]),_c('b-col',{staticClass:"col-8",attrs:{"xl":"9","lg":"8","md":"8"}},[_c('b-input-group',[_c('b-form-input',{class:_vm.backgroundColor == 'dark'
                        ? 'text-light bg-secondary'
                        : '',attrs:{"id":_vm.$route.name + '3',"name":_vm.$route.name + '3',"placeholder":"請輸入新密碼","type":_vm.showNewPassword ? 'text' : 'password',"trim":"","required":""},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":_vm.showNewPassword ? 'eye-fill' : 'eye-slash'},on:{"click":function($event){return _vm.checkPasswordShow(2)}}})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{staticClass:"col-3 titleCSS",attrs:{"xl":"2","lg":"3","md":"3"}},[_c('label',{staticClass:"fontCSS",attrs:{"for":_vm.$route.name + '4'}},[_vm._v("確認密碼： ")])]),_c('b-col',{staticClass:"col-8",attrs:{"xl":"9","lg":"8","md":"8"}},[_c('b-input-group',[_c('b-form-input',{class:_vm.backgroundColor == 'dark'
                        ? 'text-light bg-secondary'
                        : '',attrs:{"id":_vm.$route.name + '4',"name":_vm.$route.name + '4',"placeholder":"請重複輸入新密碼","type":_vm.showCheckPassword ? 'text' : 'password',"trim":"","required":""},model:{value:(_vm.checkPassword),callback:function ($$v) {_vm.checkPassword=$$v},expression:"checkPassword"}}),_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":_vm.showCheckPassword ? 'eye-fill' : 'eye-slash'},on:{"click":function($event){return _vm.checkPasswordShow(3)}}})],1)],1)],1)],1)],1),_c('b-col',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.isProcessing,"type":"submit"}},[_vm._v(" 確定 ")])])],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }