<template>
  <div>
    <div class="mainContent">
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <form class="w-100" @submit.prevent.stop="checkAction">
          <b-row class="mb-3">
            <b-col lg="12">
              <b-row>
                <b-col xl="2" lg="3" md="3" class="col-3 titleCSS">
                  <label class="fontCSS" :for="$route.name + '1'"
                    >帳號：
                  </label>
                </b-col>
                <b-col xl="9" lg="8" md="8" class="col-8">
                  <b-form-input
                    :id="$route.name + '1'"
                    :name="$route.name + '1'"
                    placeholder="請輸入帳號"
                    v-model="account"
                    type="text"
                    :class="
                      backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                    "
                    disabled
                    required
                    trim
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="12">
              <b-row>
                <b-col xl="2" lg="3" md="3" class="col-3 titleCSS">
                  <label class="fontCSS" :for="$route.name + '2'"
                    >舊密碼：
                  </label>
                </b-col>
                <b-col xl="9" lg="8" md="8" class="col-8">
                  <b-input-group>
                    <b-form-input
                      :id="$route.name + '2'"
                      :name="$route.name + '2'"
                      placeholder="請輸入舊密碼"
                      v-model="oldPassword"
                      :type="showOldPassword ? 'text' : 'password'"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      trim
                      required
                    />
                    <b-input-group-prepend is-text>
                      <b-icon
                        style="cursor: pointer"
                        :icon="showOldPassword ? 'eye-fill' : 'eye-slash'"
                        @click="checkPasswordShow(1)"
                      ></b-icon>
                    </b-input-group-prepend>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="12">
              <b-row>
                <b-col xl="2" lg="3" md="3" class="col-3 titleCSS">
                  <label class="fontCSS" :for="$route.name + '3'"
                    >新密碼：
                  </label>
                </b-col>
                <b-col xl="9" lg="8" md="8" class="col-8">
                  <b-input-group>
                    <b-form-input
                      :id="$route.name + '3'"
                      :name="$route.name + '3'"
                      placeholder="請輸入新密碼"
                      v-model="newPassword"
                      :type="showNewPassword ? 'text' : 'password'"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      trim
                      required
                    />
                    <b-input-group-prepend is-text>
                      <b-icon
                        style="cursor: pointer"
                        :icon="showNewPassword ? 'eye-fill' : 'eye-slash'"
                        @click="checkPasswordShow(2)"
                      ></b-icon>
                    </b-input-group-prepend>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="12">
              <b-row>
                <b-col xl="2" lg="3" md="3" class="col-3 titleCSS">
                  <label class="fontCSS" :for="$route.name + '4'"
                    >確認密碼：
                  </label>
                </b-col>
                <b-col xl="9" lg="8" md="8" class="col-8">
                  <b-input-group>
                    <b-form-input
                      :id="$route.name + '4'"
                      :name="$route.name + '4'"
                      placeholder="請重複輸入新密碼"
                      v-model="checkPassword"
                      :type="showCheckPassword ? 'text' : 'password'"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      trim
                      required
                    />
                    <b-input-group-prepend is-text>
                      <b-icon
                        style="cursor: pointer"
                        :icon="showCheckPassword ? 'eye-fill' : 'eye-slash'"
                        @click="checkPasswordShow(3)"
                      ></b-icon>
                    </b-input-group-prepend>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="col-12">
              <button
                :disabled="isProcessing"
                class="btn btn-primary"
                type="submit"
              >
                確定
              </button>
            </b-col>
          </b-row>
        </form>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "@/utils/helper";
import authorization from "@/apis/authorization";

export default {
  components: {},
  data() {
    return {
      isProcessing: false,

      account: "test123",
      oldPassword: "",
      newPassword: "",
      checkPassword: "",

      showOldPassword: false,
      showNewPassword: false,
      showCheckPassword: false,
    };
  },
  methods: {
    checkPasswordShow(type) {
      if (type == 1) {
        this.showOldPassword = !this.showOldPassword;
      } else if (type == 2) {
        this.showNewPassword = !this.showNewPassword;
      } else {
        this.showCheckPassword = !this.showCheckPassword;
      }
    },
    checkAction() {
      if (this.newPassword !== this.checkPassword) {
        Toast.fire({
          icon: "warning",
          title: "確認密碼有誤，請重新輸入",
        });
        this.checkPassword = "";
        return;
      }
      this.editPasswordAPI();
    },
    async editPasswordAPI() {
      try {
        this.isProcessing = true;
        const response = await authorization.editPassword({
          empID: null,
          nationalID: null,
          oldPassword: this.oldPassword,
          password: this.newPassword,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        Toast.fire({
          icon: "success",
          title: "修改完成",
        });
        this.$store.commit("revokeAuthentication");
        this.$router.push("/signin");
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
  },
  watch: {},
  computed: {
    ...mapState(["backgroundColor", "currentUser", "currentRoute"]),
  },
  created() {
    this.account = this.currentUser.account;
  },
};
</script>
<style scoped>
</style>