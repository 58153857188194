import { apiHelper } from "./../utils/helper";

export default {
  //個人權限
  getPermission(data) {
    return apiHelper.post("/api/Account/GetUserPermissionDetailList", data);
  },
  //公會
  getGuildList(data) {
    return apiHelper.post("/api/Employee/Assoc/GetMasterList", data);
  },
  getGuildData(data) {
    return apiHelper.post("/api/Employee/Assoc/GetDetailList", data);
  },
  createGuildData(data) {
    return apiHelper.post("/api/Employee/Assoc/GuildsDataInsert", data);
  },
  editGuildData(data) {
    return apiHelper.post("/api/Employee/Assoc/GuildsDataUpdate", data);
  },
  deleteGuildData(data) {
    return apiHelper.post("/api/Employee/Assoc/GuildsDataDisable", data);
  },
  //集團
  getGroupList(data) {
    return apiHelper.post("/api/Employee/Corp/GetMasterList", data);
  },
  getGroupData(data) {
    return apiHelper.post("/api/Employee/Corp/GetDetailList", data);
  },
  createGroupData(data) {
    return apiHelper.post("/api/Employee/Corp/GroupDataInsert", data);
  },
  editGroupData(data) {
    return apiHelper.post("/api/Employee/Corp/GroupDataUpdate", data);
  },
  deleteGroupData(data) {
    return apiHelper.post("/api/Employee/Corp/GroupDataDisable", data);
  },
  getGrupCompanyList(data) {
    return apiHelper.post("/api/Employee/Corp/GetCorpComMasterList", data);
  },
  getGroupCompanyData(data) {
    return apiHelper.post("/api/Employee/Corp/GetCorpComDetailList", data);
  },
  createGroupCompanyData(data) {
    return apiHelper.post("/api/Employee/Corp/CorpCompanyDataInsert", data);
  },
  editGroupCompanyData(data) {
    return apiHelper.post("/api/Employee/Corp/CorpCompanyDataUpdate", data);
  },
  deleteGroupCompanyData(data) {
    return apiHelper.post("/api/Employee/Corp/CorpCompanyDataDisable", data);
  },

  //公司
  getCompanyList(data) {
    return apiHelper.post("/api/Employee/Company/GetMasterList", data);
  },
  getCompanyData(data) {
    return apiHelper.post("/api/Employee/Company/GetDetailList", data);
  },
  getCompanyGroupData(data) {
    return apiHelper.post("/api/Employee/Company/GetComCorpDetailList", data);
  },
  getCompanyDepartmentList(data) {
    return apiHelper.post("/api/Employee/Company/GetComTeamMasterList", data);
  },
  getCompanyDepartmentData(data) {
    return apiHelper.post("/api/Employee/Company/GetComTeamDetailList", data);
  },
  getDepartmentLeaderList(data) {
    return apiHelper.post("/api/Employee/Company/GetComTeamLeaderList", data);
  },
  createCompanyDepartmentData(data) {
    return apiHelper.post("/api/Employee/Company/CompanyTeamDataInsert", data);
  },
  editCompanyDepartmentData(data) {
    return apiHelper.post("/api/Employee/Company/CompanyTeamDataUpdate", data);
  },
  deleteCompanyDepartmentData(data) {
    return apiHelper.post("/api/Employee/Company/CompanyTeamDataDisable", data);
  },
  restartCompanyDepartmentData(data) {
    return apiHelper.post("/api/Employee/Company/CompanyTeamDataEnable", data);
  },
  /////
  getCompanyDepartmentUserList(data) {
    return apiHelper.post("/api/Employee/Company/GetComTeamAccountMasterList", data);
  },
  getCompanyUserList(data) {
    return apiHelper.post("/api/Employee/Company/GetComAccountMasterList", data);
  },
  /////
  getUserData(data) {
    return apiHelper.post("/api/Employee/Account/GetDetailList", data);
  },
  getUserDataByAccount(data) {
    return apiHelper.post("/api/Employee/Company/GetPersonalExistList", data);
  },
  createCompanyUserData(data) {
    return apiHelper.post("/api/Employee/Account/CompanyAccountDataInsert", data);
  },
  editCompanyUserData(data) {
    return apiHelper.post("/api/Employee/Account/CompanyAccountDataUpdate", data);
  },

  //使用者管理
  getUsersList(data) {
    return apiHelper.post("/api/Employee/Account/GetMasterList", data);
  },
  createUserData(data) {
    return apiHelper.post("/api/Employee/Account/AccountDataInsert", data);
  },
  editUserData(data) {
    return apiHelper.post("/api/Employee/Account/AccountDataUpdate", data);
  },
};

//https://ctms-api.januarytc.com/api/Employee/Account/AccountDataUpdate
