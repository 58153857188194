<template>
  <div style="width: 98%">
    <b-card
      class="mb-3"
      :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
    >
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >車位資料：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingSpot.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingSpot.value"
            :value="item.value"
            :id="parkingSpot.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingSpot.name + item.value">
            {{ item.text }}</label
          >
        </div>
        <div class="d-flex justify-content-end ml-3 mr-1">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '7'"
            >車位面積
          </label>
        </div>
        <b-form-input
          :id="$route.name + '7'"
          :name="$route.name + '7'"
          placeholder="請輸入車位面積"
          v-model="parkingSpot.area"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex justify-content-end ml-1">
          <label style="white-space: nowrap" class="fontCSS">平方公尺 </label>
        </div>

        <div class="d-flex justify-content-end ml-5">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '2'"
            >車位來源：
          </label>
        </div>
        <div
          v-for="(item, index) in this.parkingSource.options"
          :key="index"
          class="d-flex mr-3"
        >
          <b-form-radio
            class="mt-1"
            v-model="parkingSource.value"
            :value="item.value"
            :id="parkingSource.name + item.value"
          >
          </b-form-radio>
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="parkingSource.name + item.value"
          >
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :for="$route.name + '8'"
            >車位管理費：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '8'"
          :name="$route.name + '8'"
          placeholder="請輸入管理費"
          v-model="parkingFee.input"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex mr-3 ml-2">
          <b-form-checkbox
            class="mt-1"
            v-model="parkingFee.checkBox"
            id="parkingFeeCheckBox"
          >
          </b-form-checkbox>
          <label
            style="white-space: nowrap"
            class="fontCSS"
            for="parkingFeeCheckBox"
          >
            含在管理費</label
          >
        </div>
        <div
          v-for="(item, index) in parkingFee.type.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingFee.type.value"
            :value="item.value"
            :id="parkingFee.type.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingFee.type.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :for="$route.name + '9'"
            >車位樓層：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '9'"
          :name="$route.name + '9'"
          placeholder="請輸入樓層"
          v-model="parkingFloor.floor"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end ml-3">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '10'"
            >編號
          </label>
        </div>
        <b-form-input
          :id="$route.name + '10'"
          :name="$route.name + '10'"
          placeholder="請輸入編號"
          v-model="parkingFloor.number"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >車位尺寸：
          </label>
        </div>

        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '11'"
            >長
          </label>
        </div>
        <b-form-input
          :id="$route.name + '11'"
          :name="$route.name + '11'"
          placeholder=""
          v-model="parkingSize.long"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">公尺 </label>
        </div>

        <div class="d-flex justify-content-end ml-3">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '12'"
            >寬
          </label>
        </div>
        <b-form-input
          :id="$route.name + '12'"
          :name="$route.name + '12'"
          placeholder=""
          v-model="parkingSize.width"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">公尺 </label>
        </div>

        <div class="d-flex justify-content-end ml-3">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '13'"
            >淨高
          </label>
        </div>
        <b-form-input
          :id="$route.name + '13'"
          :name="$route.name + '13'"
          placeholder=""
          v-model="parkingSize.height"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">公尺 </label>
        </div>

        <div class="d-flex justify-content-end ml-3">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '14'"
            >機械車位可乘載之重量
          </label>
        </div>
        <b-form-input
          :id="$route.name + '14'"
          :name="$route.name + '14'"
          placeholder=""
          v-model="parkingSize.weight"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">公斤 </label>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >車位形式：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingType.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingType.value"
            :value="item.value"
            :id="parkingType.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingType.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >車位性質：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingProperty.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingProperty.value"
            :value="item.value"
            :id="parkingProperty.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingProperty.name + item.value">
            {{ item.text }}</label
          >
        </div>
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap"
            class="fontCSS mr-1"
            :for="$route.name + '15'"
            >說明
          </label>
        </div>
        <b-form-input
          :id="$route.name + '15'"
          :name="$route.name + '15'"
          placeholder=""
          v-model="parkingProperty.memo"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 20rem"
          trim
        />
      </div>
      <div
        v-for="(item, index) in parkingOwnership.options"
        :key="index"
        class="d-flex"
      >
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :style="index !== 0 ? 'opacity:0' : ''"
            >車位形式：
          </label>
        </div>
        <div class="d-flex mr-1">
          <b-form-checkbox
            class="mt-1"
            v-model="parkingOwnership.value"
            :value="item.value"
            :id="parkingOwnership.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingOwnership.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >使用權：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingUsageRights.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingUsageRights.value"
            :value="item.value"
            :id="parkingUsageRights.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingUsageRights.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >使用狀況：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingUsageStatus.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingUsageStatus.value"
            :value="item.value"
            :id="parkingUsageStatus.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingUsageStatus.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >使用約定：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingUsagePromise.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingUsagePromise.value"
            :value="item.value"
            :id="parkingUsagePromise.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingUsagePromise.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
    </b-card>
    <b-card
      class="mb-3"
      :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
    >
      <div class="d-flex justify-content-between mb-3">
        <h1 style="margin: 0; font-size: 2rem">車位標示</h1>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >車位資料：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingSpot.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingSpot.value"
            :value="item.value"
            :id="parkingSpot.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingSpot.name + item.value">
            {{ item.text }}</label
          >
        </div>
        <div class="d-flex justify-content-end ml-3 mr-1">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '7'"
            >車位面積
          </label>
        </div>
        <b-form-input
          :id="$route.name + '7'"
          :name="$route.name + '7'"
          placeholder="請輸入車位面積"
          v-model="parkingSpot.area"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex justify-content-end ml-1">
          <label style="white-space: nowrap" class="fontCSS">平方公尺 </label>
        </div>

        <div class="d-flex justify-content-end ml-5">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '2'"
            >車位來源：
          </label>
        </div>
        <div
          v-for="(item, index) in this.parkingSource.options"
          :key="index"
          class="d-flex mr-3"
        >
          <b-form-radio
            class="mt-1"
            v-model="parkingSource.value"
            :value="item.value"
            :id="parkingSource.name + item.value"
          >
          </b-form-radio>
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="parkingSource.name + item.value"
          >
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :for="$route.name + '8'"
            >車位管理費：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '8'"
          :name="$route.name + '8'"
          placeholder="請輸入管理費"
          v-model="parkingFee.input"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex mr-3 ml-2">
          <b-form-checkbox
            class="mt-1"
            v-model="parkingFee.checkBox"
            id="parkingFeeCheckBox"
          >
          </b-form-checkbox>
          <label
            style="white-space: nowrap"
            class="fontCSS"
            for="parkingFeeCheckBox"
          >
            含在管理費</label
          >
        </div>
        <div
          v-for="(item, index) in parkingFee.type.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingFee.type.value"
            :value="item.value"
            :id="parkingFee.type.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingFee.type.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :for="$route.name + '9'"
            >車位樓層：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '9'"
          :name="$route.name + '9'"
          placeholder="請輸入樓層"
          v-model="parkingFloor.floor"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end ml-3">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '10'"
            >編號
          </label>
        </div>
        <b-form-input
          :id="$route.name + '10'"
          :name="$route.name + '10'"
          placeholder="請輸入編號"
          v-model="parkingFloor.number"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >車位尺寸：
          </label>
        </div>

        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '11'"
            >長
          </label>
        </div>
        <b-form-input
          :id="$route.name + '11'"
          :name="$route.name + '11'"
          placeholder=""
          v-model="parkingSize.long"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">公尺 </label>
        </div>

        <div class="d-flex justify-content-end ml-3">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '12'"
            >寬
          </label>
        </div>
        <b-form-input
          :id="$route.name + '12'"
          :name="$route.name + '12'"
          placeholder=""
          v-model="parkingSize.width"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">公尺 </label>
        </div>

        <div class="d-flex justify-content-end ml-3">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '13'"
            >淨高
          </label>
        </div>
        <b-form-input
          :id="$route.name + '13'"
          :name="$route.name + '13'"
          placeholder=""
          v-model="parkingSize.height"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">公尺 </label>
        </div>

        <div class="d-flex justify-content-end ml-3">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '14'"
            >機械車位可乘載之重量
          </label>
        </div>
        <b-form-input
          :id="$route.name + '14'"
          :name="$route.name + '14'"
          placeholder=""
          v-model="parkingSize.weight"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">公斤 </label>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >車位形式：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingType.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingType.value"
            :value="item.value"
            :id="parkingType.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingType.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >車位性質：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingProperty.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingProperty.value"
            :value="item.value"
            :id="parkingProperty.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingProperty.name + item.value">
            {{ item.text }}</label
          >
        </div>
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap"
            class="fontCSS mr-1"
            :for="$route.name + '15'"
            >說明
          </label>
        </div>
        <b-form-input
          :id="$route.name + '15'"
          :name="$route.name + '15'"
          placeholder=""
          v-model="parkingProperty.memo"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 20rem"
          trim
        />
      </div>
      <div
        v-for="(item, index) in parkingOwnership.options"
        :key="index"
        class="d-flex"
      >
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :style="index !== 0 ? 'opacity:0' : ''"
            >車位形式：
          </label>
        </div>
        <div class="d-flex mr-1">
          <b-form-checkbox
            class="mt-1"
            v-model="parkingOwnership.value"
            :value="item.value"
            :id="parkingOwnership.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingOwnership.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >使用權：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingUsageRights.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingUsageRights.value"
            :value="item.value"
            :id="parkingUsageRights.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingUsageRights.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >使用狀況：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingUsageStatus.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingUsageStatus.value"
            :value="item.value"
            :id="parkingUsageStatus.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingUsageStatus.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >使用約定：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingUsagePromise.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingUsagePromise.value"
            :value="item.value"
            :id="parkingUsagePromise.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingUsagePromise.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import { Toast } from "./../../utils/helper";
// import BaseTable from "@/components/BaseTable";
// import SearchSelect from "./../../../components/SearchSelect";
// import DateField from "./../../../components/DateField";
// import AddressField from "./../../components/AddressField";
export default {
  components: {
    // BaseTable,
    // SearchSelect,
    // AddressField,
    // DateField,
  },
  data() {
    return {
      //card 1
      
      //card 2
      parkingSpot: {
        name: "parkingSpot",
        value: "",
        options: [
          { text: "無", value: "0" },
          { text: "有", value: "1" },
        ],
        area: "",
      },
      parkingSource: {
        name: "parkingSource",
        value: "",
        options: [
          { text: "公設", value: "1" },
          { text: "獨立權狀", value: "0" },
        ],
      },
      parkingFee: {
        input: "",
        checkBox: false,
        type: {
          name: "parkingFee",
          value: "",
          options: [
            { text: "月", value: "0" },
            { text: "季", value: "1" },
            { text: "半年", value: "2" },
            { text: "年繳", value: "3" },
            { text: "其他", value: "4" },
          ],
        },
      },
      parkingFloor: {
        floor: "",
        number: "",
      },
      parkingSize: {
        long: "",
        width: "",
        height: "",
        weight: "",
      },
      parkingType: {
        name: "parkingType",
        value: "",
        options: [
          { text: "坡道平面", value: "0" },
          { text: "升降平面", value: "1" },
          { text: "坡道機械", value: "2" },
          { text: "升降機械", value: "3" },
          { text: "塔式車位", value: "4" },
          { text: "一樓平面", value: "5" },
          { text: "電腦倉儲", value: "6" },
          { text: "其他", value: "7" },
        ],
      },
      parkingProperty: {
        name: "parkingProperty",
        value: "",
        options: [
          { text: "不清楚", value: "0" },
          { text: "法定車位", value: "1" },
          { text: "自行增設車位", value: "2" },
          { text: "獎勵增設車位", value: "3" },
          { text: "其他", value: "4" },
        ],
        memo: "",
      },
      parkingOwnership: {
        name: "parkingOwnership",
        value: "",
        options: [
          {
            text: "以區分所有建物持分登記(有獨立權狀,固定車位編號)",
            value: "0",
          },
          { text: "以共用部分登記未約定專用", value: "1" },
          {
            text: "以共用部分登記業約定專用意(1.無獨立權狀,有登記車位編號 2.管委會或住戶有分管約定)",
            value: "2",
          },
          { text: "未登記", value: "3" },
        ],
      },
      parkingUsageRights: {
        name: "parkingUsageRights",
        value: "",
        options: [
          { text: "無", value: "0" },
          { text: "專用", value: "1" },
          { text: "共用", value: "2" },
          { text: "租用", value: "3" },
          { text: "借用", value: "4" },
          { text: "抽籤", value: "5" },
          { text: "排隊等候", value: "6" },
          { text: "先到先停", value: "7" },
        ],
      },
      parkingUsageStatus: {
        name: "parkingUsageStatus",
        value: "",
        options: [
          { text: "自用", value: "0" },
          { text: "出租", value: "1" },
          { text: "出借", value: "2" },
          { text: "被佔用", value: "3" },
          { text: "其他", value: "4" },
        ],
      },
      parkingUsagePromise: {
        name: "parkingUsagePromise",
        value: "",
        options: [
          { text: "無", value: "0" },
          { text: "有", value: "1" },
        ],
      },
    };
  },
  methods: {},
  computed: {
    ...mapState(["backgroundColor"]),
  },
  created() {},
  watch: {},
};
</script> 
<style scoped>
.underLine {
  position: relative;
}
.underLine::after {
  background-color: #dddddd;
  bottom: -0.1rem;
  content: "";
  display: block;
  height: 1px;
  left: 1rem;
  right: 1rem;
  position: absolute;
}
</style>
