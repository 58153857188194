<template>
  <div>
    <div class="mainContent">
      <div class="detailTitle">集團資料</div>
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row>
          <div
            v-if="logoUrl"
            style="width: 100px; height: 100px; margin-left: 2rem"
          >
            <img
              style="height: 100%; width: 100%"
              :src="logoUrl"
              alt="未上傳Logo"
            />
          </div>
          <div
            :style="
              logoUrl
                ? 'width: calc(100% - 150px); margin: auto 0'
                : 'width: 90%; margin: auto auto'
            "
          >
            <b-row>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS">集團名稱： </label>
                </div>

                <b-form-input
                  :id="$route.name + 'detail1'"
                  :name="$route.name + 'detail1'"
                  placeholder="請輸入公會名稱"
                  v-model="groupName"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  disabled
                  trim
                />
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS">狀態： </label>
                </div>
                <div
                  v-for="(item, index) in this.statusRadio.options"
                  :key="index"
                  class="d-flex align-items-center"
                >
                  <b-form-radio
                    style="margin-bottom: 0.5rem"
                    v-model="statusRadio.value"
                    :value="item.value"
                    :id="statusRadio.name + item.value"
                    disabled
                  >
                  </b-form-radio>
                  <label class="fontColor" :for="statusRadio.name + item.value">
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
              <b-col class="d-flex col-6">
                <div class="title">
                  <label class="fontCSS">啟用日期： </label>
                </div>
                <div style="width: 100%">
                  <DateField
                    :isabled="false"
                    :value.sync="startDate"
                  ></DateField>
                </div>
              </b-col>
              <b-col class="d-flex">
                <div class="title">
                  <label class="fontCSS">停用日期： </label>
                </div>
                <div style="width: 100%">
                  <DateField :isabled="false" :value.sync="endDate"></DateField>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-row>
      </b-card>
      <b-card>
        <b-col
          v-if="this.userPremission.search('公司負責人') == -1"
          class="d-flex align-items-center"
        >
          <div
            v-for="(item, index) in this.companyStatus.options"
            :key="index"
            class="d-flex mr-1 align-items-center"
          >
            <b-form-radio
              style="margin-bottom: 0.5rem"
              v-model="companyStatus.value"
              :value="item.value"
              :id="companyStatus.name + item.value"
              @change="searchAction()"
            >
            </b-form-radio>
            <label class="fontColor" :for="companyStatus.name + item.value">
              {{ item.text }}</label
            >
          </div>
        </b-col>
        <BaseTable
          v-show="table.items.length !== 0"
          :fields="table.fields"
          :items="table.items"
          :currentPage="table.currentPage"
          :totalItems="table.totalItems"
          :perPage="table.perPage"
          :editAction="editAction"
          :clickPage="clickPage"
          :detailAction="editAction"
        />
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "@/utils/helper";
import BaseTable from "@/components/BaseTable";
import DateField from "@/components/DateField";
import usersAPI from "@/apis/usersAPI";
import moment from "moment";

export default {
  components: {
    BaseTable,
    DateField,
  },
  data() {
    return {
      userPremission: "",
      isProcessing: false,

      groupName: "",
      startDate: "",
      endDate: "",
      statusRadio: {
        name: "statusRadio",
        value: 1,
        options: [
          { text: "啟用", value: 1 },
          { text: "停用", value: 0 },
        ],
      },
      companyStatus: {
        name: "companyStatus",
        value: 1,
        options: [
          { text: "啟用", value: 1 },
          { text: "停用", value: 0 },
        ],
      },
      logoUrl: "",

      searchData: null,
      table: {
        currentPage: 1,
        perPage: 5,
        totalItems: 0,
        fields: [
          {
            key: "COL0",
            label: "",
            type: "default_btn",
          },
          { key: "COL1", label: "排序" },
          { key: "COL2", label: "公司名稱" },
          { key: "COL3", label: "顯示名稱" },
          { key: "COL4", label: "加盟品牌" },
          { key: "COL5", label: "公司電話" },
          { key: "COL6", label: "啟用日期" },
          { key: "COL7", label: "停用日期" },
          { key: "COL8", label: "人數" },
        ],
        items: [],
      },
    };
  },
  methods: {
    async getCompanyGroupData() {
      try {
        this.isProcessing = true;
        if (!this.currentUser.permissionInfo.corpID) {
          Toast.fire({
            icon: "warning",
            title: "查無所屬集團，請洽管理處",
          });
          return;
        }

        const response = await usersAPI.getCompanyGroupData({
          corpID: this.currentUser.permissionInfo.corpID,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        let data = response.data.list[0];
        this.groupName = data.corpNM;
        this.startDate = moment(data.startTime).format("YYYY-MM-DD");
        this.endDate = moment(data.endTime).format("YYYY-MM-DD");
        this.statusRadio.value = Number(data.status);
        if (data.filePathList.length > 0) {
          this.logoUrl = data.filePathList[0].linkPath;
        }
        this.searchAction();

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async searchAction(page) {
      this.table.currentPage = page ? page : this.table.currentPage;
      try {
        this.isProcessing = true;
        if (
          this.userPremission.search("公司負責人") !== -1 &&
          !this.currentUser.permissionInfo.companyID
        ) {
          Toast.fire({
            icon: "warning",
            title: "查無所屬公司，請洽管理處",
          });
          return;
        }

        const response = await usersAPI.getCompanyList({
          roW_INDEX: (this.table.currentPage - 1) * this.table.perPage,
          rows: this.table.perPage,
          corpID: this.currentUser.permissionInfo.corpID,
          status: this.companyStatus.value,
          theday: null,
          taxID:
            this.userPremission.search("公司負責人") !== -1
              ? this.currentUser.permissionInfo.companyID
              : null,
        });

        let data = response.data;

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        this.searchData = data;
        this.setSearchData();
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    setSearchData() {
      this.table.items.splice(0);
      this.table.totalItems = this.searchData.dataTotal;
      let btn = "";
      if (this.userPremission.search("修改") !== -1) {
        btn = "edit";
      } else {
        btn = "detail";
      }

      let array = [];
      for (var index in this.searchData.list) {
        let data = this.searchData.list[index];
        array.push({
          COL0: btn,
          COL1: data.sort,
          COL2: data.taxNM,
          COL3: data.shortName,
          COL4: data.brandNM,
          COL5: data.tel,
          COL6: moment(data.startTime).format("YYYY-MM-DD"),
          COL7: moment(data.endTime).format("YYYY-MM-DD"),
          COL8: data.taxHeadcount,
        });
      }
      this.table.items = array;
    },
    clickPage(page) {
      this.table.currentPage = page;
      this.searchAction();
    },
    editAction(field, rowIndex) {
      console.log(field);
      let id = this.searchData.list[rowIndex].taxSID;
      this.$router.push({
        name: "CompanyManagementDetail",
        params: { id: id },
      });
    },
  },
  watch: {},
  computed: {
    ...mapState(["backgroundColor", "currentUser", "currentRoute"]),
  },
  created() {
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;

    this.getCompanyGroupData();
  },
};
</script>
<style scoped>
.title {
  white-space: nowrap;
  min-width: 150px;
  display: flex;
  justify-content: end;
}
</style>