<template>
  <div>
    <div class="mainContent">
      <div class="d-flex">
        <p v-show="viewSaveType.type == 'create'" class="detailTitle">
          新增公會
        </p>
        <p
          v-show="viewSaveType.type == 'edit' || viewSaveType.type == 'read'"
          class="detailTitle"
        >
          公會資料維護
        </p>
        <p v-show="viewSaveType.type == 'delete'" class="detailTitle">
          停用公會
        </p>
        <p v-show="viewSaveType.type == 'deleteEdit'" class="detailTitle">
          停用公會資料維護
        </p>
      </div>
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row class="mb-3">
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'detail1'"
                  >公會名稱：
                </label>
              </b-col>
              <b-col>
                <b-form-input
                  :id="$route.name + 'detail1'"
                  :name="$route.name + 'detail1'"
                  placeholder="請輸入公會名稱"
                  v-model="guildName"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  :disabled="
                    viewSaveType.type == 'delete' || viewSaveType.type == 'read'
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">狀態： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <div
                  v-for="(item, index) in this.statusRadio.options"
                  :key="index"
                  class="d-flex mr-1 align-items-center"
                >
                  <b-form-radio
                    v-model="statusRadio.value"
                    style="margin-bottom: 0.5rem"
                    :value="item.value"
                    :id="statusRadio.name + item.value"
                    :disabled="viewSaveType.type !== 'delete'"
                    @change="changeStatus()"
                  >
                  </b-form-radio>
                  <label class="fontColor" :for="statusRadio.name + item.value">
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">啟用日期： </label>
              </b-col>
              <b-col>
                <DateField
                  :isabled="
                    viewSaveType.type == 'read' ||
                    viewSaveType.type == 'delete' ||
                    viewSaveType.type == 'deleteEdit'
                      ? false
                      : true
                  "
                  :value.sync="startDate"
                ></DateField>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">停用日期： </label>
              </b-col>
              <b-col>
                <DateField
                  :isabled="
                    viewSaveType.type == 'read' ||
                    viewSaveType.type == 'edit' ||
                    viewSaveType.type == 'deleteEdit'
                      ? false
                      : true
                  "
                  :value.sync="endDate"
                ></DateField>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'detail5'"
                  >排序：
                </label>
              </b-col>
              <b-col>
                <b-form-input
                  :id="$route.name + 'detail5'"
                  :name="$route.name + 'detail5'"
                  placeholder="請輸入排序，數字愈小愈前面"
                  v-model="sort"
                  type="number"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  :disabled="
                    viewSaveType.type == 'read' ||
                    viewSaveType.type == 'delete' ||
                    viewSaveType.type == 'deleteEdit'
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-show="viewSaveType.type == 'edit' || viewSaveType.type == 'read'"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <div class="d-flex">
          <div style="width: 100%">
            <label class="fontCSS">未加入公會之集團清單</label>
            <div class="changeListCSS">
              <b-list-group>
                <b-list-group-item
                  v-for="(item, index) in this.unCheckList"
                  :key="index"
                >
                  <div class="d-flex align-items-center">
                    <b-form-checkbox
                      :id="'unCheckList' + index"
                      v-model="item.value"
                      class="col-1"
                      :disabled="viewSaveType.type == 'read'"
                    >
                    </b-form-checkbox>
                    <label
                      :for="'unCheckList' + index"
                      style="margin: 0"
                      class="fontCSS"
                    >
                      {{ item.title }}
                    </label>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
          <div style="width: 10rem; margin: 1rem" class="mt-5">
            <div class="d-flex flex-column">
              <label class="fontCSS">加入</label>
              <b-button
                @click="editCheckList(0)"
                class="btn-icon"
                variant="primary"
                :disabled="viewSaveType.type == 'read'"
              >
                <b-icon icon="chevron-double-right"></b-icon>
              </b-button>
            </div>
            <div class="d-flex flex-column mt-5">
              <label class="fontCSS">退出</label>
              <b-button
                @click="editCheckList(1)"
                class="btn-icon"
                variant="primary"
                :disabled="viewSaveType.type == 'read'"
              >
                <b-icon icon="chevron-double-left"></b-icon>
              </b-button>
            </div>
          </div>
          <div style="width: 100%">
            <label class="fontCSS">已加入該公會之集團清單</label>
            <div class="changeListCSS">
              <b-list-group>
                <b-list-group-item
                  v-for="(item, index) in this.checkList"
                  :key="index"
                >
                  <div class="d-flex align-items-center">
                    <b-form-checkbox
                      :id="'checkList' + index"
                      v-model="item.value"
                      class="col-1"
                      :disabled="viewSaveType.type == 'read'"
                    >
                    </b-form-checkbox>
                    <label
                      :for="'checkList' + index"
                      style="margin: 0"
                      class="fontCSS"
                      >{{ item.title }}</label
                    >
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </div>
      </b-card>

      <div class="col-12 mt-4">
        <b-button
          v-show="viewSaveType.type !== 'read'"
          :disabled="isProcessing"
          @click="saveAction()"
          class="mr-2"
          variant="primary"
          >確定</b-button
        >
        <b-button @click="backAction()" variant="outline-secondary"
          >返回</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "@/utils/helper";
import DateField from "@/components/DateField";
import usersAPI from "@/apis/usersAPI";
import moment from "moment";

export default {
  components: {
    DateField,
  },
  data() {
    return {
      userPremission: "",
      viewSaveType: {
        id: null,
        type: "",
      },
      isProcessing: false,

      unCheckList: [],
      checkList: [],

      guildID: "",
      guildName: "",
      startDate: "",
      endDate: "",
      originEndDate: "",
      statusRadio: {
        name: "statusRadio",
        value: 1,
        options: [
          { text: "啟用", value: 1 },
          { text: "停用", value: 0 },
        ],
      },
      sort: null,
    };
  },
  methods: {
    checkViewSaveType() {
      this.viewSaveType.id = this.$route.params.id;
      if (this.$route.name == "GuildsManagementDelete") {
        this.viewSaveType.type = "delete";
        this.getGuildData();
      } else if (this.$route.name == "GuildsManagementCreate") {
        this.viewSaveType.type = "create";
        this.startDate = moment().format("YYYY-MM-DD");
        this.endDate = "9999-12-31";
        this.sort = 1;
      } else if (this.$route.name == "GuildsManagementDeleteEdit") {
        if (this.userPremission.search("修改") !== -1) {
          this.viewSaveType.type = "deleteEdit";
        } else {
          this.viewSaveType.type = "read";
        }
        this.getGuildData();
      } else {
        if (this.userPremission.search("修改") !== -1) {
          this.viewSaveType.type = "edit";
        } else {
          this.viewSaveType.type = "read";
        }
        this.getGuildData();
      }
    },

    editCheckList(type) {
      let checkArray = [];
      let array1 = null;
      let array2 = null;
      if (type == 0) {
        array1 = [...this.unCheckList];
        array2 = [...this.checkList];
      } else {
        array2 = [...this.unCheckList];
        array1 = [...this.checkList];
      }

      for (var index in array1) {
        if (array1[index].value) {
          checkArray.push({
            id: array1[index].id,
            value: false,
            title: array1[index].title,
            assocID: array1[index].assocID,
          });
        }
      }

      if (type == 0) {
        this.unCheckList = array1.filter((a) => a.value !== true);
        this.checkList = array2.concat(checkArray);
      } else {
        this.checkList = array1.filter((a) => a.value !== true);
        this.unCheckList = array2.concat(checkArray);
      }
    },
    changeStatus() {
      if (this.viewSaveType.type == "delete" && this.statusRadio.value == 0) {
        this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      }
    },
    saveAction() {
      if (
        this.viewSaveType.type == "create" &&
        this.endDate <= moment().format("YYYY-MM-DD")
      ) {
        Toast.fire({
          icon: "warning",
          title: "停用日期不得小於當日。",
        });
        return;
      }

      if (!this.guildName) {
        Toast.fire({
          icon: "warning",
          title: "請輸入公會名稱",
        });
        return;
      }
      if (this.viewSaveType.type == "create") {
        this.createGuildData();
      } else if (this.viewSaveType.type == "delete") {
        this.deleteAction();
      } else {
        this.editGuildData();
      }
    },
    deleteAction() {
      let msg = "";
      let today = moment().format("YYYY-MM-DD");
      let type = 0;
      if (this.endDate < today) {
        msg = "公會將立即停用，請再次確定。";
        type = 1;
      } else {
        if (this.originEndDate !== this.endDate) {
          msg = "公會將於" + this.endDate + " 23:59 停用，請再次確定。";
        } else {
          this.backAction();
          return;
        }
      }

      this.$bvModal
        .msgBoxConfirm(msg, {
          bodyClass: "checkModalCss",
          headerClass: " border-bottom-0",
          footerClass: "p-2 border-top-0",
          okVariant: "primary",
          okTitle: "確定",
          cancelTitle: "取消",
          cancelVariant: "outline-secondary",
          size: "sm",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteGuildData(type);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    backAction() {
      let searchCondition = this.searchCondition;
      searchCondition.status = true;
      this.$store.commit("getsearchCondition", searchCondition);

      this.$router.push({
        name: "GuildsManagement",
      });
    },

    async getGuildData() {
      try {
        this.isProcessing = true;
        const response = await usersAPI.getGuildData({
          assocID: this.viewSaveType.id,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        let data = response.data.list[0];
        this.guildID = data.assocID;
        this.guildName = data.assocNM;
        this.statusRadio.value = Number(data.status);
        this.startDate = moment(data.startTime).format("YYYY-MM-DD");
        this.endDate = moment(data.endTime).format("YYYY-MM-DD");
        this.originEndDate = moment(data.endTime).format("YYYY-MM-DD");
        this.sort = data.sort;

        this.unCheckList.splice(0);
        this.checkList.splice(0);
        for (var idx1 in data.appertainCorpList) {
          let data1 = data.appertainCorpList[idx1];
          this.checkList.push({
            id: data1.corpID,
            value: false,
            title: data1.corpNM,
            assocID: data1.assocID,
          });
        }
        for (var idx2 in data.ownerlessCorpList) {
          let data2 = data.ownerlessCorpList[idx2];
          this.unCheckList.push({
            id: data2.corpID,
            value: false,
            title: data2.corpNM,
            assocID: data2.assocID,
          });
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async createGuildData() {
      try {
        this.isProcessing = true;
        const response = await usersAPI.createGuildData({
          AssocID: null,
          AssocNM: this.guildName,
          Sort: this.sort ? Number(this.sort) : null,
          StartTime: this.startDate == "" ? null : this.startDate,
          EndTime: this.endDate == "" ? null : this.endDate,
          Status: this.statusRadio.value,
          AssocLogo: null,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        Toast.fire({
          icon: "success",
          title: "新增成功",
        });
        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async editGuildData() {
      try {
        this.isProcessing = true;

        let AppertainCorpList = [];
        let OwnerlessCorpList = [];

        for (var idx1 in this.unCheckList) {
          let data1 = this.unCheckList[idx1];
          OwnerlessCorpList.push({
            corpID: data1.id,
            corpNM: data1.title,
            corpEmpID: null,
            corpEmpNM: null,
            assocID: data1.assocID,
          });
        }
        for (var idx2 in this.checkList) {
          let data2 = this.checkList[idx2];
          AppertainCorpList.push({
            corpID: data2.id,
            corpNM: data2.title,
            corpEmpID: null,
            corpEmpNM: null,
            assocID: data2.assocID,
          });
        }

        const response = await usersAPI.editGuildData({
          AssocID: this.guildID,
          AssocNM: this.guildName,
          Sort: Number(this.sort),
          startTime: this.startDate == "" ? null : this.startDate,
          endTime: this.endDate == "" ? null : this.endDate,
          Status: this.statusRadio.value,
          AssocLogo: null,
          AppertainCorpList: AppertainCorpList,
          OwnerlessCorpList: OwnerlessCorpList,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        Toast.fire({
          icon: "success",
          title: "修改成功",
        });
        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async deleteGuildData(type) {
      try {
        this.isProcessing = true;
        let disableList = [];

        disableList.push({
          assocID: this.guildID,
          startTime: this.startDate == "" ? null : this.startDate,
          endTime: this.endDate == "" ? null : this.endDate,
          status: this.statusRadio.value,
        });

        const response = await usersAPI.deleteGuildData({
          disableList: disableList,
        });
        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        if (type == 1) {
          Toast.fire({
            icon: "success",
            title: this.guildName + "已停用",
          });
        } else {
          Toast.fire({
            icon: "success",
            title: this.guildName + "已預作停用",
          });
        }

        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
  },
  watch: {},
  computed: {
    ...mapState([
      "backgroundColor",
      "currentUser",
      "currentRoute",
      "searchCondition",
    ]),
  },

  created() {
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;
    this.checkViewSaveType();
  },
};
</script>
<style scoped>
.changeListCSS {
  border: 1px solid #d4d7da;
  border-radius: 5px;
  padding: 0.2rem;
  height: 20rem;
  overflow: auto;
}
</style>