<template>
  <div>
    <div class="mainContent">
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row class="mb-3">
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">群組： </label>
              </b-col>
              <b-col class="">
                <SearchSelect
                  placeholder="請輸入關鍵字"
                  :searchinput.sync="PGroupSelect.keyWords"
                  :allchoose="PGroupSelect.allList"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col class="col-12">
            <b-button
              :disabled="isProcessing"
              class="mr-2"
              @click="searchAction(1)"
              variant="primary"
              >查詢</b-button
            >
            <b-button
              :disabled="isProcessing"
              @click="createAction()"
              variant="outline-primary"
              >新增</b-button
            >
          </b-col>
        </b-row>
      </b-card>

      <BaseTable
        v-show="table.items.length !== 0"
        :fields="table.fields"
        :items="table.items"
        :currentPage="table.currentPage"
        :totalItems="table.totalItems"
        :perPage="table.perPage"
        :editAction="editAction"
        :deleteAction="deleteAction"
        :clickPage="clickPage"
        :styleClickAction="styleClickAction"
      />
    </div>

    <!-- modal -->
    <b-modal
      :id="$route.name"
      :title="modalData.groupName + ' 人員名單'"
      hide-header-close
      :headerBgVariant="backgroundColor"
      :headerTextVariant="backgroundColor == 'dark' ? 'light' : 'dark'"
      :bodyBgVariant="backgroundColor"
      :bodyTextVariant="backgroundColor == 'dark' ? 'light' : 'dark'"
      :footerBgVariant="backgroundColor"
      :footerTextVariant="backgroundColor == 'dark' ? 'light' : 'dark'"
      centered
      size="lg"
    >
      <div
        :class="backgroundColor == 'dark' ? 'modalTypeDark' : 'modalTypeLight'"
      >
        <BaseTable
          :fields="modalData.table.fields"
          :items="modalData.table.items"
          :currentPage="modalData.table.currentPage"
          :totalItems="modalData.table.totalItems"
          :perPage="modalData.table.perPage"
          :clickPage="clickPageModal"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-row>
          <b-button variant="outline-secondary" @click="cancel">
            返回
          </b-button>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
    <script>
import { mapState } from "vuex";
import { Toast } from "./../../utils/helper";
import BaseTable from "./../../components/BaseTable";
import SearchSelect from "./../../components/SearchSelect";
import permissionAPI from "@/apis/permissionAPI";

export default {
  components: {
    BaseTable,
    SearchSelect,
  },
  data() {
    return {
      isProcessing: false,

      PGroupSelect: {
        keyWords: "",
        allList: [],
        isabled: true,
      },
      permission: [],

      // table
      searchData: null,
      table: {
        currentPage: 1,
        perPage: 5,
        totalItems: 0,
        fields: [
          {
            key: "COL0",
            label: "",
            type: "default_btn",
          },
          { key: "COL1", label: "群組ID" },
          { key: "COL2", label: "群組名稱", type: "style_click" },
          { key: "COL3", label: "使用者數量" },
        ],
        items: [],
      },

      //modal
      modalData: {
        groupName: "",
        groupID: "",
        searchData: null,
        table: {
          currentPage: 1,
          perPage: 5,
          totalItems: 0,
          fields: [
            { key: "COL0", label: "負責權限組織" },
            { key: "COL1", label: "人員名稱" },
            { key: "COL2", label: "人員所屬公司" },
          ],
          items: [],
        },
      },
    };
  },
  methods: {
    clickPageModal(page) {
      this.modalData.table.currentPage = page;
      this.searchModalAction(page);
    },
    styleClickAction(field, rowIndex) {
      console.log(field);
      let item = this.searchData.list[rowIndex];
      console.log("..item", item);
      if (item.userCount == 0) return;
      this.modalData.groupName = item.groupNM;
      this.modalData.groupID = item.groupID;
      this.searchModalAction(1);
    },

    async searchModalAction(page) {
      this.modalData.table.currentPage = page
        ? page
        : this.modalData.table.currentPage;
      try {
        this.isProcessing = true;

        let inputData = {
          roW_INDEX:
            (this.modalData.table.currentPage - 1) *
            this.modalData.table.perPage,
          rows: this.modalData.table.perPage,
          groupID: this.modalData.groupID,
          status: 1,
          theday: null,
        };

        const response = await permissionAPI.getPermUserDetailList(inputData);

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        this.modalData.searchData = response.data;
        this.setModalTable();
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    setModalTable() {
      this.modalData.table.items.splice(0);
      this.modalData.table.totalItems = this.modalData.searchData.dataTotal;
      let array = [];
      for (var index in this.modalData.searchData.list) {
        let data = this.modalData.searchData.list[index];

        let item = "";
        if (data.corpNM) {
          item = data.corpNM;
        }
        if (data.taxNM) {
          if (item == "") {
            item = data.taxNM;
          } else {
            item = item + " , " + data.taxNM;
          }
        }
        array.push({
          COL0: item,
          COL1: data.empNM,
          COL2: data.perTaxNM,
        });
      }
      this.modalData.table.items = array;
      this.$bvModal.show(this.$route.name);
    },

    async getGroupList() {
      try {
        this.isProcessing = true;
        let inputData = {
          roW_INDEX: null,
          rows: null,
          groupID: null,
          groupNM: null,
          status: 1,
          theday: null,
        };

        const response = await permissionAPI.getMasterList(inputData);

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        for (var index in response.data.list) {
          let group = response.data.list[index];
          this.PGroupSelect.allList.push({
            value: group.groupID,
            text: group.groupNM,
          });
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async searchAction(page) {
      this.table.currentPage = page ? page : this.table.currentPage;
      try {
        this.isProcessing = true;

        let inputData = {
          roW_INDEX: (this.table.currentPage - 1) * this.table.perPage,
          rows: this.table.perPage,
          groupID: null,
          groupNM: this.PGroupSelect.keyWords,
          status: 1,
          theday: null,
        };

        const response = await permissionAPI.getMasterList(inputData);

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        let data = response.data;
        this.searchData = data;
        this.setSearchData();
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    setSearchData() {
      this.table.items.splice(0);
      this.table.totalItems = this.searchData.dataTotal;

      let array = [];
      for (var index in this.searchData.list) {
        let data = this.searchData.list[index];
        let btn = "";
        if (data.userCount > 0) {
          btn = "edit";
        } else {
          btn = "edit,delete";
        }
        // 總管理處 集團長 公司負責人 寫死 不能刪除
        if (data.groupID == 2 || data.groupID == 3 || data.groupID == 4) {
          btn = "edit";
        }

        let item = {
          text: data.groupNM,
          style: "color:#04104a",
        };

        array.push({
          COL0: btn,
          COL1: data.groupID,
          COL2: item,
          COL3: data.userCount,
        });
      }
      this.table.items = array;
    },
    clickPage(page) {
      this.table.currentPage = page;
      this.searchAction();
    },

    createAction() {
      this.$router.push({
        name: "PermissionManagementCreate",
      });
    },
    editAction(field, rowIndex) {
      console.log(field, rowIndex);
      let PGroupID = this.searchData.list[rowIndex].groupID;
      this.$router.push({
        name: "PermissionManagementDetail",
        params: { id: PGroupID },
      });
    },
    deleteAction(field, rowIndex) {
      console.log(field, rowIndex);
      this.$bvModal
        .msgBoxConfirm("確定要刪除該群組？", {
          bodyClass: "checkModalCss",
          headerClass: " border-bottom-0",
          footerClass: "p-2 border-top-0",
          okVariant: "primary",
          okTitle: "確定",
          cancelTitle: "取消",
          cancelVariant: "outline-secondary",
          size: "sm",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deletePermissionGroup(rowIndex);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async deletePermissionGroup(index) {
      try {
        this.isProcessing = true;
        let disableList = [];
        disableList.push({
          groupID: this.searchData.list[index].groupID,
          status: 0,
        });

        const response = await permissionAPI.deletePermissionGroup({
          disableList: disableList,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        Toast.fire({
          icon: "success",
          title: this.searchData.list[index].groupNM + "，已停用",
        });
        this.isProcessing = false;
        this.searchAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
  },
  watch: {},
  computed: {
    ...mapState([
      "backgroundColor",
      "currentUser",
      "currentRoute",
      "searchCondition",
    ]),
  },
  created() {
    //取得使用者該頁面權限
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;
    this.getGroupList();
    this.searchAction();
  },
};
</script>
    <style scoped>
</style>