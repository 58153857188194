<template>
  <div>
    <div class="mainContent">
      <div class="detailTitle">{{ showTitle }}</div>
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row class="mb-3">
          <b-col class="d-flex" style="padding: 0" lg="12">
            <div style="padding: 0" class="col-6">
              <b-col lg="12">
                <b-row>
                  <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                    <label class="fontCSS" :for="$route.name + 'user1'"
                      >姓名：
                    </label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      :id="$route.name + 'user1'"
                      :name="$route.name + 'user1'"
                      placeholder="請輸入姓名"
                      v-model="name"
                      type="text"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      :disabled="fieldStatus()"
                      trim
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                    <label class="fontCSS" :for="$route.name + 'user31'"
                      >集團：
                    </label>
                  </b-col>
                  <b-col>
                    <b-form-select
                      :id="$route.name + 'user31'"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      v-model="groupSelect.value"
                      :options="groupSelect.options"
                      :disabled="fieldStatus()"
                      @change="getCompanyList()"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                    <label class="fontCSS" :for="$route.name + 'user123'"
                      >公司：</label
                    >
                  </b-col>
                  <b-col>
                    <b-form-input
                      :id="$route.name + 'user123'"
                      :name="$route.name + 'user123'"
                      placeholder="公司名稱"
                      v-model="companyName"
                      type="text"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      disabled
                      trim
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                    <label class="fontCSS" :for="$route.name + 'user2'"
                      >公司顯示名稱：</label
                    >
                  </b-col>
                  <b-col>
                    <SearchSelect
                      :searchinput.sync="companySelect.keyWords"
                      :allchoose="companySelect.allList"
                      :placeholder="'請輸入關鍵字'"
                      :isabled="fieldStatus() ? false : true"
                      @linkage="getDepartmentList()"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                    <label class="fontCSS" :for="$route.name + 'user3'"
                      >組別：
                    </label>
                  </b-col>
                  <b-col>
                    <b-form-select
                      :id="$route.name + 'user3'"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      v-model="departmentSelect.value"
                      :options="departmentSelect.options"
                      :disabled="fieldStatus()"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                    <label class="fontCSS" :for="$route.name + 'user4'"
                      >職位：
                    </label>
                  </b-col>
                  <b-col>
                    <b-form-select
                      :id="$route.name + 'user4'"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      v-model="positionSelect.value"
                      :options="positionSelect.options"
                      :disabled="fieldStatus()"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                    <label class="fontCSS" :for="$route.name + 'user5'"
                      >在職狀況：
                    </label>
                  </b-col>
                  <b-col>
                    <b-form-select
                      :id="$route.name + 'user5'"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      :disabled="
                        this.viewSaveType.type == 'read' ||
                        this.viewSaveType.type == 'delete'
                      "
                      v-model="statusSelect.value"
                      :options="statusSelect.options"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                    <label class="fontCSS" :for="$route.name + 'user6'"
                      >性別：
                    </label>
                  </b-col>
                  <b-col>
                    <b-form-select
                      :id="$route.name + 'user6'"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      :disabled="fieldStatus()"
                      v-model="genderSelect.value"
                      :options="genderSelect.options"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                    <label class="fontCSS" :for="$route.name + 'user7'"
                      >身分證：
                    </label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      :id="$route.name + 'user7'"
                      :name="$route.name + 'user7'"
                      placeholder="請輸入身分證編號"
                      v-model="IDnumber"
                      type="text"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      @change="checkVerify(1)"
                      :disabled="viewSaveType.type !== 'create'"
                      trim
                    />
                    <div
                      v-for="(item, index) in IDcheckbox.options"
                      :key="index"
                      class="d-flex mr-1"
                    >
                      <b-form-checkbox
                        v-model="IDcheckbox.value"
                        :value="item.value"
                        :id="IDcheckbox.name + item.value"
                        style="margin-top: 0.2rem"
                        :disabled="viewSaveType.type !== 'create'"
                        @change="checkIDcheckbox()"
                      >
                      </b-form-checkbox>
                      <label
                        class="fontColor"
                        :for="IDcheckbox.name + item.value"
                      >
                        {{ item.text }}
                      </label>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                    <label class="fontCSS">出生日期： </label>
                  </b-col>
                  <b-col>
                    <DateField
                      :isabled="fieldStatus() ? false : true"
                      :value.sync="birthDay"
                    ></DateField>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                    <label class="fontCSS" :for="$route.name + 'user8'"
                      >婚姻：
                    </label>
                  </b-col>
                  <b-col>
                    <b-form-select
                      :id="$route.name + 'user8'"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      :disabled="fieldStatus()"
                      v-model="marriageSelect.value"
                      :options="marriageSelect.options"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                    <label class="fontCSS" :for="$route.name + 'user9'"
                      >本業年資：
                    </label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      :id="$route.name + 'user9'"
                      :name="$route.name + 'user9'"
                      placeholder="請輸入本業年資"
                      v-model="experience"
                      type="number"
                      :class="
                        backgroundColor == 'dark'
                          ? 'text-light bg-secondary'
                          : ''
                      "
                      :disabled="fieldStatus()"
                      trim
                    />
                  </b-col>
                </b-row>
              </b-col>
            </div>
            <div class="col-6 d-flex justify-content-center">
              <input
                v-show="false"
                id="newupfule"
                ref="fileRef"
                type="file"
                accept=".jpg, .png, .bmp"
                @change="fileChange"
                :disabled="fieldStatus()"
              />
              <div
                class="upload-btn-div"
                :style="!photo.previewUrl ? 'background-color: #bcc1c5':''"
                @click="uploadFile()"
              >
                <!-- CameraIcon -->
                <div
                  class="d-flex flex-column align-items-center"
                  v-if="!photo.previewUrl"
                  style="cursor: pointer"
                >
                  <label style="font-size: 1.5rem; margin-top: 10rem" for=""
                    >請點擊上傳照片</label
                  >
                  <i
                    style="font-size: 5rem"
                    class="fa-solid fa-camera-retro"
                  ></i>
                </div>
                <img
                 class="upload-btn-img-preview"
                  v-show="photo.previewUrl"
                  :src="photo.previewUrl"
                />
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'user10'"
                  >教育程度：
                </label>
              </b-col>
              <b-col>
                <b-form-select
                  :id="$route.name + 'user10'"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="educationSelect.value"
                  :options="educationSelect.options"
                  :disabled="fieldStatus()"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'user11'"
                  >畢業學校：
                </label>
              </b-col>
              <b-col>
                <b-form-input
                  :id="$route.name + 'user11'"
                  :name="$route.name + 'user11'"
                  placeholder="請輸入學校名稱"
                  v-model="school"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  :disabled="fieldStatus()"
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">到職日： </label>
              </b-col>
              <b-col>
                <DateField
                  :isabled="fieldStatus() ? false : true"
                  :value.sync="arrivalDate"
                ></DateField>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'user12'"
                  >手機：
                </label>
              </b-col>
              <b-col>
                <b-form-input
                  :id="$route.name + 'user12'"
                  :name="$route.name + 'user12'"
                  placeholder="請輸入手機"
                  v-model="phone"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  @change="checkPhone()"
                  :disabled="fieldStatus()"
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'user13'"
                  >LINE ID：
                </label>
              </b-col>
              <b-col>
                <b-form-input
                  :id="$route.name + 'user13'"
                  :name="$route.name + 'user13'"
                  placeholder="請輸入LINE ID"
                  v-model="lineID"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  :disabled="fieldStatus()"
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'user14'"
                  >Email：
                </label>
              </b-col>
              <b-col>
                <b-form-input
                  :id="$route.name + 'user14'"
                  :name="$route.name + 'user14'"
                  placeholder="請輸入Email"
                  v-model="email"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  @change="checkVerify(4)"
                  :disabled="fieldStatus()"
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">戶籍地址： </label>
              </b-col>
              <b-col>
                <AddressField
                  :addressData.sync="residenceAddress"
                  :disabled="fieldStatus() ? true : false"
                ></AddressField>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">聯絡地址： </label>
              </b-col>
              <b-col>
                <AddressField
                  :addressData="
                    contactAddressCheck.value !== 1
                      ? contactAddress
                      : residenceAddress
                  "
                  :disabled="
                    contactAddressCheck.value == 1 || fieldStatus()
                      ? true
                      : false
                  "
                ></AddressField>

                <div
                  v-for="(item, index) in contactAddressCheck.options"
                  :key="index"
                  class="d-flex mr-1"
                >
                  <b-form-checkbox
                    v-model="contactAddressCheck.value"
                    :value="item.value"
                    :id="contactAddressCheck.name + item.value"
                    style="margin-top: 0.2rem"
                    :disabled="fieldStatus()"
                  >
                  </b-form-checkbox>
                  <label
                    class="fontColor"
                    :for="contactAddressCheck.name + item.value"
                  >
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'user15'"
                  >證書證號：
                </label>
              </b-col>
              <b-col>
                <b-form-input
                  :id="$route.name + 'user15'"
                  :name="$route.name + 'user15'"
                  placeholder="請輸入證書證號"
                  v-model="certificateID"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  :disabled="fieldStatus()"
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="12">
            <b-row>
              <b-col xl="2" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">緊急聯絡人： </label>
              </b-col>
              <b-col>
                <div
                  class="d-flex"
                  v-for="(item, index) in contactList"
                  :key="index"
                  style="margin-bottom: 0.5rem"
                >
                  <b-form-input
                    :name="$route.name + '20'"
                    placeholder="請輸入姓名"
                    v-model="item.name"
                    type="text"
                    class="col-2"
                    style="margin-right: 0.5rem"
                    :class="
                      backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                    "
                    :disabled="fieldStatus()"
                    trim
                  />
                  <b-form-input
                    :name="$route.name + '21'"
                    placeholder="請輸入關係"
                    v-model="item.relation"
                    type="text"
                    class="col-2"
                    style="margin-right: 0.5rem"
                    :class="
                      backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                    "
                    :disabled="fieldStatus()"
                    trim
                  />
                  <b-form-input
                    :name="$route.name + '22'"
                    placeholder="請輸入聯絡電話"
                    v-model="item.phone"
                    type="text"
                    class="col-2"
                    style="margin-right: 0.5rem"
                    :class="
                      backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                    "
                    :disabled="fieldStatus()"
                    trim
                  />
                  <b-form-input
                    :name="$route.name + '23'"
                    placeholder="請輸入聯絡人地址"
                    v-model="item.address"
                    type="text"
                    class=""
                    style="margin-right: 0.5rem"
                    :class="
                      backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                    "
                    :disabled="fieldStatus()"
                    trim
                  />
                  <div class="d-flex align-items-center">
                    <b-button
                      v-show="index == 0"
                      class="btn-icon"
                      variant="outline-success"
                      size="sm"
                      :disabled="fieldStatus()"
                      @click="addList()"
                    >
                      <b-icon icon="plus-lg"></b-icon>
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      class="btn-icon"
                      variant="outline-danger"
                      size="sm"
                      :disabled="fieldStatus()"
                      @click="deleteList(index)"
                    >
                      <b-icon icon="dash-lg"></b-icon>
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="12">
            <b-row>
              <b-col xl="2" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'user16'"
                  >備註：
                </label>
              </b-col>
              <b-col>
                <b-form-textarea
                  :id="$route.name + 'user16'"
                  :name="$route.name + 'user16'"
                  size="lg"
                  v-model="remark"
                  rows="5"
                  placeholder="請輸入詳細內容"
                  :disabled="fieldStatus()"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <b-card :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'">
        <div class="d-flex mb-2">
          <b-button
            :disabled="
              viewSaveType.type == 'read' ||
              viewSaveType.type == 'delete' ||
              sepcialEditePermission()
            "
            @click="editModal"
            variant="info"
            >編輯權限</b-button
          >
        </div>

        <BaseTable
          v-show="table.items.length !== 0"
          :fields="table.fields"
          :items="table.items"
          :currentPage="table.currentPage"
          :totalItems="table.totalItems"
          :perPage="table.perPage"
          :deleteAction="deleteAction"
          :showPagination="false"
        />
      </b-card>

      <div v-show="viewSaveType.type !== 'read'" class="col-12 mt-3">
        <b-button
          v-show="viewSaveType.type !== 'delete'"
          @click="saveAction()"
          class="mr-2"
          variant="primary"
          >確定</b-button
        >
        <b-button @click="backAction()" variant="outline-secondary"
          >返回</b-button
        >
      </div>
    </div>
    <!-- modal -->
    <b-modal
      id="companyUserModal"
      title="編輯權限"
      hide-header-close
      :headerBgVariant="backgroundColor"
      :headerTextVariant="backgroundColor == 'dark' ? 'light' : 'dark'"
      :bodyBgVariant="backgroundColor"
      :bodyTextVariant="backgroundColor == 'dark' ? 'light' : 'dark'"
      :footerBgVariant="backgroundColor"
      :footerTextVariant="backgroundColor == 'dark' ? 'light' : 'dark'"
      centered
      size="lg"
    >
      <div
        :class="backgroundColor == 'dark' ? 'modalTypeDark' : 'modalTypeLight'"
      >
        <b-row class="align-items-center">
          <b-col class="col-12">
            <b-row>
              <b-col lg="2" class="modalTitleCSS col-3">
                <label
                  style="font-size: 20px"
                  :for="$route.name + 'modalUser1'"
                >
                角色名稱：
                </label>
              </b-col>
              <b-col>
                <b-form-select
                  :id="$route.name + 'modalUser1'"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="modalData.positionSelect.value"
                  :options="modalData.positionSelect.options"
                  @change="getPermissionData()"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-show="modalData.groupType !== 0" class="col-12">
            <b-row>
              <b-col lg="2" class="modalTitleCSS col-3">
                <label
                  style="font-size: 20px"
                  :for="$route.name + 'modalUser3'"
                >
                  所屬集團：
                </label>
              </b-col>
              <b-col class="d-flex flex-wrap mt-1">
                <template v-if="modalData.groupType == 1">
                  <div
                    v-for="(item, index) in modalData.groupCheckbox.options"
                    :key="index"
                    class="d-flex mr-4"
                  >
                    <b-form-checkbox
                      v-model="modalData.groupCheckbox.value"
                      :value="item.value"
                      :id="modalData.groupCheckbox.name + item.value"
                      :disabled="item.disabled"
                    >
                    </b-form-checkbox>
                    <label
                      class="fontColor"
                      :for="modalData.groupCheckbox.name + item.value"
                    >
                      {{ item?.text }}</label
                    >
                  </div>
                </template>
                <template v-else>
                  <b-form-select
                    :id="$route.name + 'modalUser3'"
                    :class="
                      backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                    "
                    v-model="modalData.groupSelect.value"
                    :options="modalData.groupSelect.options"
                    @change="getModalCompanyList()"
                  ></b-form-select>
                </template>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-show="modalData.groupType == 2 && modalData.groupSelect.value"
            class="col-12"
          >
            <b-row>
              <b-col lg="2" class="modalTitleCSS col-3">
                <label
                  style="font-size: 20px"
                  :for="$route.name + 'modalUser2'"
                >
                  所屬公司：
                </label>
              </b-col>
              <b-col class="d-flex flex-wrap mt-1">
                <div
                  v-for="(item, index) in modalData.companyCheckbox.options"
                  :key="index"
                  class="d-flex mr-4"
                >
                  <b-form-checkbox
                    v-model="modalData.companyCheckbox.value"
                    :value="item.value"
                    :id="modalData.companyCheckbox.name + item.value"
                    :disabled="item.disabled"
                  >
                  </b-form-checkbox>
                  <label
                    class="fontColor"
                    :for="modalData.companyCheckbox.name + item.value"
                  >
                    {{ item?.text }}</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <template #modal-footer="{ cancel }">
        <b-row>
          <b-button @click="savePermission()" class="mr-2" variant="primary"
            >確定</b-button
          >
          <b-button variant="outline-secondary" @click="cancel">
            返回
          </b-button>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "@/utils/helper";
import BaseTable from "@/components/BaseTable";
import SearchSelect from "@/components/SearchSelect";
import DateField from "@/components/DateField";
import AddressField from "@/components/AddressField";
import usersAPI from "@/apis/usersAPI";
import moment from "moment";
import sharedModuleAPI from "@/apis/sharedModuleAPI";
import permissionAPI from "@/apis/permissionAPI";

export default {
  components: {
    BaseTable,
    SearchSelect,
    DateField,
    AddressField,
  },
  data() {
    return {
      userPremission: "",
      isProcessing: false,
      showTitle: "",
      viewSaveType: {
        userPkID: null,
        userEmpID: null,
        type: "",
      },

      photo: {
        file: null,
        previewUrl: "",
        saveFile: null,
      },
      name: "",
      groupSelect: {
        value: null,
        options: [{ value: null, text: "請選擇集團" }],
      },
      companyName: "",
      companySelect: {
        keyWords: "",
        allList: [],
      },
      departmentSelect: {
        value: null,
        options: [{ value: null, text: "請選擇組別" }],
      },
      positionSelect: {
        value: null,
        options: [{ value: null, text: "請選擇職位" }],
      },
      statusSelect: {
        value: 0,
        options: [],
      },
      genderSelect: {
        value: null,
        options: [{ value: null, text: "請選擇性別" }],
      },
      IDnumber: "",
      IDcheckbox: {
        name: "IDcheckbox",
        value: "",
        options: [{ text: "外國人/華僑", value: 1 }],
      },
      birthDay: "",
      marriageSelect: {
        value: null,
        options: [{ value: null, text: "請選擇婚姻狀態" }],
      },
      experience: null,
      educationSelect: {
        value: null,
        options: [{ value: null, text: "請選擇教育程度" }],
      },
      school: "",
      arrivalDate: "",
      phone: "",
      lineID: "",
      email: "",
      residenceAddress: {
        cityID: null,
        town: {
          ID: null,
          options: [{ value: null, text: "請選擇區域" }],
        },
        info: "",
      },
      contactAddress: {
        cityID: null,
        town: {
          ID: null,
          options: [{ value: null, text: "請選擇區域" }],
        },
        info: "",
      },
      contactAddressCheck: {
        name: "contactAddressCheck",
        value: null,
        options: [{ text: "同戶籍地址", value: 1 }],
      },
      certificateID: "",
      contactList: [],
      remark: "",

      searchData: null,
      table: {
        currentPage: 1,
        perPage: 100,
        totalItems: 0,
        fields: [
          {
            key: "COL0",
            label: "",
            type: "default_btn",
          },
          { key: "COL1", label: "角色名稱" },
          { key: "COL2", label: "所屬" },
        ],
        items: [],
      },
      permissionList: [],

      //modal
      modalData: {
        positionSelect: {
          value: null,
          options: [{ value: null, text: "請選擇群組" }],
        },
        groupType: 0,
        groupCheckbox: {
          name: "groupCheckbox",
          value: [],
          options: [],
        },
        groupSelect: {
          value: null,
          options: [],
        },

        companyCheckbox: {
          name: "companyCheckbox",
          value: [],
          options: [],
        },
      },
    };
  },
  methods: {
    checkPhone() {
      this.phone = this.phone.replace(/\D/g, "");
      if (!this.phone) return;
      let firstTwoChars = this.phone.substring(0, 2);

      if (this.phone.length !== 10 || firstTwoChars !== "09") {
        Toast.fire({
          icon: "warning",
          title: "手機格式有誤，請重新輸入",
        });
        this.phone = "";
      }
    },
    checkIDcheckbox() {
      this.IDnumber = "";
    },
    async checkVerify(type) {
      let ID = "";
      if (type == 1) {
        this.IDnumber = this.IDnumber.toUpperCase();
        ID = this.IDnumber;
        if (this.IDcheckbox.value == 1) {
          this.getUserDataByAccount();
          return;
        }
      } else if (type == 4) {
        ID = this.email;
      }
      if (!ID) return;
      try {
        this.isProcessing = true;

        const response = await sharedModuleAPI.checkVerify({
          code: type,
          id: ID,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          if (type == 1) {
            this.IDnumber = "";
          } else if (type == 4) {
            this.email = "";
          }

          return;
        }

        if (type == 1) {
          this.getUserDataByAccount();
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },

    async getGroupList(type) {
      try {
        this.isProcessing = true;

        const response = await sharedModuleAPI.getGroupList({
          assocID: null,
          corpID: null,
          corpNM: null,
          status: 1,
        });
        console.log("1");

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        console.log("2");

        if (type == 0) {
          for (var idx in response.data.list) {
            this.groupSelect.options.push({
              value: response.data.list[idx].id,
              text: response.data.list[idx].nm,
            });
          }
        } else {
          this.modalData.groupCheckbox.options.splice(0);
          this.modalData.groupSelect.options = [
            { value: null, text: "請選擇集團" },
          ];
          for (var index in response.data.list) {
            this.modalData.groupCheckbox.options.push({
              value: response.data.list[index].id,
              text: response.data.list[index].nm,
              disabled:
                this.modalData.positionSelect.value == 3 &&
                response.data.list[index].corpManagerID
                  ? true
                  : false,
            });
            this.modalData.groupSelect.options.push({
              value: response.data.list[index].id,
              text: response.data.list[index].nm,
            });
          }
        }
        console.log("3");

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async getCompanyList(type) {
      if (type !== 0) {
        this.companySelect.keyWords = "";
        this.companyName = "";
        this.departmentSelect.value = null;
        this.departmentSelect.options = [
          {
            value: null,
            text: "請選擇組別",
          },
        ];
      }
      try {
        this.isProcessing = true;

        const response = await sharedModuleAPI.getCompanyList({
          corpID: this.groupSelect.value,
          companyID: null,
          companyNM: null,
          status: 1,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        this.companySelect.allList.splice(0);
        for (var idx1 in response.data.list) {
          this.companySelect.allList.push({
            value: response.data.list[idx1].id,
            text: response.data.list[idx1].shortNM,
            companyName: response.data.list[idx1].nm,
            groupID: response.data.list[idx1].corpID,
          });
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async getDepartmentList() {
      this.departmentSelect.value = null;
      this.departmentSelect.options = [{ value: null, text: "請選擇組別" }];
      this.companyName = "";
      let taxID = "";
      if (this.companySelect.keyWords !== "") {
        let object = this.companySelect.allList.find(
          (x) => x.text == this.companySelect.keyWords
        );
        taxID = object.value;
        this.companyName = object.companyName;
        this.groupSelect.value = object.groupID;
      } else return;

      try {
        this.isProcessing = true;

        const response = await sharedModuleAPI.getDepartmentList({
          taxID: taxID,
          teamID: null,
          teamNM: null,
          status: 1,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        for (var index in response.data.list) {
          this.departmentSelect.options.push({
            value: response.data.list[index].id,
            text: response.data.list[index].nm,
          });
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async getPermissionList() {
      try {
        this.isProcessing = true;

        const response = await sharedModuleAPI.getPermissionList({
          groupID: null,
          status: 1,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        let list = response.data.list.filter((item) => item.auth == 1);
        for (var index in list) {
          this.modalData.positionSelect.options.push({
            value: list[index].id,
            text: list[index].nm,
          });
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async getPermissionData() {
      this.modalData.groupType = 0;
      this.modalData.groupCheckbox.value.splice(0);
      this.modalData.groupSelect.value = null;
      this.modalData.companyCheckbox.value.splice(0);

      if (this.modalData.positionSelect.value == null) return;
      try {
        this.isProcessing = true;
        let inputData = {
          groupID: this.modalData.positionSelect.value,
        };

        const response = await permissionAPI.getDetailList(inputData);

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        this.renderField(response.data.list[0].gpAuthMapList);

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    renderField(list) {
      let data = list.find(
        (item) => item.progPath == "CompanyManagement"
      )?.haveAuth;
      if (data.search("瀏覽") !== -1) {
        if (data.search("公司負責人") !== -1) {
          this.modalData.groupType = 2;
        } else {
          this.modalData.groupType = 1;
        }
        this.getGroupList();
      } else {
        this.modalData.groupType = 0;
      }
    },
    async getModalCompanyList() {
      if (this.modalData.groupSelect.value == null) return;
      try {
        this.isProcessing = true;

        const response = await sharedModuleAPI.getCompanyList({
          corpID: this.modalData.groupSelect.value,
          companyID: null,
          companyNM: null,
          status: 1,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        this.modalData.companyCheckbox.options.splice(0);
        for (var index in response.data.list) {
          this.modalData.companyCheckbox.options.push({
            value: response.data.list[index].id,
            text: response.data.list[index].nm,
            disabled:
              this.modalData.positionSelect.value == 4 &&
              response.data.list[index].taxManagerID
                ? true
                : false,
          });
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    setPermissionTable() {
      this.table.items.splice(0);

      let array = [];
      for (var index in this.permissionList) {
        let data = this.permissionList[index];
        let belong = "";
        if (data.assocNM) {
          belong = data.assocNM;
        }
        if (data.corpNM) {
          if (belong == "") {
            belong = data.corpNM;
          } else {
            belong = belong + "," + data.corpNM;
          }
        }
        if (data.taxNM) {
          if (belong == "") {
            belong = data.taxNM;
          } else {
            belong = belong + "," + data.taxNM;
          }
        }
        if (data.teamNM) {
          if (belong == "") {
            belong = data.teamNM;
          } else {
            belong = belong + "," + data.teamNM;
          }
        }

        array.push({
          COL0:
            this.viewSaveType.type == "stay" ||
            this.viewSaveType.type == "create" ||
            this.viewSaveType.type == "edit"
              ? "delete"
              : "",
          COL1: data.groupName,
          COL2: belong,
        });
      }
      this.table.items = array;
    },
    editModal() {
      this.modalData.positionSelect.value = null;
      this.modalData.groupType = 0;
      this.modalData.groupCheckbox.value.splice(0);
      this.modalData.groupSelect.value = null;
      this.modalData.companyCheckbox.value.splice(0);
      this.$bvModal.show("companyUserModal");
    },
    deleteAction(field, rowIndex) {
      console.log(field);
      this.$bvModal
        .msgBoxConfirm("確定要刪除該權限？", {
          bodyClass: "checkModalCss",
          headerClass: " border-bottom-0",
          footerClass: "p-2 border-top-0",
          okVariant: "primary",
          okTitle: "確定",
          cancelTitle: "取消",
          cancelVariant: "outline-secondary",
          size: "sm",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.permissionList.splice(rowIndex, 1);
            this.setPermissionTable();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    savePermission() {
      if (!this.modalData.positionSelect.value) {
        Toast.fire({
          icon: "warning",
          title: "請選擇群組",
        });
        return;
      }
      if (this.modalData.groupType !== 0) {
        if (this.modalData.groupType == 1) {
          if (this.modalData.groupCheckbox.value.length == 0) {
            Toast.fire({
              icon: "warning",
              title: "請勾選所屬集團",
            });
            return;
          }
        } else {
          if (
            !this.modalData.groupSelect ||
            this.modalData.companyCheckbox.value.length == 0
          ) {
            Toast.fire({
              icon: "warning",
              title: "請選擇所屬集團與公司",
            });
            return;
          }
        }
      }

      let checkArray = [];
      if (this.modalData.groupType !== 0) {
        if (this.modalData.groupType == 1) {
          for (var idx1 in this.modalData.groupCheckbox.value) {
            let item1 = this.modalData.groupCheckbox.value[idx1];
            let item1NM = this.modalData.groupCheckbox.options.find(
              (x1) => x1.value == item1
            )?.text;
            checkArray.push({
              perPkID: null,
              groupID: this.modalData.positionSelect.value,
              groupName: this.modalData.positionSelect.options.find(
                (a) => a.value == this.modalData.positionSelect.value
              )?.text,
              powerful: 0,
              assocID: null,
              assocNM: null,
              corpID: item1,
              corpNM: item1NM,
              taxID: null,
              taxNM: null,
              teamID: null,
              teamNM: null,
              role: null,
            });
          }
        } else {
          for (var idx2 in this.modalData.companyCheckbox.value) {
            let item2 = this.modalData.companyCheckbox.value[idx2];
            let item2NM = this.modalData.companyCheckbox.options.find(
              (x2) => x2.value == item2
            )?.text;
            checkArray.push({
              perPkID: null,
              groupID: this.modalData.positionSelect.value,
              groupName: this.modalData.positionSelect.options.find(
                (a) => a.value == this.modalData.positionSelect.value
              )?.text,
              powerful: 0,
              assocID: null,
              assocNM: null,
              corpID: this.modalData.groupSelect.value,
              corpNM: this.modalData.groupSelect.options.find(
                (b) => b.value == this.modalData.groupSelect.value
              )?.text,
              taxID: item2,
              taxNM: item2NM,
              teamID: null,
              teamNM: null,
              role: null,
            });
          }
        }
      } else {
        checkArray.push({
          perPkID: null,
          groupID: this.modalData.positionSelect.value,
          groupName: this.modalData.positionSelect.options.find(
            (a) => a.value == this.modalData.positionSelect.value
          )?.text,
          powerful: 0,
          assocID: null,
          assocNM: null,
          corpID: null,
          corpNM: null,
          taxID: null,
          taxNM: null,
          teamID: null,
          teamNM: null,
          role: null,
        });
      }
      for (var index in checkArray) {
        let match = this.permissionList.filter(
          (item) => item.groupID == checkArray[index].groupID
        );
        if (match.length !== 0) {
          let status = true;
          for (var idx in match) {
            if (
              match[idx].assocID == checkArray[index].assocID &&
              match[idx].corpID == checkArray[index].corpID &&
              match[idx].taxID == checkArray[index].taxID &&
              match[idx].teamID == checkArray[index].teamID
            ) {
              status = false;
            }
          }
          if (status) {
            this.permissionList.push(checkArray[index]);
          }
        } else {
          this.permissionList.push(checkArray[index]);
        }
      }
      this.$bvModal.hide("companyUserModal");
      this.setPermissionTable();
    },

    checkViewSaveType() {
      if (this.$route.name == "UsersManagementCreate") {
        this.showTitle = "使用者管理 - 新增使用者";
        this.viewSaveType.type = "create";
      } else {
        this.showTitle = "使用者管理 - 使用者資料維護";
        let partmentInfo = this.$route.params.id.split(".");
        this.viewSaveType.userPkID = partmentInfo[0];
        this.viewSaveType.userEmpID = partmentInfo[1];
        
        if (partmentInfo[2] == "0") {
          this.viewSaveType.type = "read";
        } else if (partmentInfo[2] == "1") {
          this.viewSaveType.type = "edit";
        } else if (partmentInfo[2] == "2") {
          this.viewSaveType.type = "delete";
        } else if (partmentInfo[2] == "3") {
          this.viewSaveType.type = "stay";
        }
      }
      this.getUserData();
      this.getPermissionList();
      // this.getGroupList(0);
    },
    fieldStatus() {
      if (
        this.viewSaveType.type == "read" ||
        this.viewSaveType.type == "delete" ||
        this.viewSaveType.type == "stay"
      ) {
        return true;
      } else {
        return false;
      }
    },
    sepcialEditePermission() {
      let status = this.statusSelect.options.find(
        (x) => x.value == this.statusSelect.value
      )?.text;
      console.log("33sepcialEditePermission", status);
      if (this.viewSaveType.type == "stay" && status !== "在職") {
        return true;
      } else {
        return false;
      }
    },
    backAction() {
      let searchCondition = this.searchCondition;
      searchCondition.status = true;
      this.$store.commit("getsearchCondition", searchCondition);

      this.$router.push({
        name: "UsersManagement",
      });
    },
    async getUserData() {
      try {
        this.isProcessing = true;
        const response = await usersAPI.getUserData({
          accPkID: this.viewSaveType.userPkID,
          empID: this.viewSaveType.userEmpID,
          taxID: null,
        });
        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        let userData = response.data.list[0];
        this.setUserData(userData);

        if (response.data.remark) {
          Toast.fire({
            icon: "warning",
            title: response.data.remark,
          });
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    setUserData(userData) {
      //集團
      for (var idx0 in userData.corpIDList) {
        this.groupSelect.options.push({
          value: userData.corpIDList[idx0].id,
          text: userData.corpIDList[idx0].nm,
        });
      }
      //公司
      for (var idx1 in userData.taxList) {
        this.companySelect.allList.push({
          value: userData.taxList[idx1].id,
          text: userData.taxList[idx1].shortNM,
          companyName: userData.taxList[idx1].nm,
          groupID: userData.taxList[idx1].corpID,
        });
      }
      //組別
      for (var idx2 in userData.teamList) {
        this.departmentSelect.options.push({
          value: userData.teamList[idx2].id,
          text: userData.teamList[idx2].nm,
        });
      }
      //職位
      for (var idx3 in userData.positionList) {
        this.positionSelect.options.push({
          value: userData.positionList[idx3].id,
          text: userData.positionList[idx3].nm,
        });
      }
      //在職狀況
      for (var idx4 in userData.hireStateList) {
        this.statusSelect.options.push({
          value: idx4,
          text: userData.hireStateList[idx4],
        });
      }
      //性別
      for (var idx5 in userData.genderList) {
        this.genderSelect.options.push({
          value: userData.genderList[idx5].id,
          text: userData.genderList[idx5].nm,
        });
      }
      //婚姻
      for (var idx6 in userData.marriageList) {
        this.marriageSelect.options.push({
          value: userData.marriageList[idx6].id,
          text: userData.marriageList[idx6].nm,
        });
      }
      //教育程度
      for (var idx7 in userData.educationLevelList) {
        this.educationSelect.options.push({
          value: userData.educationLevelList[idx7].id,
          text: userData.educationLevelList[idx7].nm,
        });
      }
      if (this.viewSaveType.type !== "create") {
        this.name = userData.empNM;
        this.companySelect.keyWords = this.companySelect.allList.find(
          (x) => x.value == userData.taxID
        )?.text;
        this.companyName = this.companySelect.allList.find(
          (x) => x.value == userData.taxID
        )?.companyName;
        this.departmentSelect.value = userData.teamID;
        this.groupSelect.value = userData.corpID;
        this.positionSelect.value = userData.positionID;

        this.statusSelect.value = this.statusSelect.options.find(
          (item) => item.text == userData.hireState
        )?.value;
        this.genderSelect.value = userData.gender;
        this.IDnumber = userData.nationalID;
        this.IDcheckbox.value = userData.foreigner == 1 ? 1 : 2;
        this.birthDay = userData.birthDate
          ? moment(userData.birthDate).format("YYYY-MM-DD")
          : "";
        this.marriageSelect.value = userData.marriage;
        this.experience = userData.seniority;
        this.educationSelect.value = userData.educationLevel;
        this.school = userData.graduate;
        this.arrivalDate = userData.hireDate
          ? moment(userData.hireDate).format("YYYY-MM-DD")
          : "";
        this.phone = userData.mobile;
        this.lineID = userData.lineID;
        this.email = userData.email;

        this.residenceAddress.cityID = !userData.registeredCityID
          ? null
          : userData.registeredCityID;
        this.residenceAddress.town.ID = !userData.registeredTownID
          ? null
          : userData.registeredTownID;
        this.residenceAddress.info = userData.registeredAddress;
        this.contactAddress.cityID = !userData.currentCityID
          ? null
          : userData.currentCityID;
        this.contactAddress.town.ID = !userData.currentTownID
          ? null
          : userData.currentTownID;
        this.contactAddress.info = userData.currentAddress;
        this.certificateID = userData.certificateID;
        if (userData.emergencyList?.length > 0) {
          this.contactList.splice(0);
          for (var index in userData.emergencyList) {
            let contactData = userData.emergencyList[index];
            this.contactList.push({
              name: contactData.name,
              relation: contactData.relation,
              phone: contactData.tel,
              address: contactData.address,
              pkID: contactData.erPkID,
              empID: contactData.empID,
            });
          }
        }
        if (userData.filePathList?.length > 0) {
          this.photo.saveFile = [userData.filePathList[0]];
          this.photo.previewUrl = userData.filePathList[0].linkPath;
        }
        if (userData.permissionsList?.length > 0) {
          this.permissionList = userData.permissionsList;
          this.setPermissionTable();
        }

        this.remark = userData.memo;
        setTimeout(() => {
          this.contactAddressCheck.value =
            userData.addressCheck == 1 ? 1 : null;
        }, 500);
      }
      if (!this.contactList || this.contactList.length == 0) {
        this.addList();
      }
      this.getCompanyList(0);
    },
    async getUserDataByAccount() {
      if (!this.IDnumber) return;
      try {
        this.isProcessing = true;

        const response = await usersAPI.getUserDataByAccount({
          nationalID: this.IDnumber,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        let msg = response.data.list[0].resultMessage;
        if (msg.search("在職") !== -1 || msg.search("留停") !== -1) {
          Toast.fire({
            icon: "warning",
            title: "此帳號已存在",
          });
          this.IDnumber = "";
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },

    //緊急聯絡人 List
    addList() {
      this.contactList.push({
        name: "",
        relation: "",
        phone: "",
        address: "",
        pkID: null,
        empID: this.viewSaveType.userEmpID,
      });
    },
    deleteList(index) {
      this.contactList.splice(index, 1);
    },

    //photo
    uploadFile() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent("click"));
    },
    fileChange(e) {
      this.photo.previewUrl = null;
      this.photo.file = e.target.files[0];
      const file = e.target.files[0];
      if (!file) return;
      this.photo.previewUrl = URL.createObjectURL(file);
    },

    saveAction() {
      if (!this.name) {
        Toast.fire({
          icon: "warning",
          title: "請輸入姓名",
        });
        return;
      }
      if (!this.IDnumber) {
        Toast.fire({
          icon: "warning",
          title: "請輸入身分證",
        });
        return;
      }
      if (!this.companySelect.keyWords) {
        Toast.fire({
          icon: "warning",
          title: "請選擇公司",
        });
        return;
      }
      if (!this.departmentSelect.value) {
        Toast.fire({
          icon: "warning",
          title: "請選擇組別",
        });
        return;
      }

      let status = this.statusSelect.options.find(
        (x) => x.value == this.statusSelect.value
      )?.text;

      if (status == "在職") {
        if (!this.permissionList || this.permissionList.length == 0) {
          Toast.fire({
            icon: "warning",
            title: "權限不得為空",
          });
          return;
        }
      }

      if (this.photo.file) {
        this.getPathList();
      } else {
        if (this.viewSaveType.type == "create") {
          this.createUserData();
        } else {
          this.editUserData();
        }
      }
    },
    async getPathList() {
      this.photo.saveFile = null;
      try {
        this.isProcessing = true;

        let form = new FormData();
        form.append("ProgNM", "userManagment");
        form.append("Files", this.photo.file);

        const response = await sharedModuleAPI.getPathList(form);

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        this.photo.saveFile = response.data.list[0].filePathList;

        if (this.photo.saveFile.length == 0) {
          Toast.fire({
            icon: "warning",
            title: "資訊錯誤，請通知管理處",
          });
          this.isProcessing = false;
          return;
        }

        if (this.viewSaveType.type == "create") {
          this.createUserData();
        } else {
          this.editUserData();
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async createUserData() {
      try {
        this.isProcessing = true;
        let emergencyList = [];
        for (var index in this.contactList) {
          let contactData = this.contactList[index];
          if (
            contactData.name ||
            contactData.relation ||
            contactData.phone ||
            contactData.address
          ) {
            emergencyList.push({
              erPkID: contactData.pkID,
              empID: contactData.empID,
              name: contactData.name,
              relation: contactData.relation,
              tel: contactData.phone,
              address: contactData.address,
            });
          }
        }
        const response = await usersAPI.createUserData({
          accPkID: null,
          empID: null,
          empNM: this.name,
          englishNM: null,
          taxID: this.companySelect.allList.find(
            (x) => x.text == this.companySelect.keyWords
          )?.value,
          teamID: this.departmentSelect.value,
          corpID: this.groupSelect.value,
          positionID: this.positionSelect.value,
          gender: this.genderSelect.value,
          nationalID: this.IDnumber,
          foreigner: this.IDcheckbox.value == 1 ? 1 : 2,
          birthDate: this.birthDay == "" ? null : this.birthDay,
          marriage: this.marriageSelect.value,
          seniority: this.experience,
          educationLevel: this.educationSelect.value,
          hireDate: this.arrivalDate == "" ? null : this.arrivalDate,
          leaveDate: null,
          hireState: this.statusSelect.options.find(
            (x) => x.value == this.statusSelect.value
          )?.text,
          lineID: this.lineID,
          registeredCityID: this.residenceAddress.cityID,
          registeredTownID: this.residenceAddress.town.ID,
          registeredAddress: this.residenceAddress.info,
          graduate: this.school,
          mobile: this.phone,
          email: this.email,
          addressCheck: this.contactAddressCheck.value == 1 ? 1 : 2,
          currentCityID:
            this.contactAddressCheck.value == 1
              ? this.residenceAddress.cityID
              : this.contactAddress.cityID,
          currentTownID:
            this.contactAddressCheck.value == 1
              ? this.residenceAddress.town.ID
              : this.contactAddress.town.ID,
          currentAddress:
            this.contactAddressCheck.value == 1
              ? this.residenceAddress.info
              : this.contactAddress.info,
          certificateID: this.certificateID,
          memo: this.remark,
          emergencyList: emergencyList?.length > 0 ? emergencyList : null,
          filePathList: this.photo.saveFile,
          permissionsList: this.permissionList,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        Toast.fire({
          icon: "success",
          title: "新增成功",
        });
        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async editUserData() {
      try {
        this.isProcessing = true;
        let emergencyList = [];
        for (var index in this.contactList) {
          let contactData = this.contactList[index];
          if (
            contactData.name ||
            contactData.relation ||
            contactData.phone ||
            contactData.address
          ) {
            emergencyList.push({
              erPkID: contactData.pkID,
              empID: contactData.empID,
              name: contactData.name,
              relation: contactData.relation,
              tel: contactData.phone,
              address: contactData.address,
            });
          }
        }
        const response = await usersAPI.editUserData({
          accPkID: Number(this.viewSaveType.userPkID),
          empID: Number(this.viewSaveType.userEmpID),
          empNM: this.name,
          englishNM: null,
          taxID: this.companySelect.allList.find(
            (x) => x.text == this.companySelect.keyWords
          )?.value,
          teamID: this.departmentSelect.value,
          corpID: this.groupSelect.value,
          positionID: this.positionSelect.value,
          gender: this.genderSelect.value,
          nationalID: this.IDnumber,
          foreigner: this.IDcheckbox.value == 1 ? 1 : 2,
          birthDate: this.birthDay == "" ? null : this.birthDay,
          marriage: this.marriageSelect.value,
          seniority: this.experience == null ? null : Number(this.experience),
          educationLevel: this.educationSelect.value,
          hireDate: this.arrivalDate == "" ? null : this.arrivalDate,
          leaveDate: null,
          hireState: this.statusSelect.options.find(
            (x) => x.value == this.statusSelect.value
          )?.text,
          lineID: this.lineID,
          registeredCityID: this.residenceAddress.cityID,
          registeredTownID: this.residenceAddress.town.ID,
          registeredAddress: this.residenceAddress.info,
          graduate: this.school,
          mobile: this.phone,
          email: this.email,
          addressCheck: this.contactAddressCheck.value == 1 ? 1 : 2,
          currentCityID:
            this.contactAddressCheck.value == 1
              ? this.residenceAddress.cityID
              : this.contactAddress.cityID,
          currentTownID:
            this.contactAddressCheck.value == 1
              ? this.residenceAddress.town.ID
              : this.contactAddress.town.ID,
          currentAddress:
            this.contactAddressCheck.value == 1
              ? this.residenceAddress.info
              : this.contactAddress.info,
          certificateID: this.certificateID,
          memo: this.remark,
          emergencyList: emergencyList?.length > 0 ? emergencyList : null,
          filePathList: this.photo.saveFile,
          permissionsList: this.permissionList,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        Toast.fire({
          icon: "success",
          title: "修改成功",
        });
        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
  },
  watch: {},
  computed: {
    ...mapState([
      "backgroundColor",
      "currentUser",
      "currentRoute",
      "searchCondition",
    ]),
  },

  created() {
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;
    console.log("this.userPremission", this.userPremission);
    this.checkViewSaveType();
  },
};
</script>
<style scoped>
</style>