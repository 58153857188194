var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar fixed-top navbar-light bg-light shadowBox",class:_vm.backgroundColor == 'dark'
      ? 'navbar-dark bg-dark shadowBoxDark'
      : 'navbar-light bg-light shadowBoxLight'},[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn",staticStyle:{"background-color":"transparent","border-color":"transparent"},attrs:{"type":"button","disabled":_vm.windowWidth <= 992},on:{"click":function($event){return _vm.setSidebarType()}}},[_c('span',{staticClass:"navbar-toggler-icon"})]),(_vm.sidebarType == '18')?_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{class:_vm.backgroundColor == 'dark' ? 'fontColorDark' : 'fontColorLight',staticStyle:{"font-size":"1.5rem"}},[_vm._v("CTMS")])]):_vm._e()]),_c('div',{staticClass:"d-flex",staticStyle:{"position":"absolute","width":"100rem"},style:(_vm.sidebarType == '18' ? 'left:17rem' : 'left:4rem')},[_c('div',{staticClass:"d-flex"},[_c('div',{class:_vm.backgroundColor == 'dark' ? 'fontColorDark' : 'fontColorLight',staticStyle:{"font-size":"1.6rem"}},[_vm._v(" "+_vm._s(_vm.currentRouteTitle)+" ")]),_c('div',{staticClass:"d-flex align-items-end ml-3 mb-1"},[_c('i',{staticClass:"fontColorGroup"},[_vm._v(_vm._s(_vm.category))]),_c('i',{class:_vm.backgroundColor == 'dark' ? 'fontColorDark' : 'fontColorLight',staticStyle:{"margin-right":"0.5rem","margin-left":"0.5rem"}},[_vm._v(">")]),_c('i',{class:_vm.sTitle1 ? 'fontColorGroup' : 'fontColorLight'},[_vm._v(_vm._s(_vm.title))]),(_vm.sTitle1)?[_c('i',{class:_vm.backgroundColor == 'dark'
                    ? 'fontColorDark'
                    : 'fontColorLight',staticStyle:{"margin-right":"0.5rem","margin-left":"0.5rem"}},[_vm._v(">")]),_c('i',{class:_vm.sTitle2 ? 'fontColorGroup' : 'fontColorLight'},[_vm._v(_vm._s(_vm.sTitle1))])]:_vm._e(),(_vm.sTitle2)?[_c('i',{class:_vm.backgroundColor == 'dark'
                    ? 'fontColorDark'
                    : 'fontColorLight',staticStyle:{"margin-right":"0.5rem","margin-left":"0.5rem"}},[_vm._v(">")]),_c('i',{class:_vm.spTitle ? 'fontColorGroup' : 'fontColorLight'},[_vm._v(_vm._s(_vm.sTitle2))])]:_vm._e(),(_vm.spTitle)?[_c('i',{class:_vm.backgroundColor == 'dark'
                    ? 'fontColorDark'
                    : 'fontColorLight',staticStyle:{"margin-right":"0.5rem","margin-left":"0.5rem"}},[_vm._v(">")]),_c('i',{class:_vm.backgroundColor == 'dark'
                    ? 'fontColorDark'
                    : 'fontColorLight'},[_vm._v(_vm._s(_vm.spTitle))])]:_vm._e()],2)])])]),_c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"ml-auto d-flex align-items-center"},[_c('div',{staticClass:"mr-3"},[_c('b-form-select',{class:_vm.backgroundColor == 'dark' ? 'text-light bg-secondary' : '',attrs:{"options":_vm.permissionSelect.options},on:{"change":function($event){return _vm.selectPermission()}},model:{value:(_vm.permissionSelect.value),callback:function ($$v) {_vm.$set(_vm.permissionSelect, "value", $$v)},expression:"permissionSelect.value"}})],1),_c('div',{class:_vm.backgroundColor == 'dark' ? 'fontColorDark' : 'fontColorName',staticStyle:{"font-size":"1.5rem","cursor":"pointer"}},[_vm._v(" "+_vm._s(_vm.currentUser.name || "使用者")+" ")])]),_c('div',[_c('button',{staticClass:"btn my-2 my-sm-0",staticStyle:{"background-color":"transparent","border-color":"transparent"},attrs:{"type":"button"},on:{"click":function($event){return _vm.showuseroption()}}},[_c('i',{staticClass:"fa-solid fa-user",class:_vm.backgroundColor == 'dark' ? 'fontColorDark' : 'fontColorName',staticStyle:{"font-size":"20px","cursor":"pointer"}})])]),(_vm.useroption)?_c('div',{staticClass:"useroption",on:{"mouseleave":function($event){return _vm.closeuseroption()}}},[_c('ul',{staticStyle:{"padding":"0","margin":"0.5rem auto"}},[_c('li',{on:{"click":function($event){return _vm.editPasswordPage()}}},[_vm._v("修改密碼")]),_c('li',{on:{"click":function($event){return _vm.logout()}}},[_vm._v("登出")])])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }