<template>
  <div style="width: 98%">
    <!-- card1 -->
    <b-card
      class="mb-3"
      :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
    >
      <div class="d-flex justify-content-between mb-3">
        <b-button variant="primary">建物謄本</b-button>
        <h1 style="margin: 0; font-size: 2rem">土地／建物謄本明細輸入</h1>
        <label></label>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >委託編號：
          </label>
        </div>
        <div class="d-flex justify-content-end">
          <label  class="fontCSS"
            >{{ referenceNumber }}
          </label>
        </div>
        <div class="d-flex justify-content-end ml-5">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '1'"
            >銷售編號：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '1'"
          :name="$route.name + '1'"
          placeholder="請輸入銷售編號"
          v-model="sellNumber"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex justify-content-end ml-5">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '2'"
            >物件狀態：
          </label>
        </div>
        <b-form-select
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          :id="$route.name + '2'"
          v-model="objectStatus.value"
          :options="objectStatus.options"
          style="width: 15rem"
        ></b-form-select>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >門牌：
          </label>
        </div>
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS"
            >{{ address }}
          </label>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :for="$route.name + '3'"
            >案名：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '3'"
          :name="$route.name + '3'"
          placeholder="請輸入案名"
          v-model="objectName"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 30rem"
          trim
        />
      </div>
      <div
        class="d-flex"
        v-for="(item, index) in this.relevantParties"
        :key="index"
      >
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS mr-5"
            >{{
              item.type == "Principal"
                ? "委託人身分證字號"
                : "代理人身分證字號"
            }}：{{ item.IDnumber }}
          </label>
          <label style="white-space: nowrap" class="fontCSS"
            >{{ item.type == "Principal" ? "委託人姓名" : "代理人姓名" }}：{{
              item.name
            }}
          </label>
          <b-button class="ml-5" v-if="index == 0" variant="outline-primary"
            >客戶資料編修</b-button
          >
        </div>
      </div>
    </b-card>
    <!-- card2 -->
    <b-card
      class="mb-3"
      :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
    >
      <div class="d-flex justify-content-between mb-3">
        <h1 style="margin: 0; font-size: 2rem">土地標示部</h1>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >土地所有權人：
          </label>
        </div>
    
        <div class="d-flex justify-content-end">
          <label class="fontCSS">共</label>
          <b-form-input
            placeholder=""
            class=""
            style="text-align: end; width: 5rem"
            v-model="landOwner"
            type="number"
            :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
            disabled
            trim
          />
          <label class="fontCSS">人</label>
          <b-button class="btn-icon ml-2" variant="outline-info">
            <!-- <b-icon icon="plus-lg"></b-icon> -->
            所有權人編輯
          </b-button>
        </div>

        <div class="d-flex justify-content-end ml-5">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '4'"
            >所有權部登記原因：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '4'"
          :name="$route.name + '4'"
          v-model="ownerRegister"
          placeholder="請輸入坪數"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />

        <div class="d-flex justify-content-end ml-5">
          <label style="white-space: nowrap" class="fontCSS"
            >預估增值稅：
          </label>
        </div>
        <b-form-input
          :name="$route.name + 'vat1'"
          placeholder="(一般)"
          v-model="VAT1"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          class="mr-1"
          trim
        />
        <b-form-input
          :name="$route.name + 'vat1'"
          placeholder="(自用)"
          type="text"
          v-model="VAT2"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >土地資料：
          </label>
        </div>

        <div class="d-flex justify-content-end">
          <b-button class="btn-icon ml-2" variant="primary">
            建物謄本
          </b-button>
        </div>

        <div class="d-flex justify-content-end ml-5">
          <label style="white-space: nowrap" class="fontCSS"
            >基地持分面積(坪)： {{ area1 }} 坪
          </label>
        </div>

        <div class="d-flex justify-content-end ml-5">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '5'"
            >基地土地面積(坪)：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '5'"
          :name="$route.name + '5'"
          placeholder="請輸入坪數"
          v-model="area2"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">坪 </label>
        </div>
      </div>
      <BaseTable
        class="mt-2"
        :fields="landTable.fields"
        :items="landTable.items"
        :currentPage="landTable.currentPage"
        :totalItems="landTable.totalItems"
        :perPage="landTable.perPage"
        :showPagination="false"
      />
    </b-card>
    <!-- card3 -->
    <b-card
      class="mb-3"
      :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
    >
      <div class="d-flex justify-content-between mb-3">
        <h1 style="margin: 0; font-size: 2rem">建物標示部</h1>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >建物所有權人：
          </label>
        </div>
    
        <div class="d-flex justify-content-end">
          <label class="fontCSS">共</label>
          <b-form-input
            placeholder=""
            class=""
            style="text-align: end; width: 5rem"
            v-model="objectOwner"
            type="number"
            :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
            disabled
            trim
          />
          <label class="fontCSS">人</label>
        </div>

        <div class="d-flex justify-content-end ml-5">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '6'"
            >總登記坪數：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '6'"
          :name="$route.name + '6'"
          v-model="registerArea"
          placeholder="請輸入坪數"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">坪 </label>
        </div>

        <div class="d-flex justify-content-end ml-5">
          <label style="white-space: nowrap" class="fontCSS">增/違建： </label>
        </div>
        <div
          v-for="(item, index) in this.illegalItem.options"
          :key="index"
          class="d-flex"
        >
          <b-form-radio
            class="mt-1"
            v-model="illegalItem.value"
            :value="item.value"
            :id="illegalItem.name + item.value"
          >
          </b-form-radio>
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="illegalItem.name + item.value"
          >
            {{ item.text }}</label
          >
        </div>
        <div class="d-flex justify-content-end ml-3">
          <label style="white-space: nowrap" class="fontCSS">位置 </label>
        </div>
        <b-form-input
          :name="$route.name + 'illegalItem1'"
          placeholder=""
          v-model="illegalItem.position"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          class="mr-1"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">約 </label>
        </div>
        <b-form-input
          :name="$route.name + 'illegalItem2'"
          placeholder=""
          type="text"
          v-model="illegalItem.area"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">坪 </label>
        </div>
      </div>
      <BaseTable
        class="mt-2"
        :fields="objectTable.fields"
        :items="objectTable.items"
        :currentPage="objectTable.currentPage"
        :totalItems="objectTable.totalItems"
        :perPage="objectTable.perPage"
        :showPagination="false"
      />
      <div class="d-flex mt-2">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >建號：
          </label>
        </div>
        <div class="d-flex justify-content-end">
          <b-form-input
            placeholder=""
            class=""
            style="text-align: end; width: 10rem"
            v-model="objectNumber.input1"
            type="number"
            :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
            trim
          />
          <label class="fontCSS mr-1 ml-1">-</label>
          <b-form-input
            placeholder=""
            class=""
            style="text-align: end; width: 10rem"
            v-model="objectNumber.input1"
            type="number"
            :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
            trim
          />
        </div>
        <div class="d-flex justify-content-end ml-5">
          <label style="white-space: nowrap" class="fontCSS"
            >出賣權利範圍：
          </label>
        </div>
        <div class="d-flex justify-content-end">
          <b-form-input
            placeholder=""
            class=""
            style="text-align: end; width: 10rem"
            v-model="objectSellRatio.input1"
            type="number"
            :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
            trim
          />
          <label class="fontCSS mr-1 ml-1">/</label>
          <b-form-input
            placeholder=""
            class=""
            style="text-align: end; width: 10rem"
            v-model="objectSellRatio.input1"
            type="number"
            :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
            trim
          />
        </div>
      </div>
      <!-- info1 -->
      <div v-for="(item, idx) in this.info1" :key="idx" class="underLine mb-1">
        <div class="d-flex">
          <div class="d-flex justify-content-end">
            <label
              style="white-space: nowrap; width: 10rem; text-align: end"
              class="fontCSS"
              >層數：
            </label>
          </div>
          <div class="d-flex">
            <div class="d-flex align-items-center mr-2">
              <b-button
                v-show="idx == 0"
                class="btn-icon"
                variant="outline-success"
                size="sm"
              >
                <b-icon icon="plus-lg"></b-icon>
              </b-button>
              <b-button
                v-show="idx !== 0"
                class="btn-icon"
                variant="outline-danger"
                size="sm"
              >
                <b-icon icon="dash-lg"></b-icon>
              </b-button>
            </div>
            <div class="d-flex">
              <b-form-input
                placeholder="樓層"
                class=""
                style="text-align: end; width: 10rem"
                v-model="item.floor.input1"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
            </div>

            <div class="d-flex">
              <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
                >面積
              </label>
              <b-form-input
                placeholder=""
                class=""
                style="text-align: end; width: 8rem"
                v-model="item.floor.input2"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
            </div>
            <div class="d-flex">
              <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
                >持分
              </label>
              <b-form-input
                placeholder=""
                class=""
                style="text-align: end; width: 5rem"
                v-model="item.floor.input3"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
              <label style="white-space: nowrap" class="fontCSS mr-1 ml-1"
                >/
              </label>
              <b-form-input
                placeholder=""
                class=""
                style="text-align: end; width: 5rem"
                v-model="item.floor.input4"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
            </div>
            <div class="d-flex">
              <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
                >面積小計
              </label>
              <b-form-input
                placeholder=""
                class=""
                style="text-align: end; width: 8rem"
                v-model="item.floor.input5"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
            </div>
            <div class="d-flex">
              <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
                >平方公尺
              </label>
              <b-form-input
                placeholder=""
                class=""
                style="text-align: end; width: 8rem"
                v-model="item.floor.input6"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
            </div>
          </div>
        </div>
        <div
          v-for="(item1, index) in item.accessory"
          :key="index"
          class="d-flex"
        >
          <div class="d-flex justify-content-end">
            <label
              style="white-space: nowrap; width: 10rem; text-align: end"
              class="fontCSS"
              :style="index !== 0 ? 'opacity:0' : ''"
              >附屬建物：
            </label>
          </div>
          <div class="d-flex">
            <div class="d-flex align-items-center mr-2">
              <b-button
                v-show="index == 0"
                class="btn-icon"
                variant="outline-success"
                size="sm"
              >
                <b-icon icon="plus-lg"></b-icon>
              </b-button>
              <b-button
                v-show="index !== 0"
                class="btn-icon"
                variant="outline-danger"
                size="sm"
              >
                <b-icon icon="dash-lg"></b-icon>
              </b-button>
            </div>
            <div class="d-flex">
              <b-form-input
                placeholder="請輸入附屬建物"
                class=""
                style="text-align: end; width: 10rem"
                v-model="item1.input1"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
            </div>

            <div class="d-flex">
              <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
                >面積
              </label>
              <b-form-input
                placeholder=""
                class=""
                style="text-align: end; width: 8rem"
                v-model="item1.input2"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
            </div>
            <div class="d-flex">
              <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
                >持分
              </label>
              <b-form-input
                placeholder=""
                class=""
                style="text-align: end; width: 5rem"
                v-model="item1.input3"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
              <label style="white-space: nowrap" class="fontCSS mr-1 ml-1"
                >/
              </label>
              <b-form-input
                placeholder=""
                class=""
                style="text-align: end; width: 5rem"
                v-model="item1.input4"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
            </div>
            <div class="d-flex">
              <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
                >面積小計
              </label>
              <b-form-input
                placeholder=""
                class=""
                style="text-align: end; width: 8rem"
                v-model="item1.input5"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
            </div>
            <div class="d-flex">
              <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
                >平方公尺
              </label>
              <b-form-input
                placeholder=""
                class=""
                style="text-align: end; width: 8rem"
                v-model="item1.input6"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                trim
              />
            </div>
          </div>
        </div>
      </div>
      <!-- info2 -->
      <div
        v-for="(item, idx) in this.info2"
        :key="'info2' + idx"
        class="d-flex"
      >
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :style="idx !== 0 ? 'opacity:0' : ''"
            >共同使用部份：
          </label>
        </div>
        <div class="d-flex">
          <div class="d-flex align-items-center mr-2">
            <b-button
              v-show="idx == 0"
              class="btn-icon"
              variant="outline-success"
              size="sm"
            >
              <b-icon icon="plus-lg"></b-icon>
            </b-button>
            <b-button
              v-show="idx !== 0"
              class="btn-icon"
              variant="outline-danger"
              size="sm"
            >
              <b-icon icon="dash-lg"></b-icon>
            </b-button>
          </div>
          <div class="d-flex">
            <b-form-input
              placeholder="請輸入附屬建物"
              class=""
              style="text-align: end; width: 10rem"
              v-model="item.input1"
              type="number"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
          </div>

          <div class="d-flex">
            <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
              >面積
            </label>
            <b-form-input
              placeholder=""
              class=""
              style="text-align: end; width: 8rem"
              v-model="item.input2"
              type="number"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
          </div>
          <div class="d-flex">
            <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
              >持分
            </label>
            <b-form-input
              placeholder=""
              class=""
              style="text-align: end; width: 5rem"
              v-model="item.input3"
              type="number"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
            <label style="white-space: nowrap" class="fontCSS mr-1 ml-1"
              >/
            </label>
            <b-form-input
              placeholder=""
              class=""
              style="text-align: end; width: 5rem"
              v-model="item.input4"
              type="number"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
          </div>
          <div class="d-flex">
            <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
              >面積小計
            </label>
            <b-form-input
              placeholder=""
              class=""
              style="text-align: end; width: 8rem"
              v-model="item.input5"
              type="number"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
          </div>
          <div class="d-flex">
            <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
              >平方公尺
            </label>
            <b-form-input
              placeholder=""
              class=""
              style="text-align: end; width: 8rem"
              v-model="item.input6"
              type="number"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
          </div>
        </div>
      </div>
      <!-- info3 -->
      <div
        v-for="(item, idx) in this.info2"
        :key="'info3' + idx"
        class="d-flex"
      >
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :style="idx !== 0 ? 'opacity:0' : ''"
            >其他獨立權狀：
          </label>
        </div>
        <div class="d-flex">
          <div class="d-flex align-items-center mr-2">
            <b-button
              v-show="idx == 0"
              class="btn-icon"
              variant="outline-success"
              size="sm"
            >
              <b-icon icon="plus-lg"></b-icon>
            </b-button>
            <b-button
              v-show="idx !== 0"
              class="btn-icon"
              variant="outline-danger"
              size="sm"
            >
              <b-icon icon="dash-lg"></b-icon>
            </b-button>
          </div>
          <div class="d-flex">
            <b-form-input
              placeholder="請輸入附屬建物"
              class=""
              style="text-align: end; width: 10rem"
              v-model="item.input1"
              type="number"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
          </div>

          <div class="d-flex">
            <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
              >面積
            </label>
            <b-form-input
              placeholder=""
              class=""
              style="text-align: end; width: 8rem"
              v-model="item.input2"
              type="number"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
          </div>
          <div class="d-flex">
            <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
              >持分
            </label>
            <b-form-input
              placeholder=""
              class=""
              style="text-align: end; width: 5rem"
              v-model="item.input3"
              type="number"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
            <label style="white-space: nowrap" class="fontCSS mr-1 ml-1"
              >/
            </label>
            <b-form-input
              placeholder=""
              class=""
              style="text-align: end; width: 5rem"
              v-model="item.input4"
              type="number"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
          </div>
          <div class="d-flex">
            <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
              >面積小計
            </label>
            <b-form-input
              placeholder=""
              class=""
              style="text-align: end; width: 8rem"
              v-model="item.input5"
              type="number"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
          </div>
          <div class="d-flex">
            <label style="white-space: nowrap" class="fontCSS ml-3 mr-1"
              >平方公尺
            </label>
            <b-form-input
              placeholder=""
              class=""
              style="text-align: end; width: 8rem"
              v-model="item.input6"
              type="number"
              :class="
                backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
              "
              trim
            />
          </div>
        </div>
      </div>
    </b-card>
    <!-- card4 -->
    <b-card
      class="mb-3"
      :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
    >
      <div class="d-flex justify-content-between mb-3">
        <h1 style="margin: 0; font-size: 2rem">車位標示</h1>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >車位資料：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingSpot.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingSpot.value"
            :value="item.value"
            :id="parkingSpot.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingSpot.name + item.value">
            {{ item.text }}</label
          >
        </div>
        <div class="d-flex justify-content-end ml-3 mr-1">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '7'"
            >車位面積
          </label>
        </div>
        <b-form-input
          :id="$route.name + '7'"
          :name="$route.name + '7'"
          placeholder="請輸入車位面積"
          v-model="parkingSpot.area"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex justify-content-end ml-1">
          <label style="white-space: nowrap" class="fontCSS">平方公尺 </label>
        </div>

        <div class="d-flex justify-content-end ml-5">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '2'"
            >車位來源：
          </label>
        </div>
        <div
          v-for="(item, index) in this.parkingSource.options"
          :key="index"
          class="d-flex mr-3"
        >
          <b-form-radio
            class="mt-1"
            v-model="parkingSource.value"
            :value="item.value"
            :id="parkingSource.name + item.value"
          >
          </b-form-radio>
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="parkingSource.name + item.value"
          >
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :for="$route.name + '8'"
            >車位管理費：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '8'"
          :name="$route.name + '8'"
          placeholder="請輸入管理費"
          v-model="parkingFee.input"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex mr-3 ml-2">
          <b-form-checkbox
            class="mt-1"
            v-model="parkingFee.checkBox"
            id="parkingFeeCheckBox"
          >
          </b-form-checkbox>
          <label
            style="white-space: nowrap"
            class="fontCSS"
            for="parkingFeeCheckBox"
          >
            含在管理費</label
          >
        </div>
        <div
          v-for="(item, index) in parkingFee.type.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingFee.type.value"
            :value="item.value"
            :id="parkingFee.type.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingFee.type.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :for="$route.name + '9'"
            >車位樓層：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '9'"
          :name="$route.name + '9'"
          placeholder="請輸入樓層"
          v-model="parkingFloor.floor"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end ml-3">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '10'"
            >編號
          </label>
        </div>
        <b-form-input
          :id="$route.name + '10'"
          :name="$route.name + '10'"
          placeholder="請輸入編號"
          v-model="parkingFloor.number"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >車位尺寸：
          </label>
        </div>

        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '11'"
            >長
          </label>
        </div>
        <b-form-input
          :id="$route.name + '11'"
          :name="$route.name + '11'"
          placeholder=""
          v-model="parkingSize.long"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">公尺 </label>
        </div>

        <div class="d-flex justify-content-end ml-3">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '12'"
            >寬
          </label>
        </div>
        <b-form-input
          :id="$route.name + '12'"
          :name="$route.name + '12'"
          placeholder=""
          v-model="parkingSize.width"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">公尺 </label>
        </div>

        <div class="d-flex justify-content-end ml-3">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '13'"
            >淨高
          </label>
        </div>
        <b-form-input
          :id="$route.name + '13'"
          :name="$route.name + '13'"
          placeholder=""
          v-model="parkingSize.height"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">公尺 </label>
        </div>

        <div class="d-flex justify-content-end ml-3">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '14'"
            >機械車位可乘載之重量
          </label>
        </div>
        <b-form-input
          :id="$route.name + '14'"
          :name="$route.name + '14'"
          placeholder=""
          v-model="parkingSize.weight"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS">公斤 </label>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >車位形式：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingType.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingType.value"
            :value="item.value"
            :id="parkingType.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingType.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >車位性質：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingProperty.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingProperty.value"
            :value="item.value"
            :id="parkingProperty.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingProperty.name + item.value">
            {{ item.text }}</label
          >
        </div>
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap"
            class="fontCSS mr-1"
            :for="$route.name + '15'"
            >說明
          </label>
        </div>
        <b-form-input
          :id="$route.name + '15'"
          :name="$route.name + '15'"
          placeholder=""
          v-model="parkingProperty.memo"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 20rem"
          trim
        />
      </div>
      <div
        v-for="(item, index) in parkingOwnership.options"
        :key="index"
        class="d-flex"
      >
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :style="index !== 0 ? 'opacity:0' : ''"
            >車位形式：
          </label>
        </div>
        <div class="d-flex mr-1">
          <b-form-checkbox
            class="mt-1"
            v-model="parkingOwnership.value"
            :value="item.value"
            :id="parkingOwnership.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingOwnership.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >使用權：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingUsageRights.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingUsageRights.value"
            :value="item.value"
            :id="parkingUsageRights.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingUsageRights.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >使用狀況：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingUsageStatus.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingUsageStatus.value"
            :value="item.value"
            :id="parkingUsageStatus.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingUsageStatus.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >使用約定：
          </label>
        </div>
        <div
          v-for="(item, index) in parkingUsagePromise.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="parkingUsagePromise.value"
            :value="item.value"
            :id="parkingUsagePromise.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="parkingUsagePromise.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
    </b-card>
    <!-- card5 -->
    <b-card
      class="mb-3"
      :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
    >
      <div class="d-flex justify-content-between mb-3">
        <h1 style="margin: 0; font-size: 2rem">他項權利</h1>
      </div>

      <div class="d-flex">
        <div class="d-flex justify-content-end mr-5">
          <b-button class="btn-icon ml-2" variant="primary">
            新增他項權利
          </b-button>
        </div>
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS"
            >小計：{{ encumbranceFee }} 萬元
          </label>
        </div>
      </div>
      <BaseTable
        class="mt-2"
        :fields="encumbranceTable.fields"
        :items="encumbranceTable.items"
        :currentPage="encumbranceTable.currentPage"
        :totalItems="encumbranceTable.totalItems"
        :perPage="encumbranceTable.perPage"
        :showPagination="false"
      />
    </b-card>
    <!-- card6 -->
    <b-card
      class="mb-3"
      :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
    >
      <div class="d-flex justify-content-between mb-3">
        <h1 style="margin: 0; font-size: 2rem">承辦人員分配</h1>
      </div>

      <div class="d-flex">
        <div class="d-flex justify-content-end mr-5">
          <b-button class="btn-icon ml-2" variant="primary">
            新增承辦人員
          </b-button>
        </div>
        <div class="d-flex justify-content-end">
          <label style="white-space: nowrap" class="fontCSS"
            >小計：{{ contractingOfficerRatio }} ％
          </label>
        </div>
      </div>
      <BaseTable
        class="mt-2"
        :fields="contractingOfficerTable.fields"
        :items="contractingOfficerTable.items"
        :currentPage="contractingOfficerTable.currentPage"
        :totalItems="contractingOfficerTable.totalItems"
        :perPage="contractingOfficerTable.perPage"
        :showPagination="false"
      />
    </b-card>
    <!-- card7 -->
    <b-card
      class="mb-3"
      :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
    >
      <div class="d-flex justify-content-between mb-3">
        <h1 style="margin: 0; font-size: 2rem">委售資料</h1>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :for="$route.name + '16'"
            >刊登價格：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '16'"
          :name="$route.name + '16'"
          placeholder="請輸入價格"
          v-model="consignmentSale"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 10rem"
          trim
        />
        <div class="d-flex justify-content-end ml-1 mr-3">
          <label style="white-space: nowrap" class="fontCSS">萬元 </label>
        </div>

        <div
          v-for="(item, index) in this.consignmentSaleParking.options"
          :key="index"
          class="d-flex mr-2"
        >
          <b-form-radio
            class="mt-1"
            v-model="consignmentSaleParking.value"
            :value="item.value"
            :id="consignmentSaleParking.name + item.value"
          >
          </b-form-radio>
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="consignmentSaleParking.name + item.value"
          >
            {{ item.text }}</label
          >
        </div>
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="$route.name + '17'"
            >車位
          </label>
        </div>
        <b-form-input
          :id="$route.name + '17'"
          :name="$route.name + '17'"
          placeholder="請輸入金額"
          v-model="consignmentSaleParking.cost"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex justify-content-end ml-1">
          <label style="white-space: nowrap" class="fontCSS">萬元 </label>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >賣方接受：
          </label>
        </div>
        <div
          v-for="(item, index) in sellerCondition.options"
          :key="index"
          class="d-flex mr-1"
        >
          <b-form-checkbox
            class="mt-1"
            v-model="sellerCondition.value"
            :value="item.value"
            :id="sellerCondition.name + item.value"
          >
          </b-form-checkbox>
          <label class="fontCSS" :for="sellerCondition.name + item.value">
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >承作安全交易：
          </label>
        </div>
        <div
          v-for="(item, index) in this.secureTransaction.options"
          :key="index"
          class="d-flex mr-2"
        >
          <b-form-radio
            class="mt-1"
            v-model="secureTransaction.value"
            :value="item.value"
            :id="secureTransaction.name + item.value"
          >
          </b-form-radio>
          <label
            style="white-space: nowrap"
            class="fontCSS"
            :for="secureTransaction.name + item.value"
          >
            {{ item.text }}</label
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :for="$route.name + '18'"
            >賣方服務費：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '18'"
          :name="$route.name + '18'"
          placeholder=""
          v-model="sellerFee"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex justify-content-end ml-2">
          <label style="white-space: nowrap" class="fontCSS">% </label>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :for="$route.name + '19'"
            >買方自備款：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '19'"
          :name="$route.name + '19'"
          placeholder=""
          v-model="buyerOwnFee"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex justify-content-end ml-2">
          <label style="white-space: nowrap" class="fontCSS">% </label>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            :for="$route.name + '20'"
            >跨店銷售比：
          </label>
        </div>
        <b-form-input
          :id="$route.name + '20'"
          :name="$route.name + '20'"
          placeholder=""
          v-model="salesRatio"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex justify-content-end ml-2">
          <label style="white-space: nowrap" class="fontCSS">% </label>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >付款方式：
          </label>
        </div>
        <b-form-input
          :name="$route.name + 'paymentOption1'"
          placeholder=""
          v-model="paymentOption.input1"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex justify-content-end ml-2 mr-3">
          <label style="white-space: nowrap" class="fontCSS">% </label>
        </div>
        <b-form-input
          :name="$route.name + 'paymentOption2'"
          placeholder=""
          v-model="paymentOption.input2"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex justify-content-end ml-2 mr-3">
          <label style="white-space: nowrap" class="fontCSS">% </label>
        </div>
        <b-form-input
          :name="$route.name + 'paymentOption3'"
          placeholder=""
          v-model="paymentOption.input3"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex justify-content-end ml-2 mr-3">
          <label style="white-space: nowrap" class="fontCSS">% </label>
        </div>
        <b-form-input
          :name="$route.name + 'paymentOption4'"
          placeholder=""
          v-model="paymentOption.input4"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          style="width: 15rem"
          trim
        />
        <div class="d-flex justify-content-end ml-2 mr-3">
          <label style="white-space: nowrap" class="fontCSS">% </label>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-end">
          <label
            style="white-space: nowrap; width: 10rem; text-align: end"
            class="fontCSS"
            >交屋條件：
          </label>
        </div>
        <div class="d-flex mr-2">
          <b-form-radio
            class="mt-1"
            v-model="handoverConditions"
            value="1"
            id="handoverConditions1"
          >
          </b-form-radio>
          <label
            style="white-space: nowrap"
            class="fontCSS"
            for="handoverConditions1"
          >
            過戶五日內交屋</label
          >
        </div>

        <div class="d-flex mr-2">
          <b-form-radio
            class="mt-1"
            v-model="handoverConditions"
            value="2"
            id="handoverConditions2"
          >
          </b-form-radio>
          <label
            style="white-space: nowrap"
            class="fontCSS"
            for="handoverConditions2"
          >
            指定交屋日期</label
          >
        </div>
        <b-form-input
          placeholder=""
          class="mr-3"
          style="text-align: end; width: 10rem"
          v-model="handoverConditionsMemo"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          trim
        />
        <div class="d-flex mr-2">
          <b-form-radio
            class="mt-1"
            v-model="handoverConditions"
            value="3"
            id="handoverConditions3"
          >
          </b-form-radio>
          <label
            style="white-space: nowrap"
            class="fontCSS"
            for="handoverConditions3"
          >
            其他</label
          >
        </div>
        <b-form-input
          placeholder=""
          class=""
          style="text-align: end; width: 20rem"
          v-model="handoverConditionsMemo"
          type="text"
          :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
          trim
        />
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import { Toast } from "./../../utils/helper";
import BaseTable from "@/components/BaseTable";
// import SearchSelect from "./../../../components/SearchSelect";
// import DateField from "./../../../components/DateField";
// import AddressField from "./../../components/AddressField";
export default {
  components: {
    BaseTable,
    // SearchSelect,
    // AddressField,
    // DateField,
  },
  data() {
    return {
      //card 1  3
      referenceNumber: "A123456789",
      sellNumber: "",
      objectStatus: {
        value: null,
        options: [
          { text: "請選擇物件狀態", value: null },
          { value: "1", text: "輸入中" },
          { value: "2", text: "可售" },
          { value: "3", text: "成交" },
          { value: "4", text: "逾期" },
        ],
      },
      address: "新北市板橋區文化路二段O鄰O里182巷3弄68號17樓",
      objectName: "測試案件-賣社區大樓",
      relevantParties: [
        {
          type: "Principal",
          IDnumber: "A123456789",
          name: "王美麗",
        },
        {
          type: "Agent",
          IDnumber: "B123456789",
          name: "一二三",
        },
      ],
      //card 2  5
      landOwner: "5",
      ownerRegister: "",
      VAT1: "",
      VAT2: "",
      area1: "222",
      area2: "",
      landTable: {
        currentPage: 1,
        perPage: 100,
        totalItems: 0,
        fields: [
          { key: "COL0", label: "流水編號" },
          { key: "COL1", label: "所有權人" },
          { key: "COL2", label: "縣市/行政區" },
          { key: "COL3", label: "段號-小段" },
          { key: "COL4", label: "地號" },
          { key: "COL5", label: "基地面積" },
          { key: "COL6", label: "物件持分" },
          { key: "COL7", label: "所有權部登記日期" },
          { key: "COL8", label: "使用分區" },
          { key: "COL9", label: "功能" },
        ],
        items: [],
      },
      //card 3  6
      objectOwner: "5",
      registerArea: "",
      illegalItem: {
        name: "illegalItem",
        value: "0",
        options: [
          { text: "有", value: "1" },
          { text: "無", value: "0" },
        ],
        position: "",
        area: "",
      },
      objectTable: {
        currentPage: 1,
        perPage: 100,
        totalItems: 0,
        fields: [
          { key: "COL0", label: "所有權人" },
          { key: "COL1", label: "身分證號" },
          { key: "COL2", label: "物件持分" },
        ],
        items: [],
      },
      objectNumber: {
        input1: "",
        input2: "",
      },
      objectSellRatio: {
        input1: "",
        input2: "",
      },
      info1: [
        {
          floor: {
            input1: "1",
            input2: "2",
            input3: "3",
            input4: "4",
            input5: "5",
            input6: "6",
          },
          accessory: [
            {
              input1: "1",
              input2: "2",
              input3: "3",
              input4: "4",
              input5: "5",
              input6: "6",
            },
            {
              input1: "",
              input2: "",
              input3: "",
              input4: "",
              input5: "",
              input6: "",
            },
          ],
        },
        {
          floor: {
            input1: "1",
            input2: "2",
            input3: "3",
            input4: "4",
            input5: "5",
            input6: "6",
          },
          accessory: [
            {
              input1: "1",
              input2: "2",
              input3: "3",
              input4: "4",
              input5: "5",
              input6: "6",
            },
            {
              input1: "",
              input2: "",
              input3: "",
              input4: "",
              input5: "",
              input6: "",
            },
          ],
        },
      ],
      info2: [
        {
          input1: "1",
          input2: "2",
          input3: "3",
          input4: "4",
          input5: "5",
          input6: "6",
        },
        {
          input1: "",
          input2: "",
          input3: "",
          input4: "",
          input5: "",
          input6: "",
        },
      ],
      info3: [
        {
          input1: "1",
          input2: "2",
          input3: "3",
          input4: "4",
          input5: "5",
          input6: "6",
        },
        {
          input1: "",
          input2: "",
          input3: "",
          input4: "",
          input5: "",
          input6: "",
        },
      ],
      //card 4  15
      parkingSpot: {
        name: "parkingSpot",
        value: "",
        options: [
          { text: "無", value: "0" },
          { text: "有", value: "1" },
        ],
        area: "",
      },
      parkingSource: {
        name: "parkingSource",
        value: "",
        options: [
          { text: "公設", value: "1" },
          { text: "獨立權狀", value: "0" },
        ],
      },
      parkingFee: {
        input: "",
        checkBox: false,
        type: {
          name: "parkingFee",
          value: "",
          options: [
            { text: "月", value: "0" },
            { text: "季", value: "1" },
            { text: "半年", value: "2" },
            { text: "年繳", value: "3" },
            { text: "其他", value: "4" },
          ],
        },
      },
      parkingFloor: {
        floor: "",
        number: "",
      },
      parkingSize: {
        long: "",
        width: "",
        height: "",
        weight: "",
      },
      parkingType: {
        name: "parkingType",
        value: "",
        options: [
          { text: "坡道平面", value: "0" },
          { text: "升降平面", value: "1" },
          { text: "坡道機械", value: "2" },
          { text: "升降機械", value: "3" },
          { text: "塔式車位", value: "4" },
          { text: "一樓平面", value: "5" },
          { text: "電腦倉儲", value: "6" },
          { text: "其他", value: "7" },
        ],
      },
      parkingProperty: {
        name: "parkingProperty",
        value: "",
        options: [
          { text: "不清楚", value: "0" },
          { text: "法定車位", value: "1" },
          { text: "自行增設車位", value: "2" },
          { text: "獎勵增設車位", value: "3" },
          { text: "其他", value: "4" },
        ],
        memo: "",
      },
      parkingOwnership: {
        name: "parkingOwnership",
        value: "",
        options: [
          {
            text: "以區分所有建物持分登記(有獨立權狀,固定車位編號)",
            value: "0",
          },
          { text: "以共用部分登記未約定專用", value: "1" },
          {
            text: "以共用部分登記業約定專用意(1.無獨立權狀,有登記車位編號 2.管委會或住戶有分管約定)",
            value: "2",
          },
          { text: "未登記", value: "3" },
        ],
      },
      parkingUsageRights: {
        name: "parkingUsageRights",
        value: "",
        options: [
          { text: "無", value: "0" },
          { text: "專用", value: "1" },
          { text: "共用", value: "2" },
          { text: "租用", value: "3" },
          { text: "借用", value: "4" },
          { text: "抽籤", value: "5" },
          { text: "排隊等候", value: "6" },
          { text: "先到先停", value: "7" },
        ],
      },
      parkingUsageStatus: {
        name: "parkingUsageStatus",
        value: "",
        options: [
          { text: "自用", value: "0" },
          { text: "出租", value: "1" },
          { text: "出借", value: "2" },
          { text: "被佔用", value: "3" },
          { text: "其他", value: "4" },
        ],
      },
      parkingUsagePromise: {
        name: "parkingUsagePromise",
        value: "",
        options: [
          { text: "無", value: "0" },
          { text: "有", value: "1" },
        ],
      },
      //card 5
      encumbranceFee: "1000",
      encumbranceTable: {
        currentPage: 1,
        perPage: 100,
        totalItems: 0,
        fields: [
          { key: "COL0", label: "流水編號" },
          { key: "COL1", label: "權利種類" },
          { key: "COL2", label: "登記日期" },
          { key: "COL3", label: "權利人" },
          { key: "COL4", label: "功能" },
        ],
        items: [],
      },
      //card 6
      contractingOfficerRatio: "100",
      contractingOfficerTable: {
        currentPage: 1,
        perPage: 100,
        totalItems: 0,
        fields: [
          { key: "COL0", label: "加盟品牌" },
          { key: "COL1", label: "分店" },
          { key: "COL2", label: "本店/跨店" },
          { key: "COL3", label: "員工編號" },
          { key: "COL4", label: "員工姓名" },
          { key: "COL5", label: "分配比例" },
          { key: "COL6", label: "承辦人員" },
          { key: "COL7", label: "功能" },
        ],
        items: [],
      },
      //card 7
      consignmentSale: "",
      consignmentSaleParking: {
        name: "consignmentSaleParking",
        value: "",
        options: [
          { text: "含", value: "1" },
          { text: "不含", value: "0" },
        ],
        cost: "",
      },
      sellerCondition: {
        name: "sellerCondition",
        value: "",
        options: [
          { text: "附停止條件定金", value: "1" },
          { text: "內政部版要約書", value: "0" },
        ],
        cost: "",
      },
      secureTransaction: {
        name: "secureTransaction",
        value: "",
        options: [
          { text: "是", value: "1" },
          { text: "否", value: "0" },
        ],
        cost: "",
      },
      sellerFee: "",
      buyerOwnFee: "",
      salesRatio: "",
      paymentOption: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
      },
      handoverConditions: "",
      handoverConditionsMemo: "",
    };
  },
  methods: {
  },
  computed: {
    ...mapState(["backgroundColor"]),
  },
  created() {},
  watch: {},
};
</script> 
<style scoped>
.underLine {
  position: relative;
}
.underLine::after {
  background-color: #dddddd;
  bottom: -0.1rem;
  content: "";
  display: block;
  height: 1px;
  left: 1rem;
  right: 1rem;
  position: absolute;
}
</style>
