<template>
  <!-- style="width: 80%; margin: 0 auto" -->
  <div>
    <b-card :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'">
      <div class="d-flex mb-1">
        <div
          v-if="renderPerPageNumber !== null"
          class="d-flex mr-3 align-items-center"
        >
          <label
            style="white-space: nowrap; margin: 0"
            class="fontCSS"
            for="tableListNumber"
            >顯示：
          </label>

          <b-form-select
            id="tableListNumber"
            :class="backgroundColor == 'dark' ? 'text-light bg-secondary' : ''"
            v-model="statusSelect.value"
            :options="statusSelect.options"
            @change="renderPerPageNumber(statusSelect.value)"
          ></b-form-select>
        </div>
        <div v-if="renderTotalItems" class="d-flex mr-3 align-items-center">
          <label style="white-space: nowrap; margin: 0" class="fontCSS"
            >總筆數：{{ totalItems }}筆
          </label>
        </div>
      </div>

      <b-table
        responsive="true"
        sticky-header="50rem"
        head-variant="light"
        style="margin: 0"
        :fields="fields"
        :items="items"
        :dark="backgroundColor == 'dark'"
        class="tableCSS"
      >
        <template #cell()="data">
          <!-- text_btn -->
          <!-- EX:data = { text: "datatext", variant: "danger"}; -->
          <b-row
            class="d-flex"
            v-if="data.field?.type == 'text_btn'"
            style="padding-left: 0.5rem"
            :class="
              data.field?.css == 'center'
                ? 'justify-content-center'
                : 'justify-content-start'
            "
          >
            <div class="d-flex flex-gap">
              <b-button
                v-for="(item, index) in data.item[data.field.key]"
                :key="index"
                class="btn-icon text-nowrap"
                :variant="item.variant"
                @click="text_btnFunction(data.field.key, data.index, index)"
              >
                <!-- <i>edit</i> -->
                <i>{{ item.text }}</i>
              </b-button>
            </div>
          </b-row>
          <!-- icon_btn -->
          <!-- (bootstrap vue icon) EX:data = { icon: "pencil-square", variant: "danger"}; -->
          <b-row
            class="d-flex justify-content-center"
            v-else-if="data.field?.type == 'icon_btn'"
            style="padding-left: 0.5rem"
          >
            <div class="d-flex flex-gap">
              <b-button
                v-for="(item, index) in data.item[data.field.key]"
                :key="index"
                class="btn-icon text-nowrap"
                :variant="item.variant"
                @click="icon_btnFunction(data.field.key, data.index, index)"
              >
                <b-icon :icon="item.icon"></b-icon>
              </b-button>
            </div>
          </b-row>
          <!-- default_btn -->
          <!-- EX:data = "edit,delete" -->
          <b-row
            class="d-flex justify-content-start"
            v-else-if="data.field?.type == 'default_btn'"
            style="padding-left: 0.5rem"
          >
            <div class="d-flex flex-gap">
              <b-button
                v-show="data.item[data.field.key].includes('detail')"
                class="btn-icon"
                variant="info"
                @click="detailAction(data.field.key, data.index)"
              >
                <b-icon icon="search"></b-icon>
              </b-button>
              <b-button
                v-show="data.item[data.field.key].includes('edit')"
                class="btn-icon"
                variant="secondary"
                @click="editAction(data.field.key, data.index)"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button
                v-show="data.item[data.field.key].includes('delete')"
                class="btn-icon"
                variant="danger"
                @click="deleteAction(data.field.key, data.index)"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </b-row>
          <!-- style_Cstm -->
          <!-- EX:data = { text: "datatext", style: "color:#dc3545 ; border:2px solid
          red" }; -->
          <b-row
            style="min-height: 2.5rem"
            v-else-if="data.field?.type == 'style_Cstm'"
            :style="data.value.text ? data.value.style : 'min-width: 4rem'"
            class="d-flex justify-content-center align-items-center"
          >
            <div>{{ data.value.text }}</div>
          </b-row>
          <!-- style_click -->
          <!-- EX:data = { text: "datatext", style: "color:#dc3545 ; border:2px solid
          red" }; -->
          <b-row
            style="min-height: 2.5rem"
            v-else-if="data.field?.type == 'style_click'"
            :style="data.value.text ? data.value.style : 'min-width: 4rem'"
            class="d-flex justify-content-center align-items-center"
          >
            <div
              @click="styleClickAction(data.field.key, data.index)"
              style="cursor: pointer; text-decoration: underline"
            >
              {{ data.value.text }}
            </div>
          </b-row>
          <!-- else -->
          <div
            style="min-height: 2.5rem"
            :style="!data.value ? 'min-width: 4rem' : 'min-width: 6rem'"
            v-else
            class="d-flex justify-content-center align-items-center text-nowrap"
          >
            {{ data.value }}
          </div>
        </template>
      </b-table>

      <div v-if="showPagination" class="mt-3 d-flex justify-content-end">
        <b-pagination
          v-model="nowPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          @change="clickPagination"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      nowPage: 1,
      statusSelect: {
        value: 5,
        options: [
          { value: 5, text: "5筆" },
          { value: 10, text: "10筆" },
          { value: 15, text: "15筆" },
          { value: 20, text: "20筆" },
        ],
      },
    };
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    totalItems: {
      type: Number,
    },
    perPage: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
    renderTotalItems: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },

    //icon_btn Function
    icon_btnFunction: {
      type: Function,
      default: null,
    },
    //text_btn Function
    text_btnFunction: {
      type: Function,
      default: null,
    },
    //default_btn
    detailAction: {
      type: Function,
      default: null,
    },
    //style_click
    styleClickAction: {
      type: Function,
      default: null,
    },
    editAction: {
      type: Function,
      default: null,
    },
    deleteAction: {
      type: Function,
      default: null,
    },

    clickPage: {
      type: Function,
      default: null,
    },
    renderPerPageNumber: {
      type: Function,
      default: null,
    },
  },
  methods: {
    clickPagination() {
      setTimeout(() => {
        this.clickPage(this.nowPage);
      }, 100);
    },
  },
  computed: {
    ...mapState(["backgroundColor"]),
  },
  watch: {
    currentPage() {
      setTimeout(() => {
        this.nowPage = this.currentPage;
      }, 100);
    },
  },
};
</script>
<style scoped>
.pagination {
  margin: 0;
}
.flex-gap > * {
  margin-right: 0.2rem;
}
.flex-gap > *:last-child {
  margin-right: 0;
}

.tableCSS td > div {
  padding-left: 10px !important;
  padding-right: 10px !important;
  white-space: break-spaces;
}
</style>