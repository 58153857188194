<template>
  <div>
    <div class="mainContent">
      <p class="detailTitle">{{ showTitle }}</p>
      <b-card
        class="mb-3"
        :class="backgroundColor == 'dark' ? 'bg-secondary' : 'bg-light'"
      >
        <b-row>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'dep1'"
                  >組別名稱：
                </label>
              </b-col>
              <b-col>
                <b-form-input
                  :id="$route.name + 'dep1'"
                  :name="$route.name + 'dep1'"
                  placeholder="請輸入組別名稱"
                  v-model="name"
                  type="text"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  :disabled="
                    viewSaveType.type == 'read' || viewSaveType.type == 'delete'
                  "
                  trim
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS" :for="$route.name + 'dep2'"
                  >組別組長：
                </label>
              </b-col>
              <b-col>
                <b-form-select
                  :id="$route.name + 'dep2'"
                  :class="
                    backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                  "
                  v-model="leader.value"
                  :options="leader.options"
                  :disabled="
                    viewSaveType.type == 'read' || viewSaveType.type == 'delete'
                  "
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">啟用日期： </label>
              </b-col>
              <b-col>
                <DateField
                  :isabled="
                    viewSaveType.type == 'read' ||
                    defaultTeam == 1 ||
                    viewSaveType.type == 'delete'
                      ? false
                      : true
                  "
                  :value.sync="startDate"
                ></DateField>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">停用日期： </label>
              </b-col>
              <b-col>
                <DateField
                  :isabled="
                    viewSaveType.type == 'read' ||
                    viewSaveType.type == 'edit' 
                      ? false
                      : true
                  "
                  :value.sync="endDate"
                ></DateField>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
                <label class="fontCSS">狀態： </label>
              </b-col>
              <b-col class="d-flex align-items-center">
                <div
                  v-for="(item, index) in this.statusRadio.options"
                  :key="index"
                  class="d-flex mr-1 mt-2"
                >
                  <b-form-radio
                    v-model="statusRadio.value"
                    :value="item.value"
                    :id="statusRadio.name + item.value"
                    :disabled="
                      viewSaveType.type !== 'delete' &&
                      viewSaveType.type !== 'restart'
                    "
                    @change="changeStatus()"
                  >
                  </b-form-radio>
                  <label class="fontColor" :for="statusRadio.name + item.value">
                    {{ item.text }}</label
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
          <b-row>
            <b-col xl="4" lg="5" md="3" class="col-3 titleCSS">
              <label class="fontCSS" :for="$route.name + 'companyManagment7'"
                >排序：
              </label>
            </b-col>
            <b-col>
              <b-form-input
                :id="$route.name + 'companyManagment7'"
                :name="$route.name + 'companyManagment7'"
                placeholder="請輸入排序，數字愈小愈前面"
                v-model="sort"
                type="number"
                :class="
                  backgroundColor == 'dark' ? 'text-light bg-secondary' : ''
                "
                :disabled="
                   !(viewSaveType.type == 'read' ||
                    defaultTeam == 1 ||
                    viewSaveType.type == 'delete')
                      ? false
                      : true
                "
                trim
              />
            </b-col>
          </b-row>
        </b-col>
        </b-row>
      </b-card>
      <div class="col-12 mt-2 mb-2">
        <b-button
          v-show="viewSaveType.type !== 'read'"
          @click="saveAction()"
          class="mr-2"
          variant="primary"
          :disabled="isProcessing"
          >確定</b-button
        >
        <b-button @click="backAction()" variant="outline-secondary"
          >返回</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "@/utils/helper";
import DateField from "@/components/DateField";
import usersAPI from "@/apis/usersAPI";
import moment from "moment";

export default {
  components: {
    DateField,
  },
  data() {
    return {
      userPremission: "",
      isProcessing: false,
      showTitle: "",
      viewSaveType: {
        id: null,
        type: "",
      },
      renderIndex: "",
      taxID: "",
      companyID: "",
      companySID: "",
      departmentID: "",
      pkID: null,
      sid: null,

      name: "",
      leader: {
        value: null,
        options: [{ value: null, text: "請選擇組長" }],
      },
      startDate: "",
      endDate: "",
      originEndDate: "",
      statusRadio: {
        name: "statusRadio",
        value: 1,
        options: [
          { text: "啟用", value: 1 },
          { text: "停用", value: 0 },
        ],
      },
      sort: null,
      defaultTeam:null
    };
  },
  methods: {
    checkViewSaveType() {
      let partmentInfo = this.$route.params.id.split(".");
      this.taxID = partmentInfo[0];
      this.companyID = partmentInfo[1];
      this.companySID = partmentInfo[2];
      this.renderIndex = partmentInfo[4];
      this.defaultTeam =  this.$route.query.defaultTeam;

      if (this.$route.name == "CompanyManagementDepartmentEdit") {
        this.showTitle = "公司資料維護 - 組別資料維護";
        if (this.userPremission.search("修改") !== -1) {
          this.viewSaveType.type = "edit";
        } else {
          this.viewSaveType.type = "read";
        }
        this.viewSaveType.id = partmentInfo[3];
        this.getCompanyDepartmentData();
      } else if (this.$route.name == "CompanyManagementDepartmentCreate") {
        this.viewSaveType.type = "create";
        this.showTitle = "公司資料維護 - 新增組別";
        this.startDate = moment().format("YYYY-MM-DD");
        this.endDate = "9999-12-31";
        this.sort = 1;
        this.getDepartmentLeaderList();
      } else if (this.$route.name == "CompanyManagementDepartmentDelete") {
        this.viewSaveType.type = "delete";
        this.showTitle = "公司資料維護 - 停用組別";
        this.viewSaveType.id = partmentInfo[3];
        this.getCompanyDepartmentData();
      } else {
        this.viewSaveType.id = partmentInfo[3];
        if (this.userPremission.search("重新啟用") !== -1) {
          this.viewSaveType.type = "restart";
          this.showTitle = "公司資料維護 - 重啟組別";
        } else {
          this.viewSaveType.type = "read";
          this.showTitle = "公司資料維護 - 組別資料維護";
        }
        this.getCompanyDepartmentData();
      }
    },
    backAction() {
      this.$router.push({
        name: "CompanyManagementDetail",
        params: { id: this.companySID },
      });
    },
    async getCompanyDepartmentData() {
      try {
        this.isProcessing = true;

        const response = await usersAPI.getCompanyDepartmentData({
          teamSID: this.viewSaveType.id,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        let data = response.data.list[0];
        this.name = data.teamNM;
        this.leader.value = data.teamEmpID;
        this.startDate = moment(data.startTime).format("YYYY-MM-DD");
        this.endDate = moment(data.endTime).format("YYYY-MM-DD");
        this.originEndDate = moment(data.endTime).format("YYYY-MM-DD");
        this.statusRadio.value = Number(data.status);
        this.pkID = data.pkID;
        this.sid = data.teamSID;
        this.departmentID = data.teamID;
        this.sort = data.sort;

        // for (var index in data.teamEmpList) {
        //   let users = data.teamEmpList[index];
        //   this.leader.options.push({
        //     value: users.id,
        //     text: users.nm,
        //   });
        // }
        this.getDepartmentLeaderList();

        if (this.viewSaveType.type == "restart") {
          this.endDate = "9999-12-31";
          this.statusRadio.value = 1;
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async getDepartmentLeaderList() {
      try {
        this.isProcessing = true;

        const response = await usersAPI.getDepartmentLeaderList({
          taxID: this.taxID,
          teamID: null,
          teamEmpID: this.leader.value,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        for (var index in response.data.list) {
          let users = response.data.list[index];
          this.leader.options.push({
            value: users.id,
            text: users.nm,
          });
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    changeStatus() {
      if (this.viewSaveType.type == "delete") {
        if (this.statusRadio.value == 0) {
          this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        } else {
          this.endDate = this.originEndDate;
        }
      }
      if (this.viewSaveType.type == "restart") {
        if (this.statusRadio.value == 0) {
          this.endDate = this.originEndDate;
        } else {
          this.endDate = "9999-12-31";
        }
      }
    },
    saveAction() {
      if (
        this.viewSaveType.type !== "delete" &&
        this.viewSaveType.type !== "restart"
      ) {
        if (this.endDate <= moment().format("YYYY-MM-DD")) {
          Toast.fire({
            icon: "warning",
            title: "停用日期不得小於當日。",
          });
          return;
        }
      }
      if (!this.name) {
        Toast.fire({
          icon: "warning",
          title: "請輸入組別名稱",
        });
        return;
      }
      if (this.viewSaveType.type == "create") {
        this.createCompanyDepartmentData();
      } else if (this.viewSaveType.type == "edit") {
        this.editCompanyDepartmentData();
      } else if (this.viewSaveType.type == "restart") {
        if (this.statusRadio.value == 0) {
          this.leader.value = null;
          this.editCompanyDepartmentData();
        } else {
          this.restartCompanyDepartmentData();
        }
      } else {
        this.deleteAction();
      }
    },
    deleteAction() {
      if (this.originEndDate == this.endDate) {
        this.backAction();
        return;
      }

      this.$bvModal
        .msgBoxConfirm("組別將立即停用，請再次確定。", {
          bodyClass: "checkModalCss",
          headerClass: " border-bottom-0",
          footerClass: "p-2 border-top-0",
          okVariant: "primary",
          okTitle: "確定",
          cancelTitle: "取消",
          cancelVariant: "outline-secondary",
          size: "sm",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteCompanyDepartmentData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async createCompanyDepartmentData() {
      try {
        this.isProcessing = true;
        const response = await usersAPI.createCompanyDepartmentData({
          taxID: this.taxID,
          teamID: this.departmentID,
          teamNM: this.name,
          teamEmpID: this.leader.value,
          StartTime: this.startDate == "" ? null : this.startDate,
          EndTime: this.endDate == "" ? null : this.endDate,
          Sort: this.sort ? Number(this.sort) : null,
          Status: this.statusRadio.value,
          filePathList: null,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        Toast.fire({
          icon: "success",
          title: "新增成功",
        });
        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async editCompanyDepartmentData(type) {
      try {
        this.isProcessing = true;
        let Status = this.statusRadio.value;
        if (type == 0) {
          Status = 1;
        }
        const response = await usersAPI.editCompanyDepartmentData({
          taxID: this.taxID,
          teamID: this.departmentID,
          teamNM: this.name,
          teamEmpID: this.leader.value,
          StartTime: this.startDate == "" ? null : this.startDate,
          EndTime: this.endDate == "" ? null : this.endDate,
          Sort: this.sort ? Number(this.sort) : null,
          Status: Status,
          filePathList: null,
          pkID: this.pkID,
          teamSID: this.sid,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        if (type == 0) {
          Toast.fire({
            icon: "success",
            title: this.name + "已啟用",
          });
        } else {
          Toast.fire({
            icon: "success",
            title: "修改成功",
          });
        }

        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async deleteCompanyDepartmentData() {
      try {
        this.isProcessing = true;
        let disableList = [];
        disableList.push({
          pkID: this.pkID,
          teamSID: this.sid,
          taxID: this.taxID,
          teamID: this.departmentID,
          StartTime: this.startDate == "" ? null : this.startDate,
          EndTime: this.endDate == "" ? null : this.endDate,
          status: this.statusRadio.value,
        });

        const response = await usersAPI.deleteCompanyDepartmentData({
          disableList: disableList,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }

        Toast.fire({
          icon: "success",
          title: this.name + "已停用",
        });

        this.isProcessing = false;
        this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
    async restartCompanyDepartmentData() {
      try {
        this.isProcessing = true;
        let enableList = [];
        enableList.push({
          pkID: this.pkID,
          teamSID: this.sid,
          taxID: this.taxID,
          teamID: this.departmentID,
          EndTime: this.endDate == "" ? null : this.endDate,
          status: this.statusRadio.value,
        });

        const response = await usersAPI.restartCompanyDepartmentData({
          enableList: enableList,
        });

        if (!response.data.success) {
          Toast.fire({
            icon: "warning",
            title: response.data.errDesc,
          });
          this.isProcessing = false;

          if (response.data.errDesc.search("過期") !== -1) {
            this.$store.commit("revokeAuthentication");
            this.$router.push("/signin");
          }
          return;
        }
        this.editCompanyDepartmentData(0);

        this.isProcessing = false;
        // this.backAction();
      } catch (error) {
        this.isProcessing = false;
        Toast.fire({
          icon: "warning",
          title: error,
        });
      }
    },
  },
  watch: {},
  computed: {
    ...mapState([
      "backgroundColor",
      "currentUser",
      "currentRoute",
      "searchCondition",
    ]),
  },
  created() {
    this.userPremission = this.currentUser.isAdmin.find(
      (item) => item.progPath == this.currentRoute
    )?.haveAuth;

    this.checkViewSaveType();
  },
};
</script>
<style scoped>
</style>